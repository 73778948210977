<script setup lang="ts">
import chatWindow from '@/ai/components/ChatWindow.vue';
import { setup } from '@/ai/views/chatView.setup';

const { position, container, isChatWindowOpen, onMouseDown, onMouseUp } = setup();
</script>

<template>
  <div
    v-show="!isChatWindowOpen"
    ref="container"
    :style="{ bottom: position.bottom + 'px', right: position.right + 'px' }"
    class="chat-view__open"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <i
      class="icon-ai-bubble"
      @click.stop
    />
  </div>
  <div
    v-show="isChatWindowOpen"
    :style="{ bottom: position.bottom + 'px', right: position.right + 'px' }"
    class="chat-view__close"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <ev-icon class="icon-close" />
  </div>
  <chat-window />
</template>

<style scoped>
.chat-view__open {
  position: fixed;
  display: flex;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1000;

  .icon-ai-bubble {
    background: url('@/common/assets/ai/ai-bubble-light.svg');
    [data-theme='dark'] & {
      background-image: url('@/common/assets/ai/ai-bubble-dark.svg');
    }
    opacity: 0;
    width: 126px;
    height: 68px;
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
  &:hover .icon-ai-bubble {
    opacity: 1;
  }
}

.chat-view__close {
  position: fixed;
  display: flex;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 10px 26px 0px #3c8eff33;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .icon-close {
    background: linear-gradient(135deg, #3e99ff 2.86%, #6c4fff 97.14%);
  }
}
</style>

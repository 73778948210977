import { useGlobalTime } from '@/common/components/molecules/navigationHeader/navigationHeader.setup';
import NavigationHeader from '@/common/components/molecules/navigationHeader/NavigationHeader.vue';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { useTargetPermission } from '@/common/permission/targetPermission.utils';
import { isFilter, isFilterData, useFilterListStore } from '@/common/stores/filter-list';
import { useAbortApi } from '@/common/utils/apiUtils';
import { getCommonAlertCountAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { useRepeat } from '@/worker/composables/useRepeat';
import { storeToRefs } from 'pinia';
import { computed, inject, InjectionKey, provide, Ref, ref, watch } from 'vue';

const use = () => {
  const { selectedPeriodInfo: timeInfo } = useGlobalTime();
  const navigationHeaderRef = ref<InstanceType<typeof NavigationHeader> | null>(null);

  const togglePause = () => {
    navigationHeaderRef.value?.togglePause();
  };

  return {
    timeInfo,
    togglePause,
    navigationHeaderRef,
  };
};

const navigationHeaderInjectionKey = Symbol('navigationHeader') as InjectionKey<
  ReturnType<typeof use>
>;

export const useContextTime = () => {
  return inject(navigationHeaderInjectionKey, use());
};

export const provideContextTime = () => {
  const injected = use();
  provide(navigationHeaderInjectionKey, injected);

  return injected;
};

const useAlertDetails = () => {
  const isOpen = ref(false);
  const targetIds = ref<string[]>([]);
  const alertCount = ref<number>(0);

  return {
    targetIds,
    alertCount,
    isOpen,
  };
};

const npmAlertInjectionKey = Symbol('npmAlertDetails') as InjectionKey<
  ReturnType<typeof useAlertDetails>
>;

export const useNpmAlertDetails = () => {
  return inject(npmAlertInjectionKey, useAlertDetails());
};

const useAlertCount = (targetIds: Ref<string[]>, alertCount: Ref<number>) => {
  const { getSignal, abortApi } = useAbortApi();
  const fetchAlertCount = async () => {
    try {
      abortApi();
      const { data } = await getCommonAlertCountAlertCommonControllerAxios({
        targetKind: 'network_object',
        subTargetKinds: ['network_object'],
        targetIds: targetIds.value,
        signal: getSignal(),
        frameName: FRAME_NAMES.NETWORK_OBJECT_ALERT_DETAIL.ALERT_COUNT,
        isPolling: true,
      });
      const { criticalCount, warningCount } = data?.data ?? {};
      alertCount.value = (criticalCount ?? 0) + (warningCount ?? 0);
    } catch (e) {
      console.log(e);
    }
  };

  const { resetFetch, clearFetch } = useRepeat(fetchAlertCount, 5_000, {
    isSetup: false,
    isImmediate: false,
  });

  return {
    resetAlertCountFetch: resetFetch,
    clearAlertCountFetch: clearFetch,
  };
};

export const provideNpmAlertDetails = () => {
  const injected = useAlertDetails();

  const { clearAlertCountFetch, resetAlertCountFetch } = useAlertCount(
    injected.targetIds,
    injected.alertCount,
  );

  const onClickAlertIcon = () => {
    injected.isOpen.value = true;
    clearAlertCountFetch();
  };

  watch(
    () => injected.isOpen.value,
    (_isShowAlertDetail) => {
      if (_isShowAlertDetail === false) {
        resetAlertCountFetch();
      }
    },
    { immediate: true },
  );

  provide(npmAlertInjectionKey, injected);
  return { ...injected, onClickAlertIcon };
};

const usePermission = () => {
  const filterStore = useFilterListStore();
  const { filterListData } = storeToRefs(filterStore);
  const { hasPermissionToTarget } = useTargetPermission();

  const permissionMap = ref<Record<string, boolean>>({});
  const targets = computed<string[]>(() => {
    return (filterListData.value.find(isFilterData)?.values.map((f) => f.id) as string[]) ?? [];
  });

  const fetch = async () => {
    const map = await hasPermissionToTarget({ targets: targets.value });

    permissionMap.value = map;
  };

  watch(targets, fetch);

  return {
    permissionMap,
  };
};

const networkObjectPermissionInjectionKey = Symbol('npmAlertDetails') as InjectionKey<
  ReturnType<typeof usePermission>
>;

export const useNetworkObjectPermission = () => {
  return inject(networkObjectPermissionInjectionKey, usePermission());
};

export const provideNetworkObjectPermission = () => {
  const injected = usePermission();
  provide(networkObjectPermissionInjectionKey, injected);
  return injected;
};

export const useTargetList = () => {
  const store = useFilterListStore();
  const { checkedFilterListData } = storeToRefs(store);
  const list = computed<string[]>(() => {
    const filterList = checkedFilterListData.value.find(isFilterData);
    return filterList?.values?.filter(isFilter).map((v) => v.id) || [];
  });

  return {
    list,
  };
};

// 주로 rtm 화면에서 사용
export const VIEW_ONLY_ALERT_COLUMNS = [
  {
    caption: 'Rule Name',
    field: 'ruleName',
    type: 'string',
    clickable: true,
    width: 100,
  },
  {
    caption: 'Alert Name',
    field: 'alertName',
    type: 'string',
    width: 90,
  },
  {
    caption: 'Target',
    field: 'targets',
    type: 'string',
    rendererType: 'chip-cell',
    width: 90,
  },
  {
    caption: 'Last Triggered',
    field: 'time',
    type: 'string',
    width: 90,
  },
  {
    caption: 'Rule Type',
    field: 'ruleType',
    type: 'string',
    width: 84,
  },
  {
    caption: 'Last Alert',
    field: 'lastAlert',
    type: 'string',
    rendererType: 'label',
    width: 90,
  },
  {
    caption: 'Threshold',
    field: 'threshold',
    type: 'string',
    width: 150,
    sortable: false,
  },
  {
    caption: 'Alert Value',
    field: 'value',
    type: 'string',
    width: 81,
    sortable: false,
  },
  // {
  //   caption: 'Description',
  //   field: 'description',
  //   type: 'string',
  //   width: 85,
  // },
  {
    caption: 'Rule ID',
    field: 'alertRuleId',
    type: 'string',
    hide: true,
  },
  {
    caption: 'Rule Name Icon',
    field: 'ruleNameIcon',
    type: 'string',
    hide: true,
  },
] as const;

// RTM_ALERT_COLUMNS 와 동일한 필드 구성
// 주로 detail > alert 탭에서 사용
export const SEARCHABLE_ALERT_COLUMNS = [
  {
    caption: 'Rule Name',
    field: 'ruleName',
    type: 'string',
    clickable: true,
    searchable: true,
    width: 150,
  },
  {
    caption: 'Alert Name',
    field: 'alertName',
    type: 'string',
    searchable: true,
    width: 150,
  },
  {
    caption: 'Target',
    field: 'targets',
    type: 'string',
    rendererType: 'chip-cell',
    searchable: false,
    width: 150,
  },
  {
    caption: 'Last Triggered',
    field: 'time',
    type: 'string',
    searchable: true,
    width: 150,
  },
  {
    caption: 'Rule Type',
    field: 'ruleType',
    type: 'string',
    searchable: true,
    width: 100,
  },
  {
    caption: 'Last Alert',
    field: 'lastAlert',
    type: 'string',
    rendererType: 'label',
    searchable: true,
    width: 100,
  },
  {
    caption: 'Threshold',
    field: 'threshold',
    type: 'string',
    searchable: true,
    sortable: false,
    width: 300,
  },
  {
    caption: 'Alert Value',
    field: 'value',
    type: 'string',
    searchable: true,
    sortable: false,
    width: 120,
  },
  {
    caption: 'Notification Status',
    field: 'notiStatus',
    type: 'string',
    searchable: false,
    sortable: false,
    width: 146,
  },
  // {
  //   caption: 'Description',
  //   field: 'description',
  //   type: 'string',
  //   searchable: true,
  // },
  {
    caption: 'Rule ID',
    field: 'alertRuleId',
    type: 'string',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Rule Name Icon',
    field: 'ruleNameIcon',
    type: 'string',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Event Type',
    field: 'eventType',
    type: 'string',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Rule Criteria',
    field: 'ruleCriteria',
    type: 'string',
    hide: true,
    searchable: false,
  },
] as const;

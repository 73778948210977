import { DEFAULT_STATUS_LEVEL } from '@/common/components/molecules/hexagonChart/hexagonChart.define';
import { getYesterdayDate } from '../components/widgets/oneDayCompareWidget/oneDayCompareWidget.utils';
import {
  StatusHexaOption,
  StatusSummaryOption,
  TopologyWidgetChartOption,
} from '../components/widgetSettingsWindow/architectureWidgetSettings/architectureWidgetSettings.types';
import { TRX_ITEMS } from '../components/widgetSettingsWindow/architectureWidgetSettings/serverWidgetSettingArticle/serverWidgetSetting.define';
import { ServerChartOption } from '../components/widgetSettingsWindow/architectureWidgetSettings/serverWidgetSettingArticle/serverWidgetSettingArticle.setup';
import { OneDayCompareChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/1dayCompareSettings/oneDayCompareSettingsArticle.setup';
import { EqualizerChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/equalizerSettings/equalizerSettingsArticle.setup';
import { GaugeChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/gauge/gaugeSettingArticle.setup';
import { TimeSeriesChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/graphWidgetSettings.types';
import { PieChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/pieSettings/pieSettingsChartOption/pieSettingsChartOption.setup';
import { ScatterChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/scatterSettings/scatterSettingsArticle.setup';
import { ScatterDotSize } from '../components/widgetSettingsWindow/graphWidgetSettings/scatterSettings/scatterSettingsChartOption/dotSizeList/dotSizeList.types';
import { DEFAULT_THRESHOLD_COLOR } from '../components/widgetSettingsWindow/graphWidgetSettings/scoreboardSettings/scoreboardSetting.define';
import { ScoreboardChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/scoreboardSettings/scoreboardSettingsArticle.setup';
import { StackedProportionBarChartOption } from '../components/widgetSettingsWindow/graphWidgetSettings/stackedProportionBarSettings/stackedProportionBarSettingsArticle.setup';
import { ImageWidgetOption } from '../components/widgetSettingsWindow/imageWidgetSettings/imageWidgetSettings.setup';
import {
  TopListChartOption,
  TreeGridChartOption,
} from '../components/widgetSettingsWindow/listWidgetSettings/listWidgetSettings.types';
import { MemoWidgetChartOption } from '../components/widgetSettingsWindow/memoWidgetSettings/memoWidgetSettings.setup';
import { COLOR_PALETTE_LIST } from './color.define';
import { WidgetChartType } from './types';

export type WidgetChartOptionsMap = {
  // graph
  TIME_SERIES: TimeSeriesChartOption;
  SCATTER: ScatterChartOption;
  ONE_DAY_COMPARE: OneDayCompareChartOption;
  EQUALIZER: EqualizerChartOption;
  SCOREBOARD: ScoreboardChartOption;
  PIE: PieChartOption;
  STACKED_PROPORTION_BAR: StackedProportionBarChartOption;
  GAUGE: GaugeChartOption;
  ACTION_VIEW: undefined;
  // list
  TABLE: TreeGridChartOption;
  TREE_GRID: TreeGridChartOption;
  TOP_LIST: TopListChartOption;
  // architecture
  EXCLUSIVE: undefined;
  STATUS: StatusHexaOption;
  STATUS_SUMMARY: StatusSummaryOption;
  TOPOLOGY: TopologyWidgetChartOption;
  SERVER: ServerChartOption;
  // business
  SERVICE_CARD: undefined;
  // etc
  MEMO: MemoWidgetChartOption;
  TEXT: undefined;
  IMAGE: ImageWidgetOption;
  HORIZONTAL_FILTER: undefined;
  VERTICAL_FILTER: undefined;
  GROUP: undefined;
};

export type ChartOptions<T extends WidgetChartType> = T extends keyof WidgetChartOptionsMap
  ? WidgetChartOptionsMap[T]
  : never;

export const DefaultChartOptions: { [K in WidgetChartType]: ChartOptions<K> } = {
  TIME_SERIES: {
    showLegend: false,
    useMarkers: false,
    showMaxValue: false,
    markerByMetrics: {
      use: false,
      dataId: '',
      category: '',
      targets: [],
      seriesType: 'avg',
      showCurrentValue: false,
      showIncreaseRate: false,
    },
    interpolation: undefined,
    referenceInfo: undefined,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
    thresholdsOptions: undefined,
  },
  SCATTER: {
    yAxisInfo: {
      autoScale: true,
      min: 0,
      max: 10,
    },
    dotSize: ScatterDotSize[1],
    showLegend: false,
    autoScaleMax: undefined,
    referenceInfo: undefined,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
  },
  ONE_DAY_COMPARE: {
    showLegend: true,
    compareDate: {
      item: 'yesterday',
      date: getYesterdayDate(new Date()).getTime(),
    },
    referenceInfo: undefined,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
  },
  EQUALIZER: {
    tableData: [],
    showLegend: false,
    yAxisOption: {
      mode: 'auto',
      useAutoMin: false,
      minValue: undefined,
      fixedValue: undefined,
    },
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
  },
  SCOREBOARD: {
    showLegend: false,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
    display1: {
      chartOption: {
        marker: {
          use: false,
          hideStatName: false,
          valueFormat: {
            use: false,
            format: '',
          },
        },
        formatting: {
          textSize: 40,
          isAuto: true,
          verticalAlign: 'bottom',
          horizontalAlign: 'right',
        },
        layoutOptions: {
          use: false,
          width: null,
          height: null,
        },
      },
      displayRules: [
        {
          use: false,
          threshold: {
            type: 'absolute',
            absolute: {
              values: [
                {
                  start: null,
                  end: null,
                  label: null,
                  backgroundColor: DEFAULT_THRESHOLD_COLOR.background,
                  fontColor: DEFAULT_THRESHOLD_COLOR.font,
                  isError: true,
                },
              ],
            },
            proportion: {
              min: {
                value: null,
                isError: true,
              },
              max: {
                value: null,
                isError: true,
              },
              values: [
                {
                  start: null,
                  end: null,
                  label: null,
                  backgroundColor: DEFAULT_THRESHOLD_COLOR.background,
                  fontColor: DEFAULT_THRESHOLD_COLOR.font,
                  isError: true,
                },
              ],
            },
          },
        },
      ],
    },
  },
  PIE: {
    displayName: '',
    displayValue: '',
    fieldNameList: [],
    showLegend: true,
    markerInfo: {
      show: true,
      showTotal: true,
    },
    chartTheme: undefined,
    sortByFieldName: undefined,
    orderBy: 'DESC',
    limitNum: 50,
    legendStatus: {},
    referenceInfo: undefined,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
  },
  STACKED_PROPORTION_BAR: {
    showLegend: false,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
  },
  GAUGE: {
    showLegend: false,
    multiGauge: undefined,
    marker: undefined,
    gaugeSetting: undefined,
    decimalOptions: {
      use: false,
      decimalsPrecision: 3,
    },
  },
  ACTION_VIEW: undefined,
  TABLE: {
    limit: 50,
    // sortType: 'DESC',
    // sortByColumn: '',
    // isShowHeader: false,
    // columns: [],
    // rowColors: [],
    // columnList: undefined,
    // referenceInfo: undefined,
    sortType: 'DESC',
    isShowHeader: false,
    columns: [],
    sortColumn: '',
    groupByColumn: 'none',
    groupChild: {
      sortColumn: '',
      sortType: 'DESC',
    },
    tree: {
      parent: {
        id: '',
        sortColumn: '',
        sortType: 'DESC',
      },
      child: {
        id: '',
        sortColumn: '',
        sortType: 'DESC',
      },
    },
    isTreeMode: true,
    rowColors: [],
    rawColumns: undefined,
    referenceInfo: undefined,
  },
  TREE_GRID: {
    sortType: 'DESC',
    isShowHeader: false,
    columns: [],
    sortColumn: '',
    groupByColumn: 'none',
    groupChild: {
      sortColumn: '',
      sortType: 'DESC',
    },
    tree: {
      parent: {
        id: '',
        sortColumn: '',
        sortType: 'DESC',
      },
      child: {
        id: '',
        sortColumn: '',
        sortType: 'DESC',
      },
    },
    isTreeMode: true,
    rowColors: [],
    rawColumns: undefined,
    referenceInfo: undefined,
    limit: 50,
  },
  TOP_LIST: {
    limit: 50,
    sortType: 'DESC',
    sortColumn: '',
    displayStat: '',
    statItems: [],
    referenceInfo: undefined,
  },
  EXCLUSIVE: undefined,
  STATUS: {
    groupSetting: {
      use: false,
      groupKey: undefined,
      orderBy: 'DESC',
    },
    statusLevel: DEFAULT_STATUS_LEVEL,
    statusSettings: [],
    markerStat: null,
    legend: true,
  },
  STATUS_SUMMARY: {
    showLegend: true,
    markerInfo: { show: true, showTotal: true },
    statusSettings: [],
  },
  TOPOLOGY: {
    nodeOptions: [],
    edgeOption: {
      statInfo: {
        stat: undefined,
        aggregation: 'sum',
      },
      threshold: {
        use: false,
        warning: {
          value: 0,
          operator: '<',
          color: COLOR_PALETTE_LIST[0].YELLOW[1].background,
        },
        critical: {
          value: 0,
          operator: '<',
          color: COLOR_PALETTE_LIST[1].ORANGE[3].background,
        },
      },
      labelDisplayType: 'none',
    },
    groupingOption: {
      use: false,
      type: 'targetType',
    },
  },
  SERVER: {
    useLegend: true,
    maxTrx: TRX_ITEMS[1].value,
    statDataList: [],
  },
  SERVICE_CARD: undefined,
  MEMO: {
    content: '',
    htmlContent: '',
  },
  TEXT: undefined,
  IMAGE: {
    imgSrc: null,
    fileName: '',
  },
  HORIZONTAL_FILTER: undefined,
  VERTICAL_FILTER: undefined,
  GROUP: undefined,
};

import { computed, onMounted, onUnmounted, ref } from 'vue';
import { Message } from '@/ai/utils/types';
import { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web/build/player/lottie_light';
import { marked } from 'marked';
import animationData from '@/common/assets/ai/window-bot.json';

export interface Props {
  message: Message;
}

export interface Emit {
  (e: 'message-click', payload: string): void;
}

export const setup = (props: Props, emit: Emit) => {
  const container = ref<HTMLDivElement | null>(null);
  let loadingScreenAnimation: AnimationItem | null = null;

  const { message } = props;

  const parsedResult = computed(() => {
    return message.result ? JSON.parse(message.result) : [];
  });

  const parsedMessage = computed(() => {
    return message.message ? marked(message.message) : [];
  });

  const displayedText = ref('');
  const typingSpeed = 10;
  const isTextFullyDisplayed = ref(false);

  const typeText = (text: string, target: typeof displayedText, speed: number) => {
    let index = 0;
    const type = () => {
      if (index < text.length) {
        target.value += text[index];
        index++;
        setTimeout(type, speed);
      } else {
        isTextFullyDisplayed.value = true;
      }
    };
    type();
  };

  const parsedTarget = computed(() => {
    return message.result ? JSON.parse(message.result) : [];
  });

  const handleClick = (msg: string) => {
    emit('message-click', msg);
  };

  onMounted(() => {
    if (container.value) {
      loadingScreenAnimation = lottie.loadAnimation({
        container: container.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }

    typeText(props.message.message, displayedText, typingSpeed);
  });

  onUnmounted(() => {
    if (loadingScreenAnimation) {
      loadingScreenAnimation.destroy();
    }
  });

  return {
    parsedResult,
    parsedMessage,
    handleClick,
    container,
    parsedTarget,
    displayedText,
    isTextFullyDisplayed,
  };
};

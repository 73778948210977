import { computed, nextTick, ref, watch } from 'vue';
import { useViewModeStore } from '@/common/stores/view-mode';
import { VIEW_MODE } from '@/common/define/common.define';
import { storeToRefs } from 'pinia';
import { isEmpty } from 'lodash-es';
import router from '@/common/router';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { useInternational } from '@/common/locale';
import { useInstanceStore } from '@/common/stores/instance';
import { MenuInfo, HoveredMenuInfo } from './types';
import { useDashboardMenu, useMenu } from './baseMenu.uses';
import { BASE_MENU_VALUE } from './baseMenu.define';
import { DASHBOARD_MENU_TYPE } from '../../molecules/breadCrumbs/breadCrumbs.define';

export const setup = () => {
  const { t } = useInternational();
  const { findMenuItem, menuInfo, helpMenuInfo, myInfoMenuInfo } = useMenu();
  const { viewMode, productName } = storeToRefs(useViewModeStore());
  const { dashboardMenuInfo, analysisBoardMenuInfo } = useDashboardMenu(menuInfo.value);

  const baseMenuStore = useBaseMenuStore();
  const {
    menuVisitRoute: menuVisitRouteStore,
    expandedMenu,
    panelMenu,
    isBaseMenuHidden,
  } = storeToRefs(baseMenuStore);
  const {
    updateLastMenu,
    getLastMenu,
    openMenu,
    closeMenu,
    selectMenu,
    selectPanelMenu,
    expandMenu,
    shrinkMenu,
  } = baseMenuStore;
  const { changeGNBInstance } = useInstanceStore();

  const hoveredMenuInfo = ref<HoveredMenuInfo>({ menuInfo: null, top: 0 });
  const isMyInfoPopShown = ref<boolean>(false);
  const tooltipMouseEvent = ref<MouseEvent | null>(null);
  const floatingMenuRef = ref();
  const baseMenuRef = ref();

  const { onMouseEventInTarget } = useSimpleTextTooltip(tooltipMouseEvent);

  const currentMenuVisitRoute = computed<MenuInfo[]>(() => {
    const visitRoute: MenuInfo[] = [];
    findMenuItem(
      menuInfo.value,
      (menu: MenuInfo) => menu.path === router.currentRoute.value.path,
      visitRoute,
    );
    return visitRoute;
  });

  const currentRootMenu = computed<string>(() => {
    const [, rootPath, ...subPaths] = router.currentRoute.value.path.split('/');
    if (!isEmpty(router.currentRoute.value.query)) {
      const { rootMenu } = router.currentRoute.value.query;
      return (rootMenu as string) ?? '';
    }

    if (rootPath === 'dashboard' || rootPath === 'myInfo') {
      return rootPath;
    }
    if (rootPath === BASE_MENU_VALUE.FAULT_ANALYSIS) {
      return BASE_MENU_VALUE.PA;
    }
    if (
      ['singleView', 'multiView'].includes(subPaths[0]) &&
      viewMode.value !== VIEW_MODE.MAXGAUGE
    ) {
      return 'database';
    }
    if (
      rootPath === BASE_MENU_VALUE.ORACLE ||
      rootPath === BASE_MENU_VALUE.PG ||
      rootPath === BASE_MENU_VALUE.MYSQL ||
      rootPath === BASE_MENU_VALUE.SQLSERVER
    ) {
      if (viewMode.value !== VIEW_MODE.MAXGAUGE) {
        return BASE_MENU_VALUE.PA;
      }
      return rootPath;
    }

    if (!currentMenuVisitRoute.value.length) {
      return '';
    }
    return currentMenuVisitRoute.value[0].value ?? '';
  });

  const onExpandMenu = (menu: MenuInfo) => {
    // 이동할 경로 계산
    let targetPath: string | undefined;

    if (
      dashboardMenuInfo.value[menu.value]?.length > 0 &&
      dashboardMenuInfo.value[menu.value][0].path
    ) {
      // 하위 메뉴에 대시보드가 존재할 경우
      targetPath = dashboardMenuInfo.value[menu.value][0].path!;
      expandMenu(menu.value, BASE_MENU_VALUE.DASHBOARD);
      openMenu({ ...menu.dashboardList!, subMenuList: dashboardMenuInfo.value[menu.value] });
      selectPanelMenu(dashboardMenuInfo.value[menu.value][0].value);
    } else if (
      analysisBoardMenuInfo.value[menu.value]?.length > 0 &&
      analysisBoardMenuInfo.value[menu.value][0].path
    ) {
      // 하위 메뉴에 분석보드가 존재할 경우
      targetPath = analysisBoardMenuInfo.value[menu.value][0].path!;
      expandMenu(menu.value, BASE_MENU_VALUE.ANALYSISBOARD);
      openMenu({
        ...menu.analysisBoardList!,
        subMenuList: analysisBoardMenuInfo.value[menu.value],
      });
      selectPanelMenu(analysisBoardMenuInfo.value[menu.value][0].value);
    } else {
      // 하위 메뉴에 대시보드 없음
      let menuNode: MenuInfo | null = null;
      targetPath = getLastMenu(menu.value);

      if (!targetPath) {
        if (menu.value === BASE_MENU_VALUE.DASHBOARD) {
          targetPath = '/dashboard/list';
        } else if (menu.value === BASE_MENU_VALUE.SETTINGS) {
          targetPath = '/myInfo';
        } else {
          const predicateFn = (info: MenuInfo) =>
            !info.children?.length && !info.dashboardList && !info.subMenuList && !!info.path;
          menuNode = findMenuItem([menu], predicateFn);
          targetPath = menuNode?.path ?? '';
        }
      }

      // 메뉴바 확장
      if (!menuNode) {
        menuNode = findMenuItem([menu], (info) => info.path === targetPath);
      }
      expandMenu(menu.value, menuNode?.value);
    }

    if (hoveredMenuInfo.value.menuInfo) {
      hoveredMenuInfo.value.menuInfo = null;
    }
    if (targetPath) {
      const [, rootPath, secondPath] = targetPath.split('/');
      if (secondPath === 'multiView' || secondPath === 'singleView') {
        changeGNBInstance(rootPath, secondPath);
      }
      router.push(targetPath);
    }
  };

  const onExpandMyInfoMenu = () => {
    expandMenu(BASE_MENU_VALUE.SETTINGS);
    isMyInfoPopShown.value = true;
  };

  const onExpandSupportMenu = () => {
    expandMenu(BASE_MENU_VALUE.HELP);
    openMenu(helpMenuInfo);
    const selectedChildren = helpMenuInfo.subMenuList[0].children?.find(({ path }) =>
      currentRootMenu.value === BASE_MENU_VALUE.PA
        ? path?.endsWith(BASE_MENU_VALUE.ANALYSIS)
        : path?.endsWith(currentRootMenu.value),
    );
    if (selectedChildren && selectedChildren.path) {
      selectPanelMenu(selectedChildren.value);
      router.push(selectedChildren.path);
    }
  };

  const onShrinkMenu = () => {
    if (panelMenu.value.menuInfo && panelMenu.value.menuInfo.value !== BASE_MENU_VALUE.SETTINGS) {
      closeMenu();
    }
    if (expandedMenu.value.expanded) {
      shrinkMenu();
    }
  };

  const onHoverMenu = (evt: MouseEvent) => {
    if (expandedMenu.value.expanded || panelMenu.value.menuInfo || evt.button !== 0) {
      return; // nav expanded 되었을 경우에는 hover 동작 X
    }

    const el = evt.target as HTMLElement;
    // button value 의미는 아래 링크 확인.
    // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button 참조

    const {
      dataset: { menu = '' },
    } = el;

    const { top } = el.getBoundingClientRect();

    if (!menu) {
      return;
    }

    if (menu !== BASE_MENU_VALUE.SETTINGS && menu !== BASE_MENU_VALUE.HELP) {
      const foundMenuInfo = findMenuItem(menuInfo.value, (info) => info.value === menu);
      hoveredMenuInfo.value.menuInfo = foundMenuInfo;
      if (top) {
        hoveredMenuInfo.value.top = top;
      }
    } else {
      hoveredMenuInfo.value.menuInfo = null;
    }
  };

  const onHideMenu = () => {
    hoveredMenuInfo.value.menuInfo = null;
  };

  const onHideMyInfoPop = () => {
    isMyInfoPopShown.value = false;
  };

  const setSettingPanel = () => {
    openMenu(myInfoMenuInfo);
    selectPanelMenu(currentMenuVisitRoute.value[2].value);
  };

  const setHelpPanel = () => {
    openMenu(helpMenuInfo);
    selectPanelMenu(currentMenuVisitRoute.value[2].value);
  };

  const onClickSetting = () => {
    let targetPath = getLastMenu('myInfo');

    if (!targetPath || !targetPath.length) {
      targetPath = '/myInfo';
    }

    onHideMyInfoPop();
    if (targetPath === router.currentRoute.value.path) {
      setSettingPanel();
    }
    router.push(targetPath);
  };

  const onClickTitle = () => {
    router.push('/');
  };

  const onClickOutsideMenu = () => {
    // 설정 화면에서만 적용됨 DSP-15084
    if (currentRootMenu.value === BASE_MENU_VALUE.SETTINGS) {
      shrinkMenu();
    }
  };

  const onPathChange = () => {
    // 경로 변경될 때마다 우측 패널 내용물 검사
    // 경로변경 예시: 뒤로가기, 링크클릭 등
    const subMenuIdx = currentMenuVisitRoute.value.findIndex((menu) => menu.subMenuList?.length);
    const { rootMenu, menuType } = router.currentRoute.value.query; // for dashboard & analysisboard
    const { id } = router.currentRoute.value.params; // for dashboard & analysisboard

    if (currentRootMenu.value === BASE_MENU_VALUE.SETTINGS) {
      setSettingPanel();
      return;
    }
    if (currentRootMenu.value === BASE_MENU_VALUE.HELP) {
      setHelpPanel();
      return;
    }

    if (panelMenu.value.menuInfo) {
      const keepOpenValue: string[] = [BASE_MENU_VALUE.KUBERNETES, BASE_MENU_VALUE.CLOUD];

      if (currentRootMenu.value === BASE_MENU_VALUE.PA && subMenuIdx !== -1) {
        openMenu(currentMenuVisitRoute.value[subMenuIdx]);
        selectPanelMenu(currentMenuVisitRoute.value[4].value);
      } else if (keepOpenValue.includes(currentRootMenu.value) && subMenuIdx !== -1) {
        openMenu(currentMenuVisitRoute.value[subMenuIdx]);
        selectPanelMenu(currentMenuVisitRoute.value[4].value);
      } else if (menuType === DASHBOARD_MENU_TYPE.DASHBOARD) {
        openMenu({
          text: t('WORD.GNB.DASHBOARD'),
          value: 'dashboard',
          subMenuList: dashboardMenuInfo.value[rootMenu as string] ?? [],
        });
        selectPanelMenu(`dashboard_Dashboard View_${id}`);
      } else if (menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD) {
        openMenu({
          text: t('WORD.GNB.ANALYSISBOARD'),
          value: 'analysisBoard',
          subMenuList: analysisBoardMenuInfo.value[rootMenu as string] ?? [],
        });
        selectPanelMenu(`dashboard_Dashboard View_${id}`);
      } else {
        closeMenu();
      }
    } else if (
      menuType === DASHBOARD_MENU_TYPE.DASHBOARD ||
      menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD
    ) {
      selectMenu({ menuValue: currentRootMenu.value, subMenuValue: menuType });
    }
  };

  watch(
    hoveredMenuInfo,
    async (hoverInfo) => {
      await nextTick();

      if (hoverInfo.menuInfo?.value === BASE_MENU_VALUE.SETTINGS) {
        return;
      }

      const floatingMenuEl = floatingMenuRef.value?.$el ?? floatingMenuRef.value;
      const baseMenuEl = baseMenuRef.value?.$el ?? baseMenuRef.value;

      if (!floatingMenuEl || !baseMenuEl) {
        return;
      }

      const floatingMenuRect = floatingMenuEl.getBoundingClientRect();
      const baseMenuRect = baseMenuEl.getBoundingClientRect();
      const { clientWidth } = document.documentElement;
      const headerHeight = clientWidth > 1600 ? 48 : 40;

      if (floatingMenuRect.height >= baseMenuRect.height) {
        hoveredMenuInfo.value.top = headerHeight;
      } else if (floatingMenuRect.height + hoverInfo.top > baseMenuRect.height) {
        hoveredMenuInfo.value.top = baseMenuRect.height - floatingMenuRect.height;
      } else {
        hoveredMenuInfo.value.top = hoverInfo.top;
      }
    },
    { deep: true },
  );

  watch(
    router.currentRoute,
    (newRoute) => {
      // console.log(newRoute, currentRootMenu.value, currentMenuVisitRoute.value);
      updateLastMenu(currentRootMenu.value, newRoute.fullPath);
      menuVisitRouteStore.value = currentMenuVisitRoute.value;
      const SUB_MENU_IDX = currentMenuVisitRoute.value.length >= 3 ? 2 : 1;
      selectMenu({
        menuValue: currentRootMenu.value,
        subMenuValue: currentMenuVisitRoute.value?.[SUB_MENU_IDX]?.value,
      });

      // NOTE: 이 밑의 로직은 GNB 설정값 계정 저장시 전체적으로 바꿀 필요 있음
      // 기본적으로 settings, help 의 경우 관련 페이지 접근 시 우측 패널 열리게끔 짜여져 있으나
      // GNB 설정값 계정 저장 기능 구현할 경우 우측 패널 자동 Open 기능이 무의미하기 때문

      onPathChange();
    },
    { immediate: true },
  );

  watch(
    [dashboardMenuInfo, analysisBoardMenuInfo],
    ([dashboardMenu, analysisMenu]) => {
      // NOTE: 대시보드 메뉴랑 분석보드 메뉴는 서버에서 받아오기 때문에 업데이트가 느림
      // 경로 바꾸는 시점에서 해당 메뉴목록이 비어있을 수 있어서 watch 통해 refetch 필요
      if (panelMenu.value.menuInfo) {
        const { rootMenu, menuType } = router.currentRoute.value.query; // for dashboard & analysisboard
        if (menuType === DASHBOARD_MENU_TYPE.DASHBOARD) {
          panelMenu.value.menuInfo.subMenuList = dashboardMenu[rootMenu as string] ?? [];
        } else if (menuType === DASHBOARD_MENU_TYPE.ANALYSIS_DASHBOARD) {
          panelMenu.value.menuInfo.subMenuList = analysisMenu[rootMenu as string] ?? [];
        }
      }
    },
    { deep: true },
  );

  return {
    t,

    menuInfo,
    dashboardMenuInfo,
    analysisBoardMenuInfo,
    helpMenuInfo,
    myInfoMenuInfo,

    productName,

    expandedMenu,
    hoveredMenuInfo,
    isMyInfoPopShown,

    floatingMenuRef,
    baseMenuRef,
    tooltipMouseEvent,
    isBaseMenuHidden,

    onMouseEventInTarget,
    onHoverMenu,
    onExpandMenu,
    onExpandMyInfoMenu,
    onExpandSupportMenu,
    onShrinkMenu,
    onHideMenu,
    onHideMyInfoPop,
    onClickTitle,
    onClickSetting,
    onClickOutsideMenu,
  };
};

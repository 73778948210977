import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { TargetTagType } from '@/common/utils/types';
import { capitalize, cloneDeep } from 'lodash-es';
import { TAG_TYPE_KEY } from '@/common/define/widget.define';
import {
  addUserFilterIntegratedDashboardFilterV7ControllerAxios,
  deleteUserFilterIntegratedDashboardFilterV7ControllerAxios,
  getGlobalFilterIntegratedDashboardFilterV7ControllerAxios,
  getUserFilterIntegratedDashboardFilterV7ControllerAxios,
  globalFilterIntegratedDashboardFilterV7ControllerAxios,
} from '@/openapi/metaV6/api/integrated-dashboard-filter-v7-controller-api';
import {
  GlobalFilterItem,
  IntegrateDashboardUserFilterRequest,
  TargetValue,
  UserFilterItem,
} from '@/openapi/metaV6/model';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { formatTimeRangeToTz } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { GlobalVarFilterDataForView } from '@/dashboard/components/globalVarFilter/globalVarFilter.type';
import { webStorageController } from '@/common/utils/webStorage.util';
import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import { useInternational } from '@/common/locale';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import dayjs from 'dayjs';
import { standardTimeToUtcZeroTime } from '@/common/utils/commonUtils';
import { getAlertHistoriesForDashboardAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { getDeviceInterfacesNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { addXmUserEnvControllerAxios } from '@/openapi/metav8Over/api/xm-user-env-controller-api';

export interface TargetTag {
  groupTagId: string; // tag key
  tagId: string; // tag value
  tagType: TargetTagType;
}

export interface TargetTagData extends TargetTag {
  groupTagName: string;
  tagName: string;
}

export interface GlobalVariablesData {
  aliasName: string;
  targets: TargetTagData[]; // selected
  tagKey: string;
}

export type FilterVariableOperationType = 'AND' | 'OR';

export interface GlobalVariables extends GlobalVariablesData {
  globalFilterId: number;
  isAster: boolean;
  isMultiSelectType: boolean;
  use: boolean;
  variableType: 'tag' | 'target';
  operationType: FilterVariableOperationType;
  allTargets: TargetTagData[];
}

export interface SelectedGlobalVariables {
  globalFilterId: number;
  aliasName: string;
  category: string;
  selectedList: TargetTagData[] | undefined;
  use: boolean;
}

export interface UpdatedGlobalVariableData {
  globalFilterId?: number;
  alias: string;
  targets: {
    tagKey: string;
    tagType: string;
    tagValueId: string;
  }[];
  isSingle: boolean;
  isTarget: boolean;
}

export interface UpdatedGlobalVariablesRequest {
  updateFilters: UpdatedGlobalVariableData[];
  addFilters: UpdatedGlobalVariableData[];
  deleteGlobalFilterIds: number[];
}

export interface FilterVariablePreset {
  userFilterId: number;
  name: string;
  filters: SelectedGlobalVariables[];
}

export const convertOperationTypeFromDto = (operationType: string): FilterVariableOperationType => {
  switch (operationType) {
    case 'AND':
      return 'AND';
    case 'OR':
      return 'OR';
    default:
      return 'AND';
  }
};

export const useGlobalFilterStore = defineStore('dashboardGlobalFilter', () => {
  const { t } = useInternational();
  const globalVariables = ref<GlobalVariables[]>([]);
  const selectedGlobalVariables = ref<SelectedGlobalVariables[]>([]);

  const DEFAULT_PRESET_ID = -1;
  const defaultPreset: FilterVariablePreset = {
    userFilterId: DEFAULT_PRESET_ID,
    name: t('WORD.DEFAULT_PRESET'),
    filters: [],
  };

  const store: Store<RootState> = useStore();

  const monitoringDashboardId = ref<number>(-1);
  const setMonitoringDashboardId = async (dashboardId: number) => {
    monitoringDashboardId.value = dashboardId;
  };

  const getTargetTagByTargetData = (
    globalVariable: GlobalVariables | undefined,
    targetValue: TargetValue | undefined,
  ): TargetTagData | undefined =>
    globalVariable?.allTargets?.find(
      ({ groupTagId, tagId, tagType }) =>
        targetValue?.tagKey === groupTagId &&
        targetValue?.tagValueId === tagId &&
        targetValue?.tagType === tagType,
    );

  const convertToVariableType = (isTarget: boolean) => {
    if (isTarget === true) {
      return 'target';
    }
    return 'tag';
  };

  const getGlobalVariableById = async (globalFilterId: number): Promise<TargetTagData[]> => {
    let result: TargetTagData[] = [];

    try {
      const { data } = await getGlobalFilterIntegratedDashboardFilterV7ControllerAxios({
        dashboardId: monitoringDashboardId.value,
        globalFilterId,
        frameName: FRAME_NAMES.DASHBOARD.GLOBAL_VARIABLES,
      });

      if (data?.data?.length) {
        result =
          data?.data?.[0]?.globalFilterKeyValues?.map((target) => ({
            groupTagId: target.tagKey ?? '',
            groupTagName: capitalize(target.tagKey ?? ''),
            tagId: target.tagValueId ?? '',
            tagName: target.tagName ?? '',
            tagType: (target.tagType as TargetTagType) ?? TAG_TYPE_KEY.TAG,
          })) ?? [];
      }
    } catch (e) {
      console.log(e);
    }

    return result;
  };

  const fetchGlobalVariables = async () => {
    try {
      const { data } = await getGlobalFilterIntegratedDashboardFilterV7ControllerAxios({
        dashboardId: monitoringDashboardId.value,
        frameName: FRAME_NAMES.DASHBOARD.GLOBAL_VARIABLES,
      });

      globalVariables.value = await Promise.all(
        data.data?.map(async (item: GlobalFilterItem) => {
          const targets: TargetTagData[] =
            item.globalFilterKeyValues?.map((target) => ({
              groupTagId: target.tagKey ?? '',
              groupTagName: capitalize(target.tagKey ?? ''),
              tagId: target.tagValueId ?? '',
              tagName: target.tagName ?? '',
              tagType: (target.tagType as TargetTagType) ?? TAG_TYPE_KEY.TAG,
            })) ?? [];

          const defaultVariableType =
            item.globalFilterKeyValues?.at(0)?.tagType === 'attribute' ? 'tag' : 'target';

          const variableType =
            item.target != null ? convertToVariableType(item.target) : defaultVariableType;

          let allTargets: TargetTagData[] = targets;
          if (variableType === 'target') {
            allTargets = await getGlobalVariableById(item.globalFilterId ?? -1);
          }

          return {
            globalFilterId: item.globalFilterId ?? -1,
            aliasName: item.alias ?? '',
            tagKey: item.tagKey ?? '',
            targets,
            allTargets,
            isAster: item.isAster ?? false,
            use: true,
            isMultiSelectType: !item.single,
            variableType,
            operationType: convertOperationTypeFromDto(
              item.operationType ?? (defaultVariableType === 'tag' ? 'AND' : 'OR'),
            ),
          };
        }) ?? [],
      );

      if (globalVariables.value.length) {
        defaultPreset.filters = globalVariables.value.map((item) => ({
          globalFilterId: item.globalFilterId,
          aliasName: item.aliasName,
          category: item.tagKey,
          selectedList: [],
          use: item.use,
        }));
      } else {
        defaultPreset.filters = [];
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchGlobalVariableById = async (globalFilterId: number) => {
    try {
      const { data } = await getGlobalFilterIntegratedDashboardFilterV7ControllerAxios({
        dashboardId: monitoringDashboardId.value,
        globalFilterId,
        frameName: FRAME_NAMES.DASHBOARD.GLOBAL_VARIABLES,
      });
      if (data?.data?.length) {
        const globalVariable = globalVariables.value.find(
          (item) => item.globalFilterId === globalFilterId,
        );
        if (globalVariable) {
          globalVariable.allTargets =
            data?.data?.[0]?.globalFilterKeyValues?.map((target) => ({
              groupTagId: target.tagKey ?? '',
              groupTagName: capitalize(target.tagKey ?? ''),
              tagId: target.tagValueId ?? '',
              tagName: target.tagName ?? '',
              tagType: (target.tagType as TargetTagType) ?? TAG_TYPE_KEY.TAG,
            })) ?? [];
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateGlobalVariables = async ({
    updateFilters,
    addFilters,
    deleteGlobalFilterIds,
  }: UpdatedGlobalVariablesRequest) => {
    await globalFilterIntegratedDashboardFilterV7ControllerAxios({
      dashboardId: monitoringDashboardId.value,
      deleteGlobalFilterIds,
      request: {
        updateFilters,
        addFilters,
      },
      frameName: FRAME_NAMES.DASHBOARD.GLOBAL_VARIABLES_UPDATE,
    });
  };

  const updatedGlobalVariableId = ref<number>();

  const updateGlobalVariablesSelectedById = (
    globalFilterId: number,
    selectedList: TargetTagData[],
  ) => {
    const globalVarSelected = selectedGlobalVariables.value.find(
      (item) => item.globalFilterId === globalFilterId,
    );

    if (globalVarSelected) {
      updatedGlobalVariableId.value = globalFilterId;
      globalVarSelected.selectedList = cloneDeep(selectedList);
    }
  };

  const presetList = ref<FilterVariablePreset[]>([defaultPreset]);
  const currentPreset = ref<FilterVariablePreset>(defaultPreset);
  const CURRENT_PRESET_KEY = 'DASHBOARD_CURRENT_PRESET_ID';
  const currentPresetUserEnv = computed<Record<number, number>>(() => {
    const userEnvMap = store.getters['userEnv/getUserEnvMap'];
    return JSON.parse(userEnvMap.get(CURRENT_PRESET_KEY) ?? '{}');
  });

  const updateSelectedGlobalVariablesByAllTarget = async (targetId: string) => {
    const targetList = globalVariables.value!;
    if (targetList.length > 0) {
      targetList.forEach((filterItem: GlobalVariables) => {
        const filteredTargets = filterItem.allTargets.filter((i) => `${targetId}` === i.tagId);
        updateGlobalVariablesSelectedById(filterItem.globalFilterId, filteredTargets);
      });
    }
    await fetchGlobalVariables();
  };

  const updateSelectedGlobalVariablesByQueryTag = async (tag: string) => {
    if (!(tag && globalVariables.value?.length)) {
      return;
    }

    // tag는 하나만 들어온다고 가정
    const [target, targetId, namespace] = tag.split('+');
    const matchedFilter = globalVariables.value.find((v) => v.tagKey === target);

    if (target === 'database') {
      await updateSelectedGlobalVariablesByAllTarget(targetId);
      return;
    }

    if (!matchedFilter) return;
    const { globalFilterId, tagKey } = matchedFilter;
    if (namespace && namespace !== 'undefined') {
      const tagType = matchedFilter.allTargets.find(({ tagName }) => tagName === namespace)
        ?.tagType;
      const selectedList = [`${tagKey}--${tagType}--${namespace}`];
      const selectedVariables = selectedList.map((item) => {
        const [, , tagName] = item.split('--');

        const allList: TargetTagData[] =
          globalVariables.value.find((variable) => variable.globalFilterId === globalFilterId)
            ?.allTargets ?? [];

        return allList?.find((tagData) => tagData.tagName === tagName);
      }) as TargetTagData[];

      updateGlobalVariablesSelectedById(globalFilterId, selectedVariables);
    } else {
      const tagType = matchedFilter.allTargets.find(({ tagId }) => tagId === targetId)?.tagType;

      if (globalFilterId && tagType) {
        const selectedList = [`${tagKey}--${tagType}--${targetId}`];
        const selectedVariables = selectedList.map((item) => {
          const [, , tagId] = item.split('--');

          const allList: TargetTagData[] =
            globalVariables.value.find((variable) => variable.globalFilterId === globalFilterId)
              ?.allTargets ?? [];

          return allList?.find((tagData) => tagData.tagId === tagId);
        }) as TargetTagData[];

        if (target === 'network_device') {
          const { data } = await getDeviceInterfacesNdmMonitoringControllerAxios({
            deviceId: targetId,
          });

          const targetList = data.data!;
          if (targetList.length > 0) {
            globalVariables.value.forEach((filterItem: GlobalVariables) => {
              const filteredTargets = filterItem.targets.filter((i) =>
                targetList.some((tObj) => `${targetId}|${tObj.index}` === i.tagId),
              );
              if (globalFilterId === filterItem.globalFilterId) {
                updateGlobalVariablesSelectedById(globalFilterId, selectedVariables);
              } else {
                updateGlobalVariablesSelectedById(filterItem.globalFilterId, filteredTargets);
              }
            });
          }
          await fetchGlobalVariables();
        } else {
          updateGlobalVariablesSelectedById(globalFilterId, selectedVariables);
        }
      }
    }
  };

  const updateSelectedGlobalVariablesByQueryAlert = async (alertId: string) => {
    if (!alertId) {
      return;
    }

    const { data } = await getAlertHistoriesForDashboardAlertCommonControllerAxios({
      alertResultId: alertId,
    });

    const targetList = data.data!;

    if (targetList.length > 0) {
      globalVariables.value.forEach((filterItem: GlobalVariables) => {
        const filteredTargets = filterItem.allTargets.filter((i) =>
          targetList.some((tObj) => tObj.targetId === i.tagId),
        );

        updateGlobalVariablesSelectedById(filterItem.globalFilterId, filteredTargets);
      });
    }

    await fetchGlobalVariables();
  };

  const saveCurrentPresetUserEnv = async (presetId: number) => {
    if (monitoringDashboardId.value !== -1) {
      currentPresetUserEnv.value[monitoringDashboardId.value] = presetId;
      const presetIdsByDashboard = JSON.stringify(currentPresetUserEnv.value);
      await addXmUserEnvControllerAxios({
        request: [
          {
            key: CURRENT_PRESET_KEY,
            value: presetIdsByDashboard,
          },
        ],
        frameName: `${FRAME_NAMES.USER_ENVIRONMENT.SAVE} ${CURRENT_PRESET_KEY}`,
      });
      store.commit(
        'userEnv/setUserEnvMap',
        { key: CURRENT_PRESET_KEY, value: presetIdsByDashboard },
        { root: true },
      );
    }
  };

  const setCurrentPresetById = (presetId: number) => {
    const target = presetList.value.find(({ userFilterId }) => userFilterId === presetId);
    if (target) {
      currentPreset.value = target;
      selectedGlobalVariables.value = cloneDeep(target.filters);
    }
  };

  const setDefaultPreset = () => {
    setCurrentPresetById(DEFAULT_PRESET_ID);
  };

  // 초기 로딩 시 userEnv에 저장되어있는 presetId로 preset을 설정
  const initCurrentPreset = () => {
    const presetId = currentPresetUserEnv.value[monitoringDashboardId.value ?? -1];
    if (presetId) {
      setCurrentPresetById(presetId);
    } else {
      setDefaultPreset();
    }
  };

  const getPresetFilters = (data: UserFilterItem): SelectedGlobalVariables[] => {
    return globalVariables.value?.reduce(
      (presetFilters: SelectedGlobalVariables[], globalVariable) => {
        const globalFilterTargetsIds: string[] =
          globalVariable?.allTargets?.map(
            ({ groupTagId, tagType, tagId }) => `${groupTagId}--${tagType}--${tagId}`,
          ) ?? [];

        const filter = data?.filters?.find(
          ({ globalFilterId }) => globalFilterId === globalVariable.globalFilterId,
        );
        if (filter) {
          presetFilters.push({
            globalFilterId: globalVariable.globalFilterId,
            aliasName: globalVariable.aliasName,
            use: filter.enable ?? true,
            category: globalVariable.tagKey,
            selectedList:
              filter.targets
                ?.filter(({ tagKey: groupTagId, tagType, tagValueId: tagId }) => {
                  const id = `${groupTagId}--${tagType}--${tagId}`;
                  return globalFilterTargetsIds.includes(id);
                })
                ?.map((filteredTarget: TargetValue) => ({
                  groupTagName: capitalize(filteredTarget.tagKey ?? ''),
                  tagName:
                    filteredTarget.tagName ??
                    getTargetTagByTargetData(globalVariable, filteredTarget)?.tagName ??
                    '',
                  groupTagId: filteredTarget.tagKey ?? '',
                  tagId: filteredTarget.tagValueId ?? '',
                  tagType: filteredTarget.tagType as TargetTagType,
                })) ?? [],
          });
        } else {
          presetFilters.push({
            globalFilterId: globalVariable.globalFilterId,
            aliasName: globalVariable.aliasName,
            category: globalVariable.tagKey,
            selectedList: [],
            use: globalVariable.use,
          });
        }
        return presetFilters;
      },
      [],
    );
  };

  const fetchPresetList = async () => {
    try {
      const response = await getUserFilterIntegratedDashboardFilterV7ControllerAxios({
        dashboardId: monitoringDashboardId.value,
        frameName: FRAME_NAMES.DASHBOARD.PRESET_LIST,
      });

      const presets: FilterVariablePreset[] =
        response?.data?.data?.map((data) => ({
          name: data?.name ?? '',
          userFilterId: data?.userFilterId ?? -1,
          filters: getPresetFilters(data),
        })) ?? [];

      if (presets?.length) {
        presetList.value = [defaultPreset, ...presets];
      } else {
        presetList.value = [defaultPreset];
      }
    } catch (e) {
      console.log(e);
    }
  };

  const savePreset = async (data: { name: string; filters: GlobalVarFilterDataForView[] }) => {
    if (monitoringDashboardId.value !== -1) {
      const request: IntegrateDashboardUserFilterRequest = {
        filters:
          data.filters?.map(({ globalFilterId, selectedList, use }) => ({
            globalFilterId,
            targets: selectedList.map((selected) => {
              const [tagKey, tagType, tagValueId] = selected.split('--');
              return {
                tagKey,
                tagType,
                tagValueId,
              };
            }),
            isEnable: use,
          })) ?? [],
      };

      const response = await addUserFilterIntegratedDashboardFilterV7ControllerAxios({
        dashboardId: monitoringDashboardId.value,
        name: data.name,
        request,
      });
      await fetchGlobalVariables();
      await fetchPresetList();

      const savedPresetId =
        response?.data?.userFilterId ??
        presetList.value?.[presetList.value.length - 1]?.userFilterId;
      setCurrentPresetById(savedPresetId);
      await saveCurrentPresetUserEnv(savedPresetId);
    }
  };

  const deletePreset = async (targetUserFilterIds: number[]) => {
    if (monitoringDashboardId.value !== -1) {
      await Promise.all(
        targetUserFilterIds.map((id) =>
          deleteUserFilterIntegratedDashboardFilterV7ControllerAxios({
            dashboardId: monitoringDashboardId.value,
            userFilterId: id,
          }),
        ),
      );
      await fetchGlobalVariables();
      await fetchPresetList();

      if (targetUserFilterIds.includes(currentPreset.value.userFilterId)) {
        setCurrentPresetById(DEFAULT_PRESET_ID);
        await saveCurrentPresetUserEnv(DEFAULT_PRESET_ID);
      }
    }
  };

  const globalTimePeriod = ref<TimePeriodInfo>(getInitPeriodInfo());
  const globalTimePeriodRecord = ref<Record<string, Omit<TimePeriodInfo, 'toTime' | 'fromTime'>>>(
    {},
  );
  const localStorageKey = 'global-time-period';
  let isInit = false;

  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  const updateGlobalTimePeriodRecord = (timePeriodData: TimePeriodInfo) => {
    if (monitoringDashboardId.value === -1 || userInfo.value.userId === -1) {
      return;
    }

    const recordKey = `${userInfo.value.userId}-${monitoringDashboardId.value}`;
    globalTimePeriodRecord.value[recordKey] = {
      fromTimeUtc: timePeriodData.fromTimeUtc,
      toTimeUtc: timePeriodData.toTimeUtc,
      isPaused: timePeriodData.isPaused,
      timePeriod: timePeriodData.timePeriod,
    };
    webStorageController.setItem({
      type: 'local',
      key: localStorageKey,
      value: JSON.stringify(globalTimePeriodRecord.value),
    });
  };

  const initGlobalTimePeriodRecord = () => {
    const initialGlobalTimePeriod = webStorageController.getItem({
      type: 'local',
      key: localStorageKey,
    });
    if (initialGlobalTimePeriod) {
      globalTimePeriodRecord.value = JSON.parse(initialGlobalTimePeriod);
    }
  };

  const setGlobalTimePeriodByRecord = async (queryInfo?) => {
    if (monitoringDashboardId.value !== -1 && userInfo.value.userId !== -1) {
      const recordKey = `${userInfo.value.userId}-${monitoringDashboardId.value}`;
      const localStorageTimePeriod = globalTimePeriodRecord.value[recordKey];
      if (queryInfo) {
        globalTimePeriod.value = localStorageTimePeriod
          ? {
              ...localStorageTimePeriod,
              ...formatTimeRangeToTz({
                fromTimeUtc: queryInfo.timeInfo
                  ? standardTimeToUtcZeroTime(
                      dayjs(queryInfo.timeInfo).subtract(5, 'minute').format('YYYY-MM-DD HH:mm:ss'),
                    )
                  : localStorageTimePeriod.fromTimeUtc,
                toTimeUtc: queryInfo.timeInfo
                  ? standardTimeToUtcZeroTime(
                      dayjs(queryInfo.timeInfo).add(5, 'minute').format('YYYY-MM-DD HH:mm:ss'),
                    )
                  : localStorageTimePeriod.toTimeUtc,
              }),
              timePeriod: queryInfo.alertId ? 'p10m' : localStorageTimePeriod.timePeriod,
              isPaused: queryInfo.alertId ? true : localStorageTimePeriod.isPaused,
            }
          : getInitPeriodInfo();
        isInit = true;
      } else {
        globalTimePeriod.value = localStorageTimePeriod
          ? {
              ...localStorageTimePeriod,
              ...formatTimeRangeToTz({
                fromTimeUtc: localStorageTimePeriod.fromTimeUtc,
                toTimeUtc: localStorageTimePeriod.toTimeUtc,
              }),
            }
          : getInitPeriodInfo();
        isInit = true;
      }
    }
  };

  const updateGlobalTimePeriod = (timePeriodData: TimePeriodInfo) => {
    globalTimePeriod.value = timePeriodData;
    if (isInit) {
      updateGlobalTimePeriodRecord(timePeriodData);
    }
  };

  const resetGlobalTimePeriodInit = () => {
    isInit = false;
  };

  const clearSelectedList = () => {
    selectedGlobalVariables.value = selectedGlobalVariables.value.map((variable) => ({
      ...variable,
      selectedList: [],
    }));
  };

  return {
    monitoringDashboardId,
    setMonitoringDashboardId,

    globalVariables,
    selectedGlobalVariables,
    fetchGlobalVariables,
    fetchGlobalVariableById,
    updateGlobalVariables,
    updatedGlobalVariableId,
    updateGlobalVariablesSelectedById,
    updateSelectedGlobalVariablesByQueryTag,
    updateSelectedGlobalVariablesByQueryAlert,

    presetList,
    currentPreset,
    defaultPreset,
    fetchPresetList,
    setCurrentPresetById,
    setDefaultPreset,
    initCurrentPreset,
    saveCurrentPresetUserEnv,
    savePreset,
    deletePreset,

    globalTimePeriod,
    updateGlobalTimePeriod,

    initGlobalTimePeriodRecord,
    setGlobalTimePeriodByRecord,
    resetGlobalTimePeriodInit,
    clearSelectedList,
  };
});

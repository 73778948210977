<script setup lang="ts">
import LoadingBar from '@/ai/components/LoadingBar.vue';
import { setup } from './chatWindow.setup';
import UserMessage from './UserMessage.vue';
import BotMessage from './BotMessage.vue';

const {
  position,
  startDrag,
  size,
  startResize,
  userInput,
  isShowWindow,
  toggleChat,
  sendMessage,
  isLoading,
  allMessages,
  handleMessageClick,
  container,
  onEnter,
} = setup();
</script>

<template>
  <transition name="fade">
    <div
      v-if="isShowWindow"
      class="chat-window__wrapper"
      :style="{
        bottom: position.bottom + 'px',
        right: position.right + 'px',
        width: size.width + 'px',
        height: size.height + 'px',
      }"
    >
      <div
        ref="container"
        class="chat-window__content"
        @mousedown="
          (e) => {
            if (!e.target.classList.contains('bot-message--message')) startDrag(e);
          }
        "
      >
        <button
          class="chat-window__close-btn"
          @click="toggleChat"
        >
          <ev-icon class="icon-close" />
        </button>
        <div
          v-for="(message, idx) in allMessages"
          :key="idx"
          class="chat-window__content__message"
        >
          <user-message
            v-if="message.sender !== 'chatbot'"
            :message="message"
          />
          <bot-message
            v-else
            :message="message"
            @message-click="handleMessageClick"
          />
        </div>
        <loading-bar v-if="isLoading" />
      </div>

      <div class="chat-window__input-section">
        <input
          v-model="userInput"
          :disabled="isLoading"
          placeholder="메시지를 입력"
          class="chat-window__input-section__input"
          @keyup.enter="onEnter"
        />
        <ev-icon
          class="icon-arrow-circle-up"
          @click="sendMessage"
        />
      </div>

      <div class="chat-window__description">
        성능 분석 모니터링 관련 질문을 중심으로 도와드리고 있습니다.<br />
        엑셈원 외 다른 주제에 대한 질문에는 정확한 답변을 드리기 어려울 수 있는 점 양해
        부탁드립니다.
      </div>
      <div
        class="chat-window__resize-handle"
        @mousedown.stop="startResize"
      />
    </div>
  </transition>
</template>

<style scoped lang="scss">
.chat-window {
  &__wrapper {
    position: fixed;
    z-index: 1000;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 8px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(180deg, #3c8eff 0%, #6c4fff 100%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    box-shadow: 0 0 40px 0 #3c8eff57;
  }

  &__content {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    height: 83%;
    max-height: 83%;
    padding: 10px;
    overflow-y: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f8f9fa;
    background-image: url('@/common/assets/ai/chat-window-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    &__message {
      margin-bottom: 20px;
    }
  }

  &__close-btn {
    position: sticky;
    top: 0;
    right: 10px;
    z-index: 10;
    width: 24px;
    height: 24px;
    padding: 2px;
    cursor: pointer;
    border-radius: 2px;
    background-color: #f8f9fa;

    &:hover {
      background-color: #bfdbfe;
    }

    &:active {
      border: 1px solid #3b82f6;
      background-color: #bfdbfe;
    }
  }

  &__loading-bar {
    width: 20px;
    height: 20px;
  }

  &__input-section {
    display: flex;
    padding: 10px;
    border-radius: 12px;

    &__input {
      flex-grow: 1;
      height: 42px;
      padding: 10px;
      padding-right: 40px;
      border: 1px solid transparent;
      border-radius: 8px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(180deg, #3c8eff 0%, #6c4fff 100%);
      background-clip: padding-box, border-box;
      background-origin: border-box;
    }
    .icon-arrow-circle-up {
      position: absolute;
      right: 20px;
      width: 28px;
      height: 42px;
      cursor: pointer;
      background: linear-gradient(135deg, #3c8eff 8.57%, #6c4fff 75%);
    }
  }

  &__resize-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    cursor: se-resize;
  }

  &__description {
    padding: 10px 16px 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #626872;
  }
}
</style>

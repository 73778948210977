/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  ApiResponseAlertMessageItem,
  ApiResponseDeviceInfoResponse,
  ApiResponseDeviceInterfaceResponse,
  ApiResponseDeviceSnmpTrapResponse,
  ApiResponseDeviceSysLogResponse,
  ApiResponseDeviceWithPerfResponse,
  ApiResponseFilterItem,
  ApiResponseTopGroupResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getDeviceAlertMessageNdmMonitoringControllerAxios = ({
  alertRuleId,
  deviceId,
  eventType,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  alertRuleId: string;
  deviceId: string;
  eventType?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseAlertMessageItem> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/alert/{deviceId}/message/{alertRuleId}'
    .replace(`{${'alertRuleId'}}`, encodeURIComponent(String(alertRuleId)))
    .replace(`{${'deviceId'}}`, encodeURIComponent(String(deviceId)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      eventType,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDeviceInfoNdmMonitoringControllerAxios = ({
  deviceId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  deviceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDeviceInfoResponse> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/device-info/{deviceId}'.replace(
    `{${'deviceId'}}`,
    encodeURIComponent(String(deviceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDeviceListWithPerformanceNdmMonitoringControllerAxios = ({
  toTime,
  fromTime,
  groupIds,
  models,
  vendors,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  toTime: string;
  fromTime?: string;
  groupIds?: Array<string>;
  models?: Array<string>;
  vendors?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDeviceWithPerfResponse> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/device-with-perf';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      groupIds,
      models,
      toTime,
      vendors,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSearchModelsNdmMonitoringControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseFilterItem> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/filter';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDeviceInterfacesNdmMonitoringControllerAxios = ({
  deviceId,
  search,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  deviceId: string;
  search?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDeviceInterfaceResponse> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/interface/{deviceId}'.replace(
    `{${'deviceId'}}`,
    encodeURIComponent(String(deviceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      search,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDeviceSnmptrapNdmMonitoringControllerAxios = ({
  deviceId,
  fromTime,
  toTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  deviceId: string;
  fromTime: string;
  toTime: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDeviceSnmpTrapResponse> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/snmptrap/{deviceId}'.replace(
    `{${'deviceId'}}`,
    encodeURIComponent(String(deviceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDeviceSysLogNdmMonitoringControllerAxios = ({
  deviceId,
  toTime,
  fromTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  deviceId: string;
  toTime: string;
  fromTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseDeviceSysLogResponse> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/syslog/{deviceId}'.replace(
    `{${'deviceId'}}`,
    encodeURIComponent(String(deviceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTopGroupsNdmMonitoringControllerAxios = ({
  toTime,
  fromTime,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  toTime: string;
  fromTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseTopGroupResponse> => {
  const localVarPath = '/api/v7/ndm/device/monitoring/top-group';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      toTime,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getDeviceAlertMessageNdmMonitoringControllerAxios,
  getDeviceInfoNdmMonitoringControllerAxios,
  getDeviceListWithPerformanceNdmMonitoringControllerAxios,
  getSearchModelsNdmMonitoringControllerAxios,
  getDeviceInterfacesNdmMonitoringControllerAxios,
  getDeviceSnmptrapNdmMonitoringControllerAxios,
  getDeviceSysLogNdmMonitoringControllerAxios,
  getTopGroupsNdmMonitoringControllerAxios,
};

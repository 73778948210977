import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { getSlideDetailStore, SlideTabPanelInfo } from '@/common/stores/slide-detail';
import { getAlertDetailProps, getAlertType } from '@/alert/utils/utils';
import { AlertSubType, RuleInfoProps } from '@/alert/components/alertDetail/alertDetail.types';
import { isEqual } from 'lodash-es';
import { useInternational } from '@/common/locale';
import { useTargetDetailProvide } from '@/alert/components/alertTargetList/alertTargetList.uses';
import { useAlertRule } from '@/alert/components/alertDetail/alertDetail.use';

export interface Props {
  ruleInfo?: RuleInfoProps;
}
export interface Emit {
  (e: 'openDetail'): void;
  (e: 'clear'): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const { selectedSlideTabPanel } = storeToRefs(getSlideDetailStore());
  const selectedRuleInfo = ref<RuleInfoProps>({
    name: '',
    type: 'user',
  });

  const tabPanels = [
    {
      text: t('WORD.INFORMATION'),
      value: 'information',
    },
    {
      text: t('WORD.CURRENT'),
      value: 'current',
    },
    {
      text: t('WORD.HISTORY'),
      value: 'history',
    },
  ];
  const selectedTab = ref('information');
  const initSelectedTab = () => {
    selectedTab.value = 'information';
  };

  useTargetDetailProvide(emit);

  const { getUserAlertRule } = useAlertRule();
  const getRuleInfo = async ({
    name,
    ruleId,
    type,
    subType,
  }: {
    name: string;
    ruleId?: string;
    type: string;
    subType?: AlertSubType;
  }): Promise<RuleInfoProps> => {
    if (getAlertType(type) === 'user') {
      const { ruleCriteria } = await getUserAlertRule({
        ruleId,
        field: 'ruleCriteria',
      });
      return {
        ...getAlertDetailProps({ name, ruleId, type }),
        subType,
        ruleCriteria,
      };
    }

    return {
      ...getAlertDetailProps({ name, ruleId, type }),
      subType,
    };
  };

  watch(
    (): [SlideTabPanelInfo | undefined, Props['ruleInfo']] => [
      selectedSlideTabPanel.value,
      props.ruleInfo,
    ],
    async ([currTabInfo, currRuleInfoProp], prev) => {
      if (currTabInfo?.type === 'Alert' && !currRuleInfoProp) {
        selectedRuleInfo.value = await getRuleInfo({
          name: currTabInfo?.name ?? '',
          ruleId: currTabInfo?.addition?.ruleId,
          type: currTabInfo?.addition?.type ?? 'user',
          subType: currTabInfo?.addition?.subType,
        });
        if (
          currTabInfo?.addition?.ruleId !== prev?.[0]?.addition?.ruleId ||
          currTabInfo?.name !== prev?.[0]?.name
        ) {
          initSelectedTab();
        }
      } else if (currRuleInfoProp && !isEqual(currRuleInfoProp, prev?.[1])) {
        selectedRuleInfo.value = await getRuleInfo({
          name: currRuleInfoProp?.name ?? '',
          ruleId: currRuleInfoProp?.ruleId,
          type: currRuleInfoProp?.type ?? 'user',
          subType: currTabInfo?.addition?.subType,
        });
        initSelectedTab();
      }
    },
    { deep: true, immediate: true },
  );
  return {
    tabPanels,
    selectedTab,
    selectedRuleInfo,
    selectedSlideTabPanel,
  };
};

<script setup lang="ts">
import { Props, setup } from './DashBoardListPopup.setup';

const props = defineProps<Props>();

const { onClickDashboardList } = setup(props);
</script>

<template>
  <div class="detail-name__list">
    <div
      v-for="(list, idx) in props.dashboardList"
      :key="idx"
      class="detail-name__list--btn"
      :title="list.dashboardName"
      @click="onClickDashboardList(list)"
    >
      <li>{{ list.dashboardName }}</li>
    </div>
  </div>
</template>

<style lang="scss">
.detail-name__list {
  display: flex;
  position: absolute;
  z-index: 1;
  flex-direction: column;
  gap: 8px;
  width: 200px;
  max-height: 500px;
  padding: 4px 0;
  overflow-y: auto;
  border-radius: 2px;
  background: var(--dropdown-bg-color);
  box-shadow: 0 10px 26px 0 rgba(0, 16, 34, 0.15);
  &--btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    padding: 4px 8px;
    li {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      background-color: var(--dropdown-dashboardlist-hover-bg-color);
    }
  }
}
</style>

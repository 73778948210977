import { i18n } from '@/common/locale';
import { AxiosInstance } from 'axios';
import { apiController } from '@/worker/commands/config/interceptors.utils';

const exceptionHandler = {
  401: async () => {
    console.warn(i18n.global.t('NOTI.API.NOT_PERMISSION_LOGIN_PAGE'));
    postMessage({ action: 'logout' });
  },
  403: async () => {
    console.warn(i18n.global.t('NOTI.API.NOT_PERMISSION'));
    postMessage({ action: 'updateToken' });
  },
  400: () => {
    console.warn(i18n.global.t('NOTI.API.INVALID_REQUEST'));
  },
  405: () => {
    console.warn(i18n.global.t('NOTI.API.NOT_ALLOW_METHOD'));
  },
  406: () => {
    postMessage({
      action: 'showMessage',
      type: 'error',
      message: i18n.global.t('MESSAGE.NO_PERMISSION'),
    });
  },
  500: () => {
    console.warn(i18n.global.t('NOTI.API.SERVER_ERROR'));
  },
  502: () => {
    // TODO 추후 메세지 수정
    console.warn(i18n.global.t('NOTI.API.SERVER_ERROR'));
    postMessage({ action: 'systemError' });
  },
  503: () => {
    console.warn(i18n.global.t('NOTI.API.NOT_AVAILABLE_SERVICE'));
  },
  504: () => {
    // TODO 추후 메세지 수정
    console.warn(i18n.global.t('NOTI.API.SERVER_ERROR'));
    postMessage({ action: 'systemError' });
  },
};

export const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (req) => {
      if (req?.params?.frameName) {
        req.headers.frameName = encodeURIComponent(req.params.frameName);
        delete req.params.frameName;
      }
      if (req?.data?.frameName) {
        req.headers.frameName = encodeURIComponent(req.data.frameName);
        delete req.data.frameName;
      }

      if (req && (req?.params?.isPolling || req?.data?.isPolling)) {
        req.headers.isPolling = true;

        if (req.params?.isPolling) {
          delete req.params.isPolling;
        } else if (req.data?.isPolling) {
          delete req?.data?.isPolling;
        }

        const { frameName } = req.headers;
        const isValid = apiController.isAvailableRequest(req, frameName);
        if (isValid) {
          apiController.request(req, frameName);
          return req;
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ignoreRequest: true });
      }

      return req;
    },
    (e) => Promise.reject(e),
  );
  instance.interceptors.response.use(
    (res) => {
      const { frameName, isPolling } = res.config?.headers ?? {};
      if (frameName) {
        postMessage({
          action: 'setApiTraceInfo',
          frameName: decodeURIComponent(frameName),
          apiTraceInfo: JSON.stringify(res),
          isError: false,
        });
      }

      if (res?.config && isPolling) {
        apiController.response(res.config, frameName);
      }

      return res;
    },
    (e) => {
      const { frameName, isPolling } = e.config?.headers ?? {};
      if (frameName) {
        postMessage({
          action: 'setApiTraceInfo',
          frameName: decodeURIComponent(frameName),
          apiTraceInfo: JSON.stringify(e.response),
          isError: true,
        });
      }

      if (e?.config && isPolling) {
        apiController.response(e.config, frameName);
      }

      if (e?.response?.status) {
        exceptionHandler?.[e.response.status]?.();
      } else if (e?.request) {
        console.log(i18n.global.t('NOTI.API.NOT_RECEIVE')); // 취소 시 로그 => 빌드시엔 안보이게
      } else if (!e?.ignoreRequest) {
        console.warn(i18n.global.t('NOTI.API.REQUEST_ERROR'));
      }
      return Promise.reject(e);
    },
  );

  return instance;
};

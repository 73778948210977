<template>
  <component
    :is="props.tag"
    class="flex-col"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed, CSSProperties } from 'vue';

const props = withDefaults(
  defineProps<{
    tag?: 'div' | 'section' | 'article' | 'aside' | 'main' | 'header' | 'footer' | 'nav' | 'ul';
    spacing?: number;
    align?: CSSProperties['alignItems'];
    justify?: CSSProperties['justifyContent'];
  }>(),
  {
    tag: 'div',
    spacing: 0,
    align: 'normal',
    justify: 'normal',
  },
);

const gap = computed(() => `${props.spacing}px`);
</script>

<style scoped>
.flex-col {
  display: flex;
  flex-direction: column;
  gap: v-bind('gap');
  align-items: v-bind('props.align');
  justify-content: v-bind('props.justify');
}
</style>

import { LocationQueryRaw, useRouter } from 'vue-router';
import { DB_TYPE, DBType } from '@/common/utils';
import { Store } from 'vuex';
import { RootState, useStore } from '@/common/store';
import { getRtmSelectedInfo } from '@/common/utils/commonUtils';
import { camelCase, upperFirst } from 'lodash-es';

type RTMType = 'multiView' | 'singleView';
type UseRTMDbType = Exclude<DBType, null | 'NONE' | 'java'>;

export const getCurrentViewInfo = (path: string, query: LocationQueryRaw) => {
  const pathArr = path.split('/');
  const [, dbType, view] = pathArr;

  if (!dbType || !view) {
    return null;
  }

  return {
    dbType: dbType as UseRTMDbType,
    viewType: view as RTMType,
    id: query?.id as string | undefined,
  };
};

export const hasDbType = {
  multiView: (dbType: UseRTMDbType) => {
    return DB_TYPE.POSTGRESQL === dbType || DB_TYPE.MYSQL === dbType || DB_TYPE.ORACLE === dbType;
  },
  singleView: (dbType: UseRTMDbType) => {
    return (
      DB_TYPE.POSTGRESQL === dbType || DB_TYPE.MYSQL === dbType || DB_TYPE.SQLSERVER === dbType
    );
  },
};

const isSingleView = (viewType?: RTMType) => viewType?.toLowerCase() === 'singleview';
const isMultiView = (viewType?: RTMType) => viewType?.toLowerCase() === 'multiview';

export const useLastSelectedInfo = () => {
  const store: Store<RootState> = useStore();
  const router = useRouter();

  const backToDatabaseList = async () => {
    await router.push('/database/instance');
  };

  const saveLastSelectedInfoFromQuery = async ({
    dbType,
    viewType,
    groupId,
    instanceId,
  }: {
    dbType: UseRTMDbType;
    viewType: RTMType;
    groupId?: string;
    instanceId?: string;
  }) => {
    const storeName = `${dbType}${upperFirst(camelCase(viewType))}Env`;
    const is = {
      singleView: isSingleView(viewType),
      multiView: isMultiView(viewType),
    };

    if (!groupId || (is.singleView && !instanceId)) {
      await store.dispatch(`${storeName}/fetchLastSelectedId`);

      const id = is.singleView
        ? store.getters[`${storeName}/getSelectedInstanceId`]
        : store.getters[`${storeName}/getSelectedFolderId`];
      await router.replace({ query: { id } });
      return;
    }

    const folderList = store.getters['monitoringFolder/getFolders'];
    const {
      folderId: verifiedGroupId,
      instanceId: verifiedInstanceId,
      selectedInfo,
    } = getRtmSelectedInfo({
      idInfo: {
        folderId: groupId,
        instanceId,
      },
      folderList,
      dbType,
      hasInstanceId: is.singleView,
    });

    if (is.multiView) {
      if (groupId !== verifiedGroupId) {
        await store.dispatch(`${storeName}/saveLastSelectedId`, {
          folderId: verifiedGroupId,
        });
      }
      store.commit(`${storeName}/setSelectedFolderId`, verifiedGroupId);
      store.commit(`${storeName}/setSelectedFolderInfo`, selectedInfo);
    } else if (is.singleView) {
      if (instanceId !== verifiedInstanceId) {
        await store.dispatch(`${storeName}/saveLastSelectedId`, {
          folderId: verifiedGroupId,
          instanceId: verifiedInstanceId,
        });
      }
      store.commit(`${storeName}/setSelectedInstanceId`, verifiedInstanceId);
      store.commit(`${storeName}/setSelectedInstanceInfo`, selectedInfo);
    }
  };

  return {
    backToDatabaseList,
    saveLastSelectedInfoFromQuery,
  };
};

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { PLATFORM } from '@/common/define/common.define';
import { useRtmApi } from '@/common/utils/apiUtils';
import {
  getFilterApplicationFilterControllerAxios,
  getTransactionFilterApplicationFilterControllerAxios,
} from '@/openapi/application/api/application-filter-controller-api';
import { getRumPageFilterApplicationRumPageControllerAxios } from '@/openapi/rum/api/application-rum-page-controller-api';
import { getInstancesDatabaseFilterControllerAxios } from '@/openapi/database/api/database-filter-controller-api';
import { getFilterAlertFilterControllerAxios } from '@/openapi/alert/api/alert-filter-controller-api';
import { getFilterNotificationHistoryControllerAxios } from '@/openapi/alert/api/notification-history-controller-api';
import { getInstanceGroupListDatabaseInstanceV7ControllerAxios } from '@/openapi/database/api/database-instance-v7-controller-api';
import { getHostFilterV7ListInfraFilterV7ControllerAxios } from '@/openapi/infra/api/infra-filter-v7-controller-api';
import { getServiceGroupListFilterXmServiceControllerAxios } from '@/openapi/service/api/xm-service-controller-api';
import { getFilterKubernetesFilterV7ControllerAxios } from '@/openapi/kubernetes/api/kubernetes-filter-v7-controller-api';
import { getSearchModelsNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { getTraceFilterApplicationTransactionControllerAxios } from '@/openapi/application/api/application-transaction-controller-api';
import { getLogFiltersLoggingViewV8ControllerAxios } from '@/openapi/log/api/logging-view-v8-controller-api';
import { getReportFilterReportExportV8ControllerAxios } from '@/openapi/report/api/report-export-v8-controller-api';
import { getFiltersApplicationRumBrowserErrorControllerAxios } from '@/openapi/rum/api/application-rum-browser-error-controller-api';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { getFiltersAwsFilterControllerAxios } from '@/openapi/cloud/api/aws-filter-controller-api';
import { getNpmFilterNpmOverviewControllerAxios } from '@/openapi/npm/api/npm-overview-controller-api';

export type PlatformType = (typeof PLATFORM)[keyof typeof PLATFORM];

export interface FilterApiInfo {
  platform: PlatformType | 'alert' | 'service' | 'logging' | 'networkDevice' | 'report' | '';
  menu?: string;
  frameName?: string;
}

export interface Filter {
  id: string;
  name: string;
}

export interface FilterData {
  key: Filter;
  values: FilterData[] | Filter[];
  type?: string;
  useSearch?: boolean;
}

type FilterControllerType = (request: any) => PromiseAxiosResponse<any>;

export interface FilterCloudData {
  accounts: { id: string; name: string; region: string }[];
  regions: string[];
  services: string[];
  engines: string[];
}

const NAME_MAP_MAPPER: Record<string, [string, string][]> = {
  // Filter List 카테고리명 매핑이 필요할 경우 이곳에 추가 후 하단 조건문에 조건식 추가
  'notification-history': [
    ['status', 'notification_status'],
    ['ruleType', 'notification_type'],
    ['group', 'alert_group'],
  ],
  'rum-browser-error-tracking': [['rumGroupId', 'rum_group']],
  'page-performance-monitoring': [['RumGroup', 'rum_group']],
  aws: [
    ['accounts', 'aws_account'],
    ['engines', 'engine_type'],
  ],
};

export const isFilterData = (v: FilterData | Filter): v is FilterData =>
  typeof v === 'object' && 'values' in v && 'key' in v;
export const isFilter = (v: FilterData | Filter): v is Filter =>
  typeof v === 'object' && 'id' in v && 'name' in v;

/**
 * @param checkedFilterListData checkedFilterListData
 * @returns Record<string, string[]>
 * @description checkedFilterListData에서 top level key.id를 key로, nested한 id를 values로 하는 Record를 반환합니다.
 */
export const extractFilterIds = (checkedFilterListData: FilterData[]): Record<string, string[]> => {
  const extract = (data: FilterData[] | Filter[]) => {
    const flatIds: string[] = [];

    data.forEach((d: FilterData | Filter) => {
      if (isFilter(d)) {
        flatIds.push(d.id);
      } else if (isFilterData(d)) {
        flatIds.push(...extract(d.values));
      }
    });

    return flatIds;
  };

  const result = {};
  const keys = checkedFilterListData.map((f) => f.key.id);

  keys.forEach((k) => {
    result[k] = extract(checkedFilterListData.find((f) => f.key.id === k)?.values ?? []);
  });
  return result;
};

const transformAWSData = (data: FilterCloudData, keys: (keyof FilterCloudData)[]): FilterData[] => {
  const NameMap = new Map(NAME_MAP_MAPPER.aws ?? []);

  return keys.map((key) => {
    const values: Filter[] =
      key === 'accounts'
        ? data.accounts.map(({ id, name, region }) => ({ id, name: `${name} (${region})` }))
        : data[key].map((item) => ({ id: item, name: item }));

    return {
      key: { id: key, name: NameMap.get(key) ?? '' },
      values,
    };
  });
};

export const useFilterListStore = defineStore('filterList', () => {
  const { callApi } = useRtmApi();
  const filterListData = ref<FilterData[]>([]);
  const checkedFilterListData = ref<FilterData[]>([]);
  const isFilterLoading = ref(false);

  const fetchFilterList = async (parameter: FilterApiInfo): Promise<void> => {
    filterListData.value = [];

    const { platform, menu, frameName } = parameter;

    let controller: FilterControllerType | null = null;
    switch (platform) {
      case PLATFORM.INFRA:
        if (menu === 'npm') {
          controller = getNpmFilterNpmOverviewControllerAxios;
        } else {
          controller = getHostFilterV7ListInfraFilterV7ControllerAxios;
        }
        break;
      case PLATFORM.KUBE:
        controller = getFilterKubernetesFilterV7ControllerAxios;
        break;
      case PLATFORM.APP: {
        if (menu === 'application') {
          controller = getFilterApplicationFilterControllerAxios;
        } else if (menu === 'trace') {
          controller = getTraceFilterApplicationTransactionControllerAxios;
        } else if (menu === 'transaction') {
          controller = getTransactionFilterApplicationFilterControllerAxios;
        } else if (menu === 'rum-browser-error-tracking') {
          controller = getFiltersApplicationRumBrowserErrorControllerAxios;
        } else if (menu === 'page-performance-monitoring') {
          controller = getRumPageFilterApplicationRumPageControllerAxios;
        }
        break;
      }
      case PLATFORM.DB:
        if (menu === 'database') {
          controller = getInstancesDatabaseFilterControllerAxios;
        } else if (menu === 'instance') {
          controller = getInstanceGroupListDatabaseInstanceV7ControllerAxios;
        }
        break;
      case PLATFORM.CLOUD:
        controller = getFiltersAwsFilterControllerAxios;
        break;
      case 'alert':
        if (menu === 'alert-list') {
          controller = getFilterAlertFilterControllerAxios;
        } else if (menu === 'notification-history') {
          controller = getFilterNotificationHistoryControllerAxios;
        }
        break;
      case 'service':
        controller = getServiceGroupListFilterXmServiceControllerAxios;
        break;
      case 'networkDevice':
        controller = getSearchModelsNdmMonitoringControllerAxios;
        break;
      case 'logging':
        controller = getLogFiltersLoggingViewV8ControllerAxios;
        break;
      case 'report':
        controller = getReportFilterReportExportV8ControllerAxios;
        break;
      default:
        break;
    }
    if (!menu || !controller) {
      return;
    }

    isFilterLoading.value = true;
    let params;
    if (platform !== PLATFORM.CLOUD) {
      params = { menu };
    } else {
      params = {
        awsServices:
          menu === 'ELASTICACHE'
            ? ['ELASTICACHE_CLUSTER', 'ELASTICACHE_SERVERLESS']
            : [menu?.toUpperCase()],
      };
    }

    try {
      const { data: rawData = [] } = await callApi({
        fn: controller,
        params,
        isTimeout: false,
        frameName,
      });
      const NameMap = new Map(NAME_MAP_MAPPER[menu] ?? []);
      const data: FilterData[] = (rawData as FilterData[]).map<FilterData>((filter) => ({
        ...filter,
        key: {
          id: filter.key?.id ?? '',
          name: NameMap.get(filter.key?.name ?? '') ?? filter.key?.name ?? '',
        },
      }));

      if (menu === 'host') {
        filterListData.value = data.length > 1 ? [{ ...data[1], useSearch: true }, data[0]] : [];
      } else if (platform === PLATFORM.CLOUD) {
        filterListData.value = transformAWSData(rawData[0], ['accounts']);
      } else {
        filterListData.value = data;
      }
    } catch (e) {
      console.log(e);
    } finally {
      isFilterLoading.value = false;
    }
  };

  const fetchCheckedFilterList = (checkedItems: FilterData[]) => {
    checkedFilterListData.value = checkedItems;
  };

  return {
    filterListData,
    checkedFilterListData,
    isFilterLoading,
    fetchFilterList,
    fetchCheckedFilterList,
  };
});

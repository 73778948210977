/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios/index';
import {
  DashboardCopyResponse,
  DashboardShareUserGroupResponse,
  DashboardShareUserV7Response,
  IntegrateDashboardCopyRequest,
  IntegrateDashboardMappingTargetsResponse,
  IntegrateDashboardSharePatchV7Request,
  IntegrateDashboardV7Response,
  SuccessResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
  /* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getBoardsIntegratedDashboardV7ControllerAxios = ({
  column,
  dashboardId,
  includeImage,
  pageNum,
  pageSize,
  sortType,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  column?: string;
  dashboardId?: number;
  includeImage?: boolean;
  pageNum?: number;
  pageSize?: number;
  sortType?: 'ASC' | 'DESC';
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardV7Response> => {
  const localVarPath = '/api/v7/dashboards/dashboard';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      dashboardId,
      includeImage,
      pageNum,
      pageSize,
      sortType,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupByUsersIntegratedDashboardV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DashboardShareUserGroupResponse> => {
  const localVarPath = '/api/v7/dashboards/groups';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getUserInShareDashboardIntegratedDashboardV7ControllerAxios = ({
  dashboardIds,
  groupId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardIds: Array<number>;
  groupId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DashboardShareUserV7Response> => {
  const localVarPath = '/api/v7/dashboards/share-dashboard/users';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dashboardIds,
      groupId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDashboardSharePermissionTypeIntegratedDashboardV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<Array<string>> => {
  const localVarPath = '/api/v7/dashboards/share-permission-type';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAllDashboardTargetsIntegratedDashboardV7ControllerAxios = ({
  dashboardId,
  widgetMappingId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  widgetMappingId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardMappingTargetsResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/targets'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      widgetMappingId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchBoardShareIntegratedDashboardV7ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: IntegrateDashboardSharePatchV7Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/dashboard/user-share';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const copyDashboardIntegratedDashboardV7ControllerAxios = ({
  dashboardId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  request: IntegrateDashboardCopyRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DashboardCopyResponse> => {
  const localVarPath = '/api/v7/dashboards/copy/{dashboardId}'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const exportDashboardAttributeIntegratedDashboardV7ControllerAxios = ({
  dashboardId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/dashboard/export';
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      dashboardId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const importDashboardAttributeIntegratedDashboardV7ControllerAxios = ({
  jsonList,
  isCustomReport,
  isPreset,
  isReport,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  jsonList: Array<string>;
  isCustomReport?: boolean;
  isPreset?: boolean;
  isReport?: boolean;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/dashboard/import';
  const httpMethod = 'post';

  const data: any = jsonList;
  const config: any = {
    params: {
      isCustomReport,
      isPreset,
      isReport,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getBoardsIntegratedDashboardV7ControllerAxios,
  getGroupByUsersIntegratedDashboardV7ControllerAxios,
  getUserInShareDashboardIntegratedDashboardV7ControllerAxios,
  getDashboardSharePermissionTypeIntegratedDashboardV7ControllerAxios,
  getAllDashboardTargetsIntegratedDashboardV7ControllerAxios,
  patchBoardShareIntegratedDashboardV7ControllerAxios,
  copyDashboardIntegratedDashboardV7ControllerAxios,
  exportDashboardAttributeIntegratedDashboardV7ControllerAxios,
  importDashboardAttributeIntegratedDashboardV7ControllerAxios,
};

import { computed, ref, watch, watchEffect } from 'vue';
import { getSlideDetailStore, PanelInfo } from '@/common/stores/slide-detail';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { useRtmApi } from '@/common/utils/apiUtils';
import { storeToRefs } from 'pinia';
import { useInternational } from '@/common/locale';
import { useTargetTagStore } from '@/common/stores/target-tags';
import { getDeviceAlertMessageNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import {
  AlertTreeRow,
  useAlertRuleDetail,
  useAlertTreeGrid,
} from '@/alert/components/alertGrid/alertGrid.uses';
import { getCurrentByServiceAlertCommonControllerAxios } from '@/openapi/alert/api/alert-common-controller-api';
import { getFilterItemsByGridColumns } from '@/common/components/molecules/filterSearch/filterSearch.utils';
import { useEvGridFilterSearch } from '@/common/components/molecules/filterSearch/filterSearch.uses';
import { VIEW_TOGGLE } from '@/alert/components/alertDetail/platformAlert';

export interface Props {
  isShow: boolean;
  data?: {
    addition?: {
      deviceId: string;
      networkDeviceName: string;
    };
  };
}

// value를 통해 Syslog, Trap 외에 그냥 일반 Alert 값이 들어올수도 있음.
export const isEventType = (value: string | number): boolean => {
  const cellValue = `${value}`.toLowerCase();
  switch (cellValue) {
    case 'syslog':
    case 'trap':
      return true;
    default:
      return false;
  }
};

const setup = (props: Props) => {
  const { t } = useInternational();

  const slideDetailStore = getSlideDetailStore();
  const { setIsDetailLoading } = slideDetailStore;
  const { selectedSlideTabPanel: curTabInfo } = storeToRefs(slideDetailStore);
  const { callApi } = useRtmApi();

  const rows = ref<AlertTreeRow[]>([]);

  const isOpenMessageWindow = ref<boolean>(false);

  const timePeriodInfo = ref<TimePeriodInfo>({
    ...getInitPeriodInfo(),
    isPaused: !props.isShow,
  });

  const messageText = ref('');

  const networkInfo = ref<{
    facility?: string;
    severity?: string;
    specificTrapCode?: string;
    genericTrapType?: string;
    enterpriseOid?: string;
  }>({});

  const { fetchAllData: fetchAllTagsData } = useTargetTagStore();
  const { alertColumns, makeAlertTreeRows } = useAlertTreeGrid({ columnOption: 'searchable' });

  const viewToggle = ref<keyof typeof VIEW_TOGGLE>(VIEW_TOGGLE.LIST_VIEW_PANEL);

  const filterItems = getFilterItemsByGridColumns(alertColumns);
  const { filterSearchResultMV, filterTreeGridByFilterSearch } = useEvGridFilterSearch({
    columns: computed(() => alertColumns),
    filterItems,
  });

  const filteredAlertRows = ref<AlertTreeRow[]>([]);
  watchEffect(() => {
    filteredAlertRows.value = filterTreeGridByFilterSearch(rows.value, filterSearchResultMV.value);
  });

  const deviceId = computed<string>(
    () =>
      props?.data?.addition?.deviceId ??
      (curTabInfo.value as PanelInfo<'Network Device'>)?.addition?.deviceId ??
      '',
  );

  // ALERT GRID LIST API
  const onGetData = async ({ isInitialLoading = true }: { isInitialLoading?: boolean }) => {
    if (!props.isShow || !deviceId.value) {
      return;
    }

    setIsDetailLoading(isInitialLoading);

    const { data = [] } = await callApi({
      fn: getCurrentByServiceAlertCommonControllerAxios,
      params: {
        targetIds: [deviceId.value],
        targetKind: 'network',
      },
      frameName: FRAME_NAMES.NETWORK_DEVICE_DETAIL.ALERT,
    });

    rows.value = makeAlertTreeRows({ data });
    setIsDetailLoading(false);
  };

  const onRefresh = (info: TimePeriodInfo) => {
    timePeriodInfo.value = info;
    if (props.isShow && !info.isPaused) {
      onGetData({ isInitialLoading: false });
    }
  };

  const onShowMessageBox = async (item) => {
    const eventType = item.alertValue;

    if (!(eventType === 'Syslog') && !(eventType === 'Trap')) {
      return;
    }

    isOpenMessageWindow.value = true;
    timePeriodInfo.value.isPaused = true;

    const { data } = await callApi({
      fn: getDeviceAlertMessageNdmMonitoringControllerAxios,
      params: {
        deviceId: deviceId?.value,
        alertRuleId: item.alertRuleId,
        eventType,
      },
    });

    if (eventType === 'Syslog') {
      const { facility, severity, message } = data?.[0].sysLogMessage || {};
      networkInfo.value = { facility, severity };
      messageText.value = message || '';

      return;
    }

    if (eventType === 'Trap') {
      const { genericTrapType, specificTrapCode, enterpriseOid, message } =
        data?.[0].snmpTrapMessage || {};
      networkInfo.value = { genericTrapType, specificTrapCode, enterpriseOid };
      messageText.value = message || '';
      return;
    }

    messageText.value = '';
  };

  const { showAlertRuleDetail } = useAlertRuleDetail();
  const onClickCell = async ({ field, row }: { field: string; row: AlertTreeRow }) => {
    if (field === 'ruleName') {
      await fetchAllTagsData();
      showAlertRuleDetail({ info: row });
    }
    if (field === 'alertValue') {
      onShowMessageBox(row);
    }
  };

  watch(
    () => props.isShow,
    (isShow) => {
      if (isShow) {
        onGetData({ isInitialLoading: true });
      }
    },
  );

  watch(
    () => deviceId.value,
    (newDevice, oldDevice) => {
      if (newDevice !== oldDevice) {
        onGetData({ isInitialLoading: true });
      }
    },
    { deep: true },
  );

  return {
    alertColumns,
    filteredAlertRows,
    timePeriodInfo,
    viewToggle,
    filterSearchResultMV,
    filterItems,

    isOpenMessageWindow,
    networkInfo,
    messageText,

    onRefresh,
    onClickCell,
    t,
  };
};

export { setup };

import { getCurrentInstance, ref } from 'vue';
import { isEventType } from '@/infrastructure/views/networkDeviceView/networkDeviceDetail/networkDeviceAlert/networkDeviceAlert.setup';
import { getDeviceAlertMessageNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { getSlideDetailStore, SlideTabPanelInfo } from '@/common/stores/slide-detail';
import { TOTAL_DETAIL } from '@/common/stores/slide-detail.logic';
import {
  columnFormatter,
  getAlertType,
  getDisplayNameFromSelectboxItem,
  useAlertFormatter,
} from '@/alert/utils/utils';
import {
  tagsAndTargetsController,
  TARGET_TYPE,
  useAlertTagsAndTargets,
} from '@/alert/utils/tagsAndTargets.uses';
import { getRuleNameIcon } from '@/alert/components/alertGrid/alertGrid.utils';
import { ExtractedCustomColumnTreeData } from '@/common/utils';
import {
  SEARCHABLE_ALERT_COLUMNS,
  VIEW_ONLY_ALERT_COLUMNS,
} from '@/alert/components/alertGrid/alertGrid.define';
import {
  CHECK_BY_COMBINATION,
  CHECK_BY_TARGET,
  getDuringTimeItems,
  getEvaluateValueItems,
} from '@/alert/utils/define';
import { lowerCase } from 'lodash-es';
import { AlertSubType } from '@/alert/components/alertDetail/alertDetail.types';

interface EventAlertInfo {
  messageText: string;
  networkInfo: Record<string, string>;
}

export const useEventTypeValue = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  const isShowMessageWindow = ref(false);
  const eventAlertInfo = ref<EventAlertInfo>({
    messageText: '',
    networkInfo: {},
  });

  const setEventInfo = (eventType, data) => {
    if (eventType.toLowerCase() === 'syslog') {
      const { facility, severity, message } = data.sysLogMessage;
      eventAlertInfo.value = {
        messageText: message,
        networkInfo: { facility, severity },
      };
    } else if (eventType.toLowerCase() === 'trap') {
      const { genericTrapType, specificTrapCode, enterpriseOid, message } = data.snmpTrapMessage;
      eventAlertInfo.value = {
        messageText: message,
        networkInfo: { genericTrapType, specificTrapCode, enterpriseOid },
      };
    }
  };

  const onClickValueCell = async ({ value, row, ruleId }) => {
    if (!isEventType(value)) {
      return;
    }
    const eventType = row.value || value;
    const deviceId = row.targets[0]?.id;
    const alertRuleId = row.ruleId || ruleId;

    if (!deviceId) {
      showErrorMsg(ctx, t('MESSAGE.NO_DATA'));
      return;
    }

    isShowMessageWindow.value = true;

    try {
      const { data } = await getDeviceAlertMessageNdmMonitoringControllerAxios({
        alertRuleId,
        deviceId,
        eventType,
      });
      setEventInfo(eventType, data.data?.[0]);
    } catch (e: any) {
      if (e?.response?.status && e.response.status !== 200) {
        showErrorMsg(ctx, t('MESSAGE.FAILED'));
      }
    }
  };

  return {
    isShowMessageWindow,
    eventAlertInfo,
    onClickValueCell,
  };
};

export const useAlertRuleDetail = () => {
  const isAlertRuleDetailShow = ref(false);

  const { addTabPanel } = getSlideDetailStore();
  const showAlertRuleDetail = ({
    info,
    init = false,
    state = {},
  }: {
    info: {
      ruleName: string;
      ruleType: string;
      alertRuleId?: string | null;
      subType?: AlertSubType;
    };
    init?: boolean;
    state?: SlideTabPanelInfo['state'];
  }) => {
    const { ruleName, ruleType, alertRuleId, subType } = info;
    addTabPanel(
      {
        type: TOTAL_DETAIL.ALERT,
        name: ruleName,
        addition: {
          ruleId: alertRuleId,
          type: getAlertType(ruleType),
          subType,
        },
        init,
      },
      state,
    );
    isAlertRuleDetailShow.value = true;
  };

  return {
    isAlertRuleDetailShow,
    showAlertRuleDetail,
  };
};

export type AlertTreeRow = ExtractedCustomColumnTreeData<typeof VIEW_ONLY_ALERT_COLUMNS>;
export type AlertRowFormatter<Row = any> = Record<string, (val: any, row: Row) => any>;
export type AlertThresholdInfo = {
  lastAlert: 'normal' | 'warning' | 'critical';
  evaluateValue: string;
  duringTimeInterval: string;
  comparison: string;
  warning: number;
  critical: number;
};

const getThreshold = ({
  lastAlert,
  evaluateValue,
  duringTimeInterval,
  critical,
  criticalComparison,
  warning,
  warningComparison,
}: AlertThresholdInfo) => ({
  lastAlert,
  evaluateValue: getDisplayNameFromSelectboxItem(getEvaluateValueItems(), evaluateValue),
  duringTimeInterval: getDisplayNameFromSelectboxItem(getDuringTimeItems(), duringTimeInterval),
  comparison: warningComparison || criticalComparison,
  warning,
  critical,
});

export const useAlertTreeGrid = ({ columnOption }: { columnOption: 'viewOnly' | 'searchable' }) => {
  const alertColumns =
    columnOption === 'viewOnly' ? VIEW_ONLY_ALERT_COLUMNS : SEARCHABLE_ALERT_COLUMNS;

  const { makeAlertTreeRows: makeRows } = useAlertTagsAndTargets();
  const alertFormatter = useAlertFormatter();

  const makeAlertTreeRows = <Row extends { alertValue?: any; ruleCriteria?: string }>({
    data,
    formatter = {},
  }: {
    data: Row[];
    formatter?: AlertRowFormatter;
  }) =>
    makeRows({
      data,
      columns: alertColumns,
      targetType: TARGET_TYPE.NONE,
      formatter: {
        ruleNameIcon: (_, { ruleCriteria }) => ({
          iconClass: getRuleNameIcon({ ruleCriteria: ruleCriteria || CHECK_BY_TARGET }),
          tooltip: ruleCriteria || CHECK_BY_TARGET,
        }),
        alertName: (_, { alertName, ruleType, parent }) => {
          const type = ruleType || parent?.ruleType;
          if (type === 'Metric') {
            return alertFormatter.alertName(alertName);
          }
          return alertName;
        },
        targets: (_, { ruleCriteria, target }) => {
          // by combination 부모
          if (ruleCriteria === CHECK_BY_COMBINATION) {
            return tagsAndTargetsController[TARGET_TYPE.TARGETS]([]).targets;
          }

          return tagsAndTargetsController[TARGET_TYPE.TARGETS](target).targets;
        },
        threshold: (_, row) => {
          const { ruleCriteria, ruleType, lastAlert, threshold } = row;

          // event, system 또는 by combination 부모
          if (
            ['system', 'event'].includes(lowerCase(ruleType)) ||
            ruleCriteria === CHECK_BY_COMBINATION
          ) {
            return null;
          }

          // by target
          if (ruleCriteria === CHECK_BY_TARGET) {
            return getThreshold({ ...(threshold?.[0] || {}), lastAlert: lowerCase(lastAlert) });
          }

          // by combination 자식
          return getThreshold({
            ...row,
            lastAlert: lowerCase(lastAlert),
          });
        },
        value: (_, { alertValue }) => columnFormatter.value(alertValue),
        ...formatter,
      },
    });

  return {
    alertColumns,
    makeAlertTreeRows,
  };
};

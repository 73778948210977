<script setup lang="ts">
import { getVersion } from '@/common/utils/commonUtils';
import { USER_ROLE, USER_GENERAL_SETTING_ITEMS } from '@/common/utils/define';
import ToggleButtonGroup from '@/common/components/molecules/toggle/ToggleButtonGroup.vue';
import ThemeToggle from '@/common/components/molecules/toggle/ThemeToggle.vue';
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';
import { RoleIdMap } from '@/common/auth/auth.defines';
import { type Emit, setup } from './myInfoPop.setup';

const emit = defineEmits<Emit>();

const {
  t,
  productName,
  userInfo,
  languageAndThemeInfo,
  groupItems,
  selectedGroupId,
  onChangeGroup,
  onLogout,
  onClickSetting,
} = setup(emit);
</script>

<template>
  <div class="my-info-popup">
    <div class="my-info-popup__content">
      <div class="content-top">
        <div
          class="content-top__profile"
          :class="userInfo.profileImage"
        />
        <div class="content-top__user-info">
          <span class="content-top__user-info--name">{{ userInfo.name }}</span>
          <span class="content-top__user-info--email">{{ userInfo.email }}</span>
        </div>
        <badge-item
          class="content-top__role"
          size="xsmall"
          html-type="span"
        >
          {{ USER_ROLE[userInfo.role ?? 2]?.name ?? '' }}
        </badge-item>
      </div>
      <ev-button
        type="text"
        class="setting-route-button"
        @click="onClickSetting"
      >
        <span>{{ t('WORD.GNB.SETTING') }}</span>
        <ev-icon icon="icon-settings" />
      </ev-button>
      <hr class="content__border" />
      <ul class="content-bottom">
        <li class="content__item">
          <p>{{ t('WORD.LANGUAGE') }}</p>
          <toggle-button-group
            v-model="languageAndThemeInfo.lang"
            type="round"
            :options="USER_GENERAL_SETTING_ITEMS.lang.map(({ name }) => ({ label: name }))"
          />
        </li>
        <li class="content__item">
          <p>{{ t('WORD.THEME') }}</p>
          <theme-toggle v-model:is-dark-mode="languageAndThemeInfo.isDarkTheme" />
        </li>
        <li class="content__item">
          <p>{{ t('WORD.MONITORING_GROUP') }}</p>
          <ev-select
            v-model="selectedGroupId"
            :items="groupItems"
            :disabled="userInfo.role === RoleIdMap.Master"
            @change="onChangeGroup"
          />
        </li>
      </ul>
      <hr class="content__border" />
      <ev-button
        type="text"
        class="logout-button"
        @click.stop="onLogout"
      >
        <span>{{ t('WORD.GNB.PROFILE_LOGOUT') }}</span>
        <ev-icon icon="icon-logout" />
      </ev-button>
      <hr class="content__border" />
      <p class="content__version">{{ productName }} {{ getVersion() }}</p>
    </div>
  </div>
</template>

<style lang="scss">
.my-info-popup {
  --shadow-color: rgba(0, 0, 0, 0.14);

  display: none !important;
  position: absolute;
  bottom: 0;
  z-index: $on-base-sub-menu;
  width: 280px;
  background-color: var(--lnb-bg-color);
  box-shadow:
    0 2px 4px 0 var(--shadow-color),
    0 1px 10px 0 var(--shadow-color),
    0 4px 5px 0 var(--shadow-color);
  transition: left $animate-faster;

  &.shown {
    display: block !important;
    left: var(--gnb-menu-width) !important;
  }

  &.shown.expanded {
    left: var(--gnb-menu-width-expanded) !important;
  }

  &:not(.expanded):hover {
    display: block !important;
    left: var(--gnb-menu-width);
  }

  &__content {
    width: 100%;

    > div:not(:first-child),
    > ul,
    > button {
      margin: 4px 0;
    }
  }

  .content-top {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    background-color: var(--profile-user-info-bg-color);

    &__profile {
      width: 60px;
      height: 60px;
      border-radius: 40px;
      background-color: var(--profile-image-bg-color);
    }
    &__user-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;
      &--name {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        color: var(--profile-user-text-color);
      }
      &--email {
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        color: var(--profile-user-text-color);
      }
    }
    &__role {
      position: absolute;
      top: 12px;
      right: 12px;
      border: 1px solid var(--badge-type-border-border-color);
      background-color: var(--profile-user-badge-bg-color);
      font-size: 10px;
    }
  }

  .content-bottom {
    width: 100%;
  }

  .content {
    &__border {
      border: 0.5px solid var(--lnb-menu-border-color);
      border-top: 0;
      color: var(--lnb-menu-border-color);
    }

    &__version {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 28px;
      font-size: 11px;
      line-height: 14px;
      color: var(--lnb-version-font-color);
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 36px;
      padding: 0 16px;
      font-size: 13px;
      color: var(--text-button-font-color);
    }
  }

  .ev-select {
    width: 108px !important;
  }
  /* stylelint-disable */
  .ev-radio-group {
    gap: 0;
    width: 80px;
    height: 20px;
    background-color: var(--lnb-radio-bg-color);
    transition: background-color $animate-base;
    &:hover {
      border: 1px solid transparent !important;
      background-color: var(--lnb-radio-bg-color) !important;
    }

    &.type-button[role='group'] .ev-radio span {
      padding: 0 !important;
      text-align: center;
      vertical-align: middle;
      line-height: 16px;
      background-color: inherit !important;
    }
  }
  .ev-radio {
    width: 38px;
    max-width: 38px;
    height: 16px;

    .ev-radio-label {
      width: inherit !important;
      height: inherit !important;
      color: var(--lnb-radio-font-color) !important;
    }

    &:hover:not(.checked) {
      background-color: var(--lnb-radio-hover-label-color) !important;
    }

    &.checked {
      background-color: var(--lnb-radio-selected-bg-color) !important;

      .ev-radio-label {
        color: var(--lnb-radio-selected-font-color) !important;
      }
    }
  }
  /* stylelint-enable */
}
.ev-button.type-text.logout-button,
.ev-button.type-text.setting-route-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  height: 36px !important;
  padding: 0 16px !important;
  background-color: transparent !important;
  font-size: 13px !important;
  font-weight: 400 !important;

  > i {
    background-color: var(--gnb-collapse-icon-color);
    mask-size: 16px;
    mask-repeat: no-repeat;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--move-group-button-list-hover-bg) !important;
    color: var(--move-group-button-list-hover-color) !important;
  }
}

@include max-screen() {
  .my-info-popup {
    .content__title {
      height: 26px;
    }
    .content__description {
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>

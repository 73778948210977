import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { Message } from '@/ai/utils/types';
import axios from 'axios';
import { store } from '@/common/store';

export interface ChatWindowPosition {
  bottom: number;
  right: number;
}

export const useChatStore = defineStore('chat', () => {
  const isChatWindowOpen = ref(false);
  const activeWidgetId = ref<string | null>(null);
  const allMessages = ref<Message[]>([]);
  const chatWindowPosition = ref<ChatWindowPosition>({ bottom: 80, right: 20 });
  const isLoading = ref(false);
  const lastTime = ref<string>('');

  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  const openChat = (position?: ChatWindowPosition, widgetId?: string | null) => {
    if (position) chatWindowPosition.value = position;
    if (widgetId) activeWidgetId.value = widgetId;
    isChatWindowOpen.value = true;
  };

  const closeChat = () => {
    isChatWindowOpen.value = false;
    activeWidgetId.value = null;
  };

  const toggleChat = () => {
    isChatWindowOpen.value = !isChatWindowOpen.value;
    if (!isChatWindowOpen.value) {
      activeWidgetId.value = null;
    }
  };

  const addMessage = (messages: Message | Message[]) => {
    if (Array.isArray(messages)) {
      allMessages.value.push(...messages);
    } else {
      allMessages.value.push(messages);
    }
  };

  const setChatWindowPosition = (position: ChatWindowPosition) => {
    chatWindowPosition.value = position;
  };

  const isAiButtonDisabled = () => {
    return activeWidgetId.value !== null;
  };

  const resetActiveWidgetId = () => {
    activeWidgetId.value = null;
  };

  const isPostAnalysisRequested = ref(false);
  const setPostAnalysisRequested = (status: boolean) => {
    isPostAnalysisRequested.value = status;
  };
  const resetPostAnalysisRequested = () => {
    isPostAnalysisRequested.value = false;
  };

  const getChatMessage = async () => {
    try {
      const params = {
        userId: String(userInfo.value?.userId ?? -1),
        roomId: 0,
        ...(lastTime.value && allMessages.value.length > 0 && { lastTime: lastTime.value }),
      };

      const response = await axios.get('/ai-api/v1/message/', {
        params,
      });

      if (response.data.data) {
        const newMessages = response.data.data;

        if (!lastTime.value) {
          addMessage(newMessages);
        } else {
          const botMessages = newMessages.filter((message) => message.sender === 'chatbot');
          addMessage(botMessages);
        }
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      isLoading.value = false;
    }
  };

  const postChatMessage = async (message: string) => {
    try {
      addMessage({
        userId: String(userInfo.value?.userId ?? -1),
        message,
        messageType: 'text',
      });

      isLoading.value = true;

      const response = await axios.post('/ai-api/v1/message/', {
        userId: String(userInfo.value?.userId ?? -1),
        message,
        messageType: 'text',
      });
      lastTime.value = response.data.responseTime;
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      getChatMessage();
    }
  };

  const postAnalysisData = async (title: string, imageUrl: string, responseData: string) => {
    try {
      addMessage({
        userId: String(userInfo.value?.userId ?? -1),
        messageType: 'text',
        message: `${title} 분석 해주세요.`,
      });

      isLoading.value = true;

      const response = await axios.post(
        '/ai-api/v1/analysis/',
        {
          userId: String(userInfo.value?.userId ?? -1),
          messageType: 'text',
          message: `${title} 분석 해주세요.`,
          imageUrl,
          widgetName: title,
          data: encodeURIComponent(JSON.stringify(responseData)),
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      lastTime.value = response.data.responseTime;
    } catch (error) {
      console.error('분석 데이터 요청 중 오류 발생:', error);
    } finally {
      setPostAnalysisRequested(true);
      resetActiveWidgetId();
      getChatMessage();
    }
  };

  return {
    isChatWindowOpen,
    allMessages,
    chatWindowPosition,
    isLoading,
    openChat,
    closeChat,
    toggleChat,
    addMessage,
    setChatWindowPosition,
    isAiButtonDisabled,
    resetActiveWidgetId,
    setPostAnalysisRequested,
    resetPostAnalysisRequested,
    isPostAnalysisRequested,
    postChatMessage,
    postAnalysisData,
    getChatMessage,
    lastTime,
  };
});

import { checkPermissionTargetListPermissionControllerAxios } from '@/openapi/metaV6/api/permission-controller-api';
import { store } from '../store';
import { useRtmApi } from '../utils/apiUtils';

export const useTargetPermission = () => {
  const { userId } = store.getters['myInfo/getAccountInfo'];
  const userRole = store.getters['myInfo/getMyRole'];
  const { callApi } = useRtmApi();

  const hasPermissionToTarget = async ({ targets }: { targets: string[] }) => {
    if (!targets.length) return {};

    if (userRole === 'master') {
      return targets.reduce((acc: Record<string, boolean>, targetId) => {
        acc[targetId] = true;
        return acc;
      }, {});
    }

    const { data } = await callApi({
      fn: checkPermissionTargetListPermissionControllerAxios,
      params: {
        targets,
        userId,
      },
    });
    return (
      data?.reduce((acc: Record<string, boolean>, { targetId, permission }) => {
        if (!targetId) return acc;

        acc[targetId] = permission === true;
        return acc;
      }, {}) ?? {}
    );
  };

  return {
    hasPermissionToTarget,
  };
};

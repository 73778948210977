/* eslint-disable */
import { useInternational } from '@/common/locale';
import { ToggleInfo } from '@/service/utils/types';
import { ref, computed } from 'vue';
import { TOTAL_DETAIL } from '@/common/stores/slide-detail.logic';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import { useInstanceDetail } from '@/database/utils/instanceDetail.uses';

export interface Props {
  targetData?: any;
}

export const setup = (props: Props) => {
  const { t } = useInternational();
  const slideDetailStore = getSlideDetailStore();
  const { addTabPanel } = slideDetailStore;
  const { openInstanceDetail } = useInstanceDetail();

  const toggleValue = ref<ToggleInfo>('list');
  const onChange = (newVal: ToggleInfo) => {
    toggleValue.value = newVal;
  };

  const columns = [
    { caption: 'Platform', field: 'platform', type: 'string', width: 115 },
    { caption: 'Target Name', field: 'targetName', type: 'string', width: 140 },
    {
      caption: 'Alert',
      field: 'alert',
      type: 'string',
      rendererType: 'capitalize-label',
      width: 105
    },
  ];

  const convertedRows = computed(() => {
    return (
      props.targetData?.map((item) => [
        item.targetKind,
        item.targetName,
        item.targetAlertLevel,
        item.targetId,
      ]) || []
    );
  });

  const getIconType = (targetKind: string) => {
    const allowedKinds = ['infrastructure', 'application', 'database', 'host', 'container', 'rum', 'kubernetes', 'aws', 'kafka'];
    return allowedKinds.includes(targetKind) ? `icon-ai-${targetKind}` : `icon-ai-etc`;
  };

  const onDetail = (targetData) => {

    let targetKind, targetName, targetAlertLevel, targetId;

    if (Array.isArray(targetData)) {
      [targetKind, targetName, targetAlertLevel, targetId] = targetData;
    } else if (typeof targetData === 'object' && targetData !== null) {
      ({ targetId, targetName, targetKind } = targetData);
    }

    switch (targetKind) {
      case 'host':
        addTabPanel({
          type: TOTAL_DETAIL.HOST,
          name: targetName,
          host: targetId,
          addition: {
            server: 'host',
          },
          state: {
            keepOpen: true,
          }
        });
        break;
      case 'infrastructure':
        addTabPanel({
          type: TOTAL_DETAIL.HOST,
          name: targetName,
          host: targetId,
          addition: {
            server: 'host',
          },
          state: {
            keepOpen: true,
          }
        });
        break;
      case 'kubernetes':
        addTabPanel({
          type: TOTAL_DETAIL.NAMESPACE,
          name: targetName,
          addition: {
            uid: targetId ?? '',
          },
        });
        break;
      case 'application':
        addTabPanel({
          type: TOTAL_DETAIL.WAS,
          name: targetName,
          was: {
            wasId: targetId,
            type: 'None',
          },
        });
        break;
      case 'database':
        openInstanceDetail({
          instanceName: targetName,
          instanceId: targetId,
          init: false,
        });
        break;
      default:
        break;
    }
  };

  return { toggleValue, onChange, t, convertedRows, columns, onDetail,getIconType };
};

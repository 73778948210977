import { camelCase, startCase } from 'lodash-es';
import { showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { getLicenseTargetXmLicenseControllerAxios } from '@/openapi/license/api/xm-license-controller-api';
import { LicenseTargetDisplayName } from './license.data';
import { LicenseType } from './license.type';

export const getDisplayLicenseTargetName = (licenseTarget: string) => {
  return LicenseTargetDisplayName[licenseTarget] ?? startCase(camelCase(licenseTarget));
};

export const showLicenseNotification = (type: 'error' | 'success', ctx, message: string) => {
  if (type === 'error') {
    showErrorMsg(ctx, message);
  } else {
    showSuccessMsg(ctx, message);
  }
};

export const getLicenseTypeForRequest = (
  type: LicenseType,
): Parameters<typeof getLicenseTargetXmLicenseControllerAxios>[0]['licenseType'] => {
  switch (type) {
    case 'application':
      return 'APPLICATION';
    case 'cloud':
      return 'CLOUD';
    case 'container':
      return 'CONTAINER';
    case 'database':
      return 'DATABASE';
    case 'infrastructure':
      return 'INFRASTRUCTURE';
    default:
      throw new Error('Invalid license type');
  }
};

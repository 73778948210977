export interface Message {
  messageType: string;
  message: string;
  sender: string;
  resultType: string;
  result: string;
  imageUrl: string;
}

export const IconTypes = ['host-info', 'metrics', 'setup', 'monitoring'];

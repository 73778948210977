/* eslint-disable no-redeclare */
import { WidgetPackPatchRequestPackType } from '@/openapi/metaV6/model';
import { TargetTagType, WidgetCustomId, WidgetUUID } from '@/common/utils/types';
import { BY_STAT_CHART_TYPES, BY_TARGET_CHART_TYPES } from '@/dashboard/utils/define';
import { MetricV7RequestInterpolateType } from '@/openapi/data/model';
import { CSSProperties } from 'vue';
import { IconType } from '@/types/icon.types';
import { LayoutItem } from 'vue3-grid-layout-next/dist/helpers/utils';
import { FilterTableData } from '../components/widgetSettingsWindow/filterWidgetSettings/filterDataList.setup';
import { ChartOptions, FilterOptions } from '../components/widgets/widgets.types';

export type WidgetType =
  | 'GRAPHS'
  | 'ARCHITECTURE'
  | 'LIST'
  | 'MEMO'
  | 'TEXT'
  | 'IMAGE'
  | 'GROUP'
  | 'SERVICE'
  | 'ETC'
  | 'FILTER';

const FilterWidgetChartType = ['HORIZONTAL_FILTER', 'VERTICAL_FILTER'] as const;
export type FilterWidgetChartType = (typeof FilterWidgetChartType)[number];

const GraphsWidgetChartType = [
  'TIME_SERIES',
  'SCATTER',
  'ONE_DAY_COMPARE',
  'EQUALIZER',
  'SCOREBOARD',
  'STACKED_PROPORTION_BAR',
  'ACTION_VIEW',
  'PIE',
  'GAUGE',
] as const;
export type GraphsWidgetChartType = (typeof GraphsWidgetChartType)[number];

const ListWidgetChartType = ['TABLE', 'TOP_LIST', 'TREE_GRID'] as const;
export type ListWidgetChartType = (typeof ListWidgetChartType)[number];

const ArchitectureWidgetChartType = [
  // 'CLUSTER_OVERVIEW',
  'TOPOLOGY',
  'EXCLUSIVE',
  'STATUS',
  'STATUS_SUMMARY',
  'SERVER',
] as const;
export type ArchitectureWidgetChartType = (typeof ArchitectureWidgetChartType)[number];

const InfoWidgetChartType = ['TEXT', 'IMAGE', 'MEMO'] as const;
export type InfoWidgetChartType = (typeof InfoWidgetChartType)[number];

const GroupWidgetChartType = ['GROUP', 'TAB_GROUP'] as const;
export type GroupWidgetChartType = (typeof GroupWidgetChartType)[number];

const BusinessWidgetChartType = ['SERVICE_CARD'] as const;
export type BusinessWidgetChartType = (typeof BusinessWidgetChartType)[number];

export const WidgetChartType = [
  ...GraphsWidgetChartType,
  ...ListWidgetChartType,
  ...ArchitectureWidgetChartType,
  ...InfoWidgetChartType,
  ...GroupWidgetChartType,
  ...BusinessWidgetChartType,
  ...FilterWidgetChartType,
  'LEGEND',
] as const;
export type WidgetChartType = (typeof WidgetChartType)[number];

export type DisplayStyleType =
  | 'LINE'
  | 'BAR'
  | 'AREA'
  | 'STACKED_AREA'
  | 'STACKED_BAR'
  | 'STYLE_1'
  | 'STYLE_2'
  | 'STYLE_3'
  | 'TABLE'
  | 'TOP_3_CARD'
  | 'SUMMARY_BAR'
  | 'HEXA'
  | 'CARD'
  | 'PIE'
  | 'TREE_GRID'
  | 'TOPOLOGY'
  | 'HORIZONTAL'
  | 'VERTICAL'
  | 'SERVER'
  | 'GAUGE_HALF_CIRCLE'
  | 'GAUGE_CIRCLE'
  | '';

export type WidgetOptionMenuType = 'remove' | 'widget-setting' | 'copy';

export type WidgetModeType = 'view' | 'edit' | 'preview';

export type TextAlignType = 'left' | 'right' | 'center';

export const WidgetTimePeriodList = [
  'globalTime',
  'calendar',
  'p5m',
  'p10m',
  'p30m',
  'p1h',
  'p6h',
  'p12h',
  'p1d',
] as const;

export type WidgetTimePeriod = (typeof WidgetTimePeriodList)[number];

export type WidgetIntervalType =
  | 'I100ms'
  | 'I5s'
  | 'I10s'
  | 'I15s'
  | 'I30s'
  | 'I1m'
  | 'I3m'
  | 'I5m'
  | 'I10m'
  | 'I15m'
  | 'I20m'
  | 'I30m'
  | 'I1d'
  | 'I1h'
  | 'I2h'
  | 'I5h'
  | 'I24h'
  | 'none';

export type WidgetSeriesType = 'avg' | 'byTarget' | 'max' | 'sum';

export type WidgetDataType =
  | 'current'
  | 'delta'
  | 'sum'
  | 'avg'
  | 'max'
  | 'deltaPerSec'
  | 'sumPerSec';

export interface SeriesTypeItem {
  name: string;
  value: WidgetSeriesType;
  disabled: boolean;
}

export interface WidgetTarget {
  tagKey: string;
  tagType: TargetTagType;
  tagValue: string;
  tagValueId?: string;
}

export interface WidgetChartData {
  id: string;
  targets: WidgetTarget[];
  category: string;
  dataId: string; // 지표
  seriesType?: WidgetSeriesType;
  dataType?: WidgetDataType;
  isDefault?: boolean;
  alias?: string;
  value?: any;
}

export interface WidgetTitleStyle extends CSSProperties {
  fontSize: string;
  color: string;
  textAlign: TextAlignType;
}

export type WidgetHeaderType = 'basic' | 'highLight';

export interface WidgetTitleOption {
  showTitle: boolean;
  titleText: string;
  titleStyle: WidgetTitleStyle;
  headerType?: WidgetHeaderType;
}

export interface WidgetColorOption {
  background: string;
  border: string;
}

export interface CalendarTimeRange {
  fromTime: string;
  toTime: string;
}

export type WidgetCreateType = 'base' | 'reference';

export interface WidgetExtraData {
  extraId: string;
  targets: {
    id: string;
    value: string;
  }[][];
}

export interface GridLayoutItem extends LayoutItem {
  compName: string;
  widgetType: WidgetType;
  chartType: WidgetChartType;
  displayStyle: DisplayStyleType;
  titleOption: WidgetTitleOption;
  widgetColor: WidgetColorOption;
  createType: WidgetCreateType;
  headerType?: WidgetHeaderType;
  timePeriod?: WidgetTimePeriod;
  chartData?: WidgetChartData[];
  colorTheme?: string;
  children?: GridLayoutItem[];
  minW?: number;
  minH?: number;
  chartOption?: any; // 위젯 타입마다 달라 json으로 받음
  extraData?: WidgetExtraData[];
  filterOption?: { filterList: FilterTableData[] };
  calendarTimeRange?: CalendarTimeRange[];
  isInViewport?: boolean;
  customId?: WidgetCustomId;
  originHeight?: number;
  widgetMappingId?: number;
}

export interface DashboardUserGroupData {
  dashboardGroupId?: number;
  dashboardGroupName: string;
}

interface DashboardOption {
  showTimePeriod: boolean;
}
export interface DashboardData {
  dashboardId: number;
  dashboardGroupId?: number;
  description?: string;
  dashboardName: string;
  preset: boolean;
  share: boolean;
  sharePermission: {
    edit: boolean | 'INDETERMINATE';
    shared: boolean | 'INDETERMINATE';
    mapping: boolean | 'INDETERMINATE';
    shareUsers?: number[];
  };
  shareAuth?: boolean;
  image?: string;
  startScreen: boolean;
  favoriteId?: number;
  createUser?: boolean;
  createUserName?: string;
  lastModified?: string;
  menuPaths?: string[];
  analysisMenuPaths?: string[];
  featuredReport?: boolean;
  customReport?: boolean;
  iconId?: string;
  dashboardOption: DashboardOption | string | null;
  creator: {
    name?: string;
    activeId?: string;
    profileImage?: string;
  };
}

export interface WidgetSettingsWindowData<T extends WidgetChartType = any> {
  widgetId: WidgetUUID;
  widgetType: WidgetType;
  chartType: T;
  displayStyle: DisplayStyleType;
  timePeriod: WidgetTimePeriod;
  titleOption: WidgetTitleOption;
  colorTheme: string;
  widgetColor: WidgetColorOption;
  createType: WidgetCreateType;
  headerType?: WidgetHeaderType;
  chartData?: WidgetChartData[];
  chartOption: ChartOptions<T>;
  extraData?: WidgetExtraData[];
  filterOption?: FilterOptions<T>;
  calendarTimeRange?: CalendarTimeRange[];
  customId?: WidgetCustomId;
}

export interface ViewPackInfo {
  name: string;
  description: string;
  packId: number;
  widgetMappingId: number;
  packType: WidgetPackPatchRequestPackType;
  width: number;
  height: number;
  titleOption: WidgetTitleOption;
  widgetColor: WidgetColorOption;
  widgets: GridLayoutItem;
  createUserName: string;
  tag: string;
  image?: string;
  category?: string[];
}

/*
 * Dashboard Editor > List
 */
export interface MenuItem {
  id: string;
  name: string;
  isVisible: boolean;
  badge?: string;
  isExpand?: boolean;
  depth?: number;
  children?: MenuItem[];
  description?: string;
  hasChild?: boolean;
  isLeaf?: boolean;
  data?: any;
}

export interface DashboardListItemOnEditor {
  id: number;
  name: string;
  description: string;
  preset: boolean;
  featuredReport: boolean;
  createUser?: boolean;
  share?: boolean;
  imgSrc?: URL;
}

export interface ViewPackListItem {
  id: number;
  isVisible: boolean;
  tag: string;
  name: string;
  description?: string;
  imgSrc?: URL;
  packType?: string;
  childWidgetCount?: number;
  childChartTypes?: string[];
  childWidgetTypes?: WidgetType[];
  createUserName?: string;
  category?: string[];
}

export interface WidgetListItem extends Omit<MenuItem, 'children'> {
  isExpand: boolean;
  hasChild: boolean;
  isLeaf: boolean;
  children?: WidgetListItem[];
  iconClass?: IconType;
}

export type LENGTH_RULE_KEY = 'NAME' | 'DESCRIPTION' | 'WIDGET_NAME';

export interface DashboardWidgetTargetData {
  tagKey: string;
  tagType: string;
  tagValueId: string;
}

export interface DashboardWidgetChartData {
  id?: string;
  alias?: string;
  category?: string;
  dataId: string;
  dataType?: string;
  isDefault?: boolean;
  seriesType?: string;
  targets: DashboardWidgetTargetData[];
  value?: string;
}

export interface DashboardWidgetItem {
  widgetId: WidgetUUID;
  createType: WidgetCreateType;
  chartData?: DashboardWidgetChartData[];
  chartOption?: string;
  extraData?: WidgetExtraData[];
  filterOption?: string;
  chartType?: string;
  colorTheme?: string;
  displayStyle?: string;
  height?: number;
  period?: string;
  titleOption?: string;
  widgetColor?: string;
  widgetType?: string;
  width?: number;
  x?: number;
  y?: number;
  value?: string; // calendarTimeRange 값이 json 형식으로 저장
  customId?: WidgetCustomId;
}

export type ChartThemeType =
  | 'EXEM'
  | 'COMPANY'
  | 'FINANCE'
  | 'MAXGAUGE'
  | 'PUBLIC'
  | 'BLUE'
  | 'GREEN'
  | 'BROWN'
  | 'MONO'
  | 'PURPLE';

export type ByTargetChartTypes = (typeof BY_TARGET_CHART_TYPES)[number];
export type ByStatChartTypes = (typeof BY_STAT_CHART_TYPES)[number];

export type TargetType =
  | 'node'
  | 'pod'
  | 'container'
  | 'application'
  | 'database'
  | 'host'
  | 'network_device'
  | 'aws_ec2'
  | 'aws_dynamodb'
  | 'aws_documentdb'
  | 'aws_redshift'
  | 'aws_rds'
  | 'aws_elasticache_cluster'
  | 'aws_elasticache_shard'
  | 'aws_elasticache_node'
  | 'kafka_broker'
  | 'kafka_cluster_consumer_group';

export interface SummaryDetailParams {
  field: string;
  dataType: string;
  fields?: SummaryDetailParams[];
}

export type Interpolation = MetricV7RequestInterpolateType;

export type DashboardCopyMode = false | 'stay' | 'move';

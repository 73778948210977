import { computed, onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
import { useDrag, useResize } from '@/ai/utils/utils';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '@/common/assets/ai/chat-loading.json';
import { AnimationItem } from 'lottie-web';
import { useChatStore } from '../store/chat';

export const setup = () => {
  const chatStore = useChatStore();
  const initialPosition = chatStore.chatWindowPosition;

  const { position, startDrag } = useDrag(initialPosition);
  const { size, startResize } = useResize();

  const container = ref<HTMLDivElement | null>(null);
  let loadingAnimation: AnimationItem | null = null;

  const userInput = ref<string>('');

  const isShowWindow = computed(() => chatStore.isChatWindowOpen);
  const allMessages = computed(() => chatStore.allMessages);
  const isLoading = computed(() => chatStore.isLoading);

  const toggleChat = () => {
    chatStore.toggleChat();
  };

  const handleMessageClick = async (message: string) => {
    await chatStore.postChatMessage(message);
    userInput.value = '';
  };

  const sendMessage = async () => {
    if (userInput.value.trim()) {
      await chatStore.postChatMessage(userInput.value);
      userInput.value = '';
    }
  };

  const scrollToBottom = async () => {
    await nextTick();
    if (container.value) {
      container.value.scrollTop = container.value.scrollHeight;
    }
  };
  const onEnter = async (e: KeyboardEvent) => {
    e.preventDefault();
    await sendMessage();
  };

  watch(
    () => allMessages.value.length,
    async () => {
      await scrollToBottom();
    },
  );

  watch(position, (newPos) => {
    chatStore.setChatWindowPosition(newPos);
  });

  watch(isShowWindow, async (newVal) => {
    if (newVal) {
      await chatStore.getChatMessage();
    } else {
      chatStore.allMessages = [];
      chatStore.lastTime = '';
    }
  });

  onMounted(async () => {
    await nextTick();
    if (container.value) {
      try {
        loadingAnimation = lottie.loadAnimation({
          container: container.value,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData,
        });
      } catch (error) {
        console.error('Error loading Lottie animation:', error);
      }
    }
  });

  onUnmounted(() => {
    if (loadingAnimation) {
      loadingAnimation.destroy();
    }
  });

  return {
    position,
    startDrag,
    size,
    startResize,
    userInput,
    allMessages,
    isShowWindow,
    toggleChat,
    sendMessage,
    container,
    isLoading,
    handleMessageClick,
    onEnter,
  };
};

import { computed, ref } from 'vue';
import router from '@/common/router';
import { useInstanceStore } from '@/common/stores/instance';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';
import { useDashboardFavorite } from '@/common/components/organisms/baseMenu/baseMenu.uses';
import { MenuInfo } from '../types';

export interface Props {
  menuInfo: MenuInfo;
}

export const setup = (props: Props) => {
  const { changeGNBInstance } = useInstanceStore();
  const isSelected = computed(() => router.currentRoute.value.fullPath === props.menuInfo.path);
  const computedChildrenLength = computed(() => props.menuInfo.subMenuList?.length ?? 0);

  const visibleFavoriteIcon = computed(() => {
    const isDashboard = props.menuInfo.value.includes(`${BASE_MENU_VALUE.DASHBOARD}_`);
    const isDashboardList = props.menuInfo.value.includes(`${BASE_MENU_VALUE.DASHBOARD}_list`);

    return isDashboard && !isDashboardList;
  });

  const favoriteRef = ref<HTMLButtonElement | null>(null);
  const { selectPanelMenu } = useBaseMenuStore();
  const { isFavorited, isAnalysisFavorited, updateFavorite } = useDashboardFavorite(props.menuInfo);

  const onClickElement = async (e: MouseEvent) => {
    const favoriteEl = favoriteRef.value;
    const targetEl = e.target as HTMLElement;

    if (!props.menuInfo.path || props.menuInfo.subMenuList) {
      return;
    }
    if (!favoriteEl) {
      const [, rootPath, secondPath] = props.menuInfo.path.split('/');
      if (secondPath === 'multiView' || secondPath === 'singleView') {
        changeGNBInstance(rootPath, secondPath);
        return;
      }
    }
    if (favoriteEl?.contains(targetEl)) {
      updateFavorite();
      return;
    }
    selectPanelMenu(props.menuInfo.value);
    router.push(props.menuInfo.path);
  };

  return {
    isSelected,
    computedChildrenLength,
    visibleFavoriteIcon,
    favoriteRef,
    isFavorited,
    isAnalysisFavorited,
    onClickElement,
  };
};

import {
  AwsDataRequestStatEnum,
  AwsDataRequestTotalTypeEnum,
  OrderByByEnum,
  OrderByOrderEnum,
} from '@/openapi/cloud/model';
import { AwsServiceType } from './types';

export const SUMMARY_STATUS = {
  SUCCESS: 'success',
  COLLECT_OFF: 'collectOff',
  NO_PERMISSION: 'noPermission',
} as const;

export const SUMMARY_STAT = {
  Average: 'AVERAGE',
  Max: 'MAX',
  Min: 'MIN',
  SampleCount: 'SAMPLE_COUNT',
  Sum: 'SUM',
} as const;

export const AWS_PERIOD_ITEMS = [
  { name: 'Past 30 Minutes', value: 'p30m', shortName: '30m' },
  { name: 'Past 1 Hours', value: 'p1h', shortName: '1h' },
  { name: 'Past 3 Hours', value: 'p3h', shortName: '3h' },
  { name: 'Past 6 Hours', value: 'p6h', shortName: '6h' },
  { name: 'Past 12 Hours', value: 'p12h', shortName: '12h' },
  { name: 'Past 1 Day', value: 'p1d', shortName: '1d' },
];

export const SUMMARY_INFO = {
  EC2: {
    cpuUtilization: {
      dataName: 'CPUUtilization',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Average,
      totalType: AwsDataRequestTotalTypeEnum.Average,
      limit: 5,
    },
    networkIn: {
      dataName: 'NetworkIn',
      order: OrderByOrderEnum.Asc,
      by: OrderByByEnum.Time,
      stat: AwsDataRequestStatEnum.Average,
      totalType: AwsDataRequestTotalTypeEnum.Average,
      interval: 'I1m',
    },
    networkOut: {
      dataName: 'NetworkOut',
      order: OrderByOrderEnum.Asc,
      by: OrderByByEnum.Time,
      stat: AwsDataRequestStatEnum.Average,
      totalType: AwsDataRequestTotalTypeEnum.Average,
      interval: 'I1m',
    },
  },
  LAMBDA: {
    invocations: {
      dataName: 'Invocations',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Sum,
      totalType: AwsDataRequestTotalTypeEnum.Sum,
      limit: 1,
    },
    errors: {
      dataName: 'Errors',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Sum,
      totalType: AwsDataRequestTotalTypeEnum.Sum,
      limit: 1,
    },
    duration: {
      dataName: 'Duration',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Average,
      totalType: AwsDataRequestTotalTypeEnum.Average,
      limit: 5,
    },
  },
  S3: {
    bucketSizeBytes: {
      dataName: 'BucketSizeBytes',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Sum,
      totalType: AwsDataRequestTotalTypeEnum.Sum,
      limit: 5,
    },
    AllRequests: {
      dataName: 'AllRequests',
      order: OrderByOrderEnum.Asc,
      by: OrderByByEnum.Time,
      stat: AwsDataRequestStatEnum.Sum,
      totalType: AwsDataRequestTotalTypeEnum.Sum,
      limit: 5,
    },
  },
  RDS: {
    cpuUtilization: {
      dataName: 'CPUUtilization',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Average,
      totalType: AwsDataRequestTotalTypeEnum.Average,
      limit: 5,
    },
    databaseConnections: {
      dataName: 'DatabaseConnections',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Sum,
      totalType: AwsDataRequestTotalTypeEnum.Sum,
      limit: 5,
    },
    readLatency: {
      dataName: 'ReadLatency',
      order: OrderByOrderEnum.Desc,
      by: OrderByByEnum.Value,
      stat: AwsDataRequestStatEnum.Average,
      totalType: AwsDataRequestTotalTypeEnum.Average,
      limit: 5,
    },
  },
};

export const SUMMARY_CHANGE_INFO = {
  bucketSize: {
    dataName: 'BucketSizeBytes',
    limit: 5,
    stat: AwsDataRequestStatEnum.Max,
    interval: 'I1m',
  },
};

export const GRID_INFO = {
  databaseConnection: {
    dataName: 'DatabaseConnections',
    order: OrderByOrderEnum.Desc,
    by: OrderByByEnum.Time,
    stat: AwsDataRequestStatEnum.Average,
    totalType: AwsDataRequestTotalTypeEnum.Average,
  },
  cpuUtilization: {
    dataName: 'CPUUtilization',
    order: OrderByOrderEnum.Desc,
    by: OrderByByEnum.Time,
    stat: AwsDataRequestStatEnum.Average,
    totalType: AwsDataRequestTotalTypeEnum.Average,
  },
};

export const awsServices = [
  'aws_aurora',
  'aws_documentdb',
  'aws_dynamodb',
  'aws_ec2',
  'aws_elasticache_cluster',
  'aws_elasticache_node',
  'aws_elasticache_serverless',
  'aws_elasticache_shard',
  'aws_lambda',
  'aws_lightsail_database',
  'aws_lightsail_instance',
  'aws_rds',
  'aws_redshift',
  'aws_s3',
  'aws_vpc',
] as const;

export const AWS_SERVICE: { [key in AwsServiceType]: key } = {
  AURORA: 'AURORA',
  DOCUMENTDB: 'DOCUMENTDB',
  DYNAMODB: 'DYNAMODB',
  EC2: 'EC2',
  ELASTICACHE_CLUSTER: 'ELASTICACHE_CLUSTER',
  ELASTICACHE_NODE: 'ELASTICACHE_NODE',
  ELASTICACHE_SERVERLESS: 'ELASTICACHE_SERVERLESS',
  ELASTICACHE_SHARD: 'ELASTICACHE_SHARD',
  LAMBDA: 'LAMBDA',
  LIGHTSAIL_DATABASE: 'LIGHTSAIL_DATABASE',
  LIGHTSAIL_INSTANCE: 'LIGHTSAIL_INSTANCE',
  RDS: 'RDS',
  REDSHIFT: 'REDSHIFT',
  S3: 'S3',
  VPC: 'VPC',
};

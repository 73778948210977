<script setup lang="ts">
import { defineProps } from 'vue';
import { IconTypes } from '@/ai/utils/types';
import TargetList from '@/ai/components/TargetList.vue';
import { type Props, setup, type Emit } from './botMessage.setup';

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const { parsedResult, handleClick, container, parsedMessage, parsedTarget, isTextFullyDisplayed } =
  setup(props, emit);
</script>

<template>
  <div class="bot-message__wrapper">
    <div
      v-if="message.messageType === 'welcomeText'"
      class="bot-message__wrapper--welcome"
    >
      <div
        ref="container"
        class="bot-message--animation"
      />
      <div
        class="bot-message--intro"
        v-html="parsedMessage"
      />
      <div class="bot-message--result">
        <div
          v-for="(item, index) in parsedResult"
          :key="index"
          class="bot-message--button fade-in"
          :style="{ animationDelay: `${index * 0.1}s` }"
          @click="handleClick(item.message)"
        >
          <ev-icon
            :icon="`icon-${IconTypes[index]}`"
            class="bot-message--button--icon"
          />
          <div class="bot-message--button--message">{{ item.message }}</div>
          <ev-icon
            icon="icon-line-arrow-right"
            class="ev-icon"
          />
        </div>
      </div>
    </div>

    <div
      v-else
      class="bot-message__wrapper--origin"
    >
      <i class="bot-message--icon" />
      <div>
        <div
          v-if="message.imageUrl"
          class="bot-message--message"
        >
          <img
            :src="message.imageUrl"
            alt="Captured Graph"
            class="user-message__image"
          />
        </div>
        <div
          v-if="message.message"
          class="bot-message--message"
          @mousedown.stop
          v-html="parsedMessage"
        />

        <div
          v-if="message.resultType === 'textButton'"
          class="bot-message--message"
        >
          <div
            v-if="isTextFullyDisplayed"
            class="bot-message--result"
          >
            <div
              v-for="(item, index) in parsedResult"
              :key="index"
              class="bot-message--button fade-in"
              :style="{ animationDelay: `${index * 0.1}s` }"
              @click="handleClick(item.message)"
            >
              <div class="bot-message--button--message">{{ item.message }}</div>
              <ev-icon
                icon="icon-line-arrow-right"
                class="ev-icon"
              />
            </div>
          </div>
        </div>
        <div
          v-if="message.resultType === 'targetList'"
          class="bot-message--message"
        >
          <target-list
            :target-data="parsedTarget"
            class="bot-message--result"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.bot-message__wrapper {
  &--welcome {
    .bot-message--animation {
      width: 100%;
      height: 180px;
    }

    .bot-message--intro {
      margin-top: 8px;
      margin-bottom: 20px;
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
    }
  }

  &--origin {
    display: flex;
    align-items: flex-start;

    .bot-message--icon {
      display: inline-block;
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      margin-right: 10px;
      background-image: url('@/common/assets/ai/chat-bot.svg');
      background-size: cover;
    }

    .bot-message--message {
      max-width: 400px;
      padding: 12px;
      margin-bottom: 8px;
      border-radius: 12px;
      background-color: white;
      font-size: 15px;
      line-height: 21px;
      color: #333333;
      word-wrap: break-word;
    }
  }

  .bot-message--result {
    gap: 10px;
    padding: 12px;
    margin: 0 auto;
    border-radius: 12px;
    background-color: white;
    font-size: 15px;
    color: #3b82f6;

    .bot-message--button {
      display: flex;
      align-items: center;
      padding: 12px;
      margin-top: 8px;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 12px;
      background-color: white;
      font-size: 15px;
      color: #3b82f6;

      &:hover {
        border: 1px solid #3b82f6;
      }

      &:active {
        border: 1px solid #3b82f6;
        background-color: #dbeafe;
      }

      &--icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        background-color: #3b82f6;
      }

      &--message {
        font-size: 15px;
        color: #3b82f6;
      }
    }
  }

  .bot-message--table {
    padding: 12px;
    border-collapse: collapse;
    border-radius: 12px;
    background-color: white;

    .table-header {
      padding: 8px;
      background-color: #f0f0f0;
      font-weight: bold;
      color: #333333;
    }

    .table-cell {
      padding: 8px;
      color: #333333;
      text-align: left;
    }

    .target-link {
      color: #3b82f6;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .ev-icon {
    margin-left: auto;
    background-color: #3b82f6;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    opacity: 0;
    animation: fade-in 0.3s ease forwards;
  }

  .user-message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 8px;

    &__image-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    &__image {
      max-width: 100%;
      border-radius: 10px;
      background-color: white;
    }
  }
  a {
    display: inline-block;
    border-radius: 8px;
    background: linear-gradient(90deg, #2680ff 0%, #6c4fff 100%);
    background-clip: text;
    font-size: 15px;
    font-weight: bold;
    color: transparent;
    text-decoration: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}
</style>

import { ref } from 'vue';
import router from '@/common/router';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { useDashboardFavorite } from '@/common/components/organisms/baseMenu/baseMenu.uses';
import { MenuInfo } from '../types';

export interface Props {
  dashboardInfo: MenuInfo;
  selected?: boolean;
}

export const setup = (props: Props) => {
  const favoriteRef = ref<HTMLButtonElement | null>(null);

  const { selectPanelMenu } = useBaseMenuStore();
  const { isFavorited, isAnalysisFavorited, updateFavorite } = useDashboardFavorite(
    props.dashboardInfo,
  );

  const onClickElement = async (e: MouseEvent) => {
    const favoriteEl = favoriteRef.value;
    const targetEl = e.target as HTMLElement;

    if (!favoriteEl) {
      return;
    }
    if (favoriteEl.contains(targetEl)) {
      updateFavorite();
      return;
    }
    if (!props.dashboardInfo.path) {
      return;
    }
    selectPanelMenu(props.dashboardInfo.value);
    router.push(props.dashboardInfo.path);
  };

  return { isFavorited, isAnalysisFavorited, favoriteRef, onClickElement };
};

<script setup lang="ts">
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import { setup, Props, Emit } from '@/common/components/molecules/timePeriod/timePeriodV2.setup';
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';
import TimePeriodV2Calendar from '@/common/components/molecules/timePeriod/TimePeriodV2Calendar.vue';

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});
const emit = defineEmits<Emit>();

const {
  t,

  isShowDropdown,
  toggleDropdown,
  closeAll,

  periodName,
  periodShortName,

  quickPeriods,
  onClickQuickPeriod,

  recentlySearches,
  onClickRecentlySearch,
  deleteRecentlySearch,

  isShowDatePicker,
  dateTimePeriod,
  openDatePicker,
  closeDatePicker,
  onSelectByCalendar,

  timeUnitItems,
  pastInputTime,
  pastInputUnit,
  pastInputError,
  onInputValidation,
  onClickInputTimeOk,
} = setup(props, emit);
</script>

<template>
  <div
    v-click-outside="closeAll"
    :class="['time-period-v2', { 'quick-section': !props.useCalendar }]"
  >
    <div>
      <tag-item
        v-if="props.periods?.[0].shortName !== 'a'"
        size="xsmall"
        :title="periodShortName"
      >
        {{ periodShortName }}
      </tag-item>
      <div :class="['ev-select time-period__select', { disabled: props.disabled }]">
        <div
          class="ev-select__wrapper"
          @click="toggleDropdown"
        >
          <input
            v-model="periodName"
            :disabled="props.disabled"
            type="text"
            class="ev-input"
            readonly
          />
          <span class="ev-input-suffix">
            <i
              class="ev-input-suffix-arrow ev-icon-s-arrow-down"
              :class="{
                selected: isShowDropdown,
              }"
            />
          </span>
        </div>
        <div
          v-show="isShowDropdown"
          class="time-period-v2__dropdown-wrapper"
        >
          <div
            v-if="props.useInput"
            class="time-period-v2__input-section"
          >
            <label class="time-period-v2__section-title">
              {{ t('WORD.PAST', 'Past') }}
            </label>
            <div class="time-period-v2__input-wrapper">
              <ev-text-field
                v-model="pastInputTime"
                type="text"
                :error-msg="pastInputError"
                @input="onInputValidation"
              />
              <ev-select
                v-model="pastInputUnit"
                :items="timeUnitItems"
              />
            </div>
            <div class="time-period-v2__input-actions">
              <ev-button
                type="primary"
                size="small"
                :disabled="!!pastInputError"
                @click="() => onClickInputTimeOk(pastInputTime, pastInputUnit)"
              >
                <span>{{ t('WORD.OK', 'OK') }}</span>
              </ev-button>
            </div>
          </div>
          <div
            v-if="recentlySearches?.length && props.useCalendar"
            class="time-period-v2__recently-section"
          >
            <label class="time-period-v2__section-title">
              {{ t('WORD.RECENT_SEARCHES', 'Recent Searches') }}
            </label>
            <ul>
              <li
                v-for="(searchItem, index) in recentlySearches"
                :key="searchItem.id"
                :title="searchItem.value.name"
                class="time-period-v2__list-item"
                @click="() => onClickRecentlySearch(searchItem.value)"
              >
                <span>{{ searchItem.value.name }}</span>
                <ev-button
                  type="icon"
                  size="xsmall"
                  @click.stop="() => deleteRecentlySearch(index)"
                >
                  <ev-icon icon="icon-close" />
                </ev-button>
              </li>
            </ul>
          </div>
          <div class="time-period-v2__quick-section">
            <label class="time-period-v2__section-title">
              {{ t('WORD.QUICK_RANGES', 'Quick Ranges') }}
            </label>
            <div class="time-period-v2__quick-section-contents">
              <button
                v-for="quickPeriod in quickPeriods"
                :key="quickPeriod.value"
                @click="() => onClickQuickPeriod(quickPeriod)"
              >
                {{ quickPeriod.name }}
              </button>
            </div>
            <ev-button
              v-if="props.useCalendar"
              shape="border"
              class="time-period-v2__date-picker-button"
              @click="openDatePicker"
            >
              <ev-icon icon="icon-calendar" />
              <span>{{ t('WORD.SELECT_FROM_CALENDAR', 'Select from calendar') }}</span>
            </ev-button>
          </div>
        </div>
      </div>
      <time-period-v2-calendar
        v-if="isShowDatePicker"
        :date-time-period="dateTimePeriod"
        :max-range-in-sec="props.timeRangeSecLimit"
        @click-cancel="closeDatePicker"
        @click-ok="onSelectByCalendar"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.time-period-v2 {
  --time-period-select-width: 280px;
  --time-period-dropdown-list-width: calc(var(--time-period-select-width) + 82px);

  position: relative;
  width: var(--time-period-select-width);
  height: 24px;

  > div {
    display: flex;
    position: absolute;
    align-items: center;
    width: 100%;
  }

  &__select {
    width: 100% !important;

    .ev-input {
      width: 100% !important;
      height: 24px !important;
      padding-right: 0 !important;
      font-size: 12px !important;
    }

    .ev-input-suffix {
      right: 4px;
    }
  }

  .tag-item {
    position: absolute;
    left: 4px;
    z-index: 1;
    justify-content: center;
    width: 35px;
    padding: 4px 6px;
    font-weight: 400;

    :deep(span) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    /* stylelint-disable */
    + .time-period__select {
      input {
        padding-left: 42px !important;
      }
    }
    /* stylelint-enable */
  }

  &__dropdown-wrapper {
    display: flex;
    position: absolute;
    z-index: $on-base-sub-menu;
    flex-direction: column;
    width: var(--time-period-dropdown-list-width);
    padding: 0 10px;
    margin-top: 4px;
    cursor: default;
    border-radius: 2px;
    background-color: var(--color-gray-07-00);
    box-shadow: var(--dropdown-shadow);
  }

  &__section-title {
    padding: 0 6px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 700;
    color: var(--color-gray-00-12);
  }

  &__input-section {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-gray-05-05);

    :deep(.ev-text-field),
    :deep(.ev-select) {
      width: auto !important;
    }
    :deep(.ev-text-field) {
      .ev-input {
        cursor: text;
      }
    }
    :deep(.ev-text-field-error) {
      position: absolute;
    }
    .time-period-v2__input-wrapper {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
  }

  &__recently-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-gray-05-05);

    .time-period-v2__list-item {
      display: flex;
      flex-direction: row;
      gap: 3px;
      align-items: center;
      height: 23px;
      padding: 0 6px;
      cursor: pointer;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
      line-height: 23px;
      color: var(--color-gray-02-10);

      span {
        flex: auto;
        @include shortening();
      }
      &:hover {
        background-color: var(--color-gray-05-02);
      }
    }
  }

  &__quick-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 0;

    &-contents {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1px;
      width: 100%;
      padding: 0 0 16px;

      button {
        height: 23px;
        padding: 3px 6px;
        cursor: pointer;
        border-radius: 2px;
        background-color: transparent;
        color: var(--color-gray-02-10);
        text-align: left !important;

        &:hover {
          background-color: var(--color-gray-05-02);
        }
      }
    }

    .time-period-v2__date-picker-button {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
  &.quick-section {
    .time-period-v2__dropdown-wrapper {
      width: var(--time-period-select-width);
    }
    .time-period-v2__quick-section-contents {
      padding: 0;
    }
  }
}
</style>

// src/ai/utils/utils.ts
import { ref, onMounted, onUnmounted } from 'vue';

export interface Position {
  bottom: number;
  right: number;
}

export interface Size {
  width: number;
  height: number;
}

/**
 * useDrag 커스텀 훅
 * 요소를 드래그하여 위치를 변경할 수 있게 합니다.
 * @param initialPosition 초기 위치를 설정할 수 있습니다.
 */
export const useDrag = (initialPosition?: Position) => {
  const position = ref<Position>({
    bottom: initialPosition?.bottom ?? 10,
    right: initialPosition?.right ?? 10,
  });
  const isDragging = ref(false);
  const startX = ref(0);
  const startY = ref(0);

  const startDrag = (event: MouseEvent) => {
    isDragging.value = true;
    startX.value = event.clientX - (window.innerWidth - position.value.right);
    startY.value = event.clientY - (window.innerHeight - position.value.bottom);
    event.preventDefault();
  };

  const onMouseMove = (event: MouseEvent) => {
    if (!isDragging.value) return;

    position.value.right = Math.max(0, window.innerWidth - event.clientX + startX.value);
    position.value.bottom = Math.max(0, window.innerHeight - event.clientY + startY.value);

    position.value.right = Math.min(position.value.right, window.innerWidth - 50);
    position.value.bottom = Math.min(position.value.bottom, window.innerHeight - 50);
  };

  const onMouseUp = () => {
    if (isDragging.value) {
      isDragging.value = false;
    }
  };

  onMounted(() => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  });

  onUnmounted(() => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  });

  return {
    position,
    startDrag,
  };
};

/**
 * useResize 커스텀 훅
 * 요소의 크기를 조정할 수 있게 합니다.
 */
export const useResize = () => {
  const size = ref<Size>({ width: 630, height: 820 });
  const isResizing = ref(false);
  const startX = ref(0);
  const startY = ref(0);
  const startWidth = ref(0);
  const startHeight = ref(0);

  const MIN_WIDTH = 200; // 최소 너비
  const MIN_HEIGHT = 200; // 최소 높이

  const startResize = (event: MouseEvent) => {
    isResizing.value = true;
    startX.value = event.clientX;
    startY.value = event.clientY;
    startWidth.value = size.value.width;
    startHeight.value = size.value.height;
    event.preventDefault();
  };

  const onMouseMove = (event: MouseEvent) => {
    if (!isResizing.value) return;

    // 오른쪽 하단 모서리를 기준으로 크기 조정
    size.value.width = Math.max(MIN_WIDTH, startWidth.value + (event.clientX - startX.value));
    size.value.height = Math.max(MIN_HEIGHT, startHeight.value + (event.clientY - startY.value));
  };

  const onMouseUp = () => {
    if (isResizing.value) {
      isResizing.value = false;
    }
  };

  onMounted(() => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  });

  onUnmounted(() => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  });

  return {
    size,
    startResize,
  };
};

<script setup lang="ts">
import { Props, Emit, setup } from './setupDashBoardPopup.setup';

const emit = defineEmits<Emit>();

const props = withDefaults(defineProps<Props>(), {
  envKey: '',
  isAlert: false,
  isShow: false,
});

const {
  urlInfoList,
  setupDashBoardData,
  onClickSave,
  isValid,
  searchFilterList,
  searchValue,
  onClosePopup,
  nameErrorMsg,
  validateName,
  validateUrl,
  validateUrlName,
  checkedList,
  isLoading,
  t,
  onClickAddBtn,
  onClickDeleteBtn,
  isMaster,
} = setup(props, emit);
</script>

<template>
  <div
    v-if="props.isShow"
    :class="['setup-modal', props.isAlert ? 'setup-modal__alert' : '']"
  >
    <div class="setup-modal__header">
      <span> {{ t('MESSAGE.GO_TO_DASHBOARD') }} </span>
      <ev-button
        type="icon"
        @click="onClosePopup"
      >
        <ev-icon icon="icon-close" />
      </ev-button>
    </div>
    <div class="setup-modal__btn-name">
      <span> {{ t('WORD.CHANGE_BUTTON_NAME') }} </span>
      <ev-text-field
        v-model.trim="setupDashBoardData.name"
        :max-length="10"
        :error-msg="nameErrorMsg.name"
        @input="validateName"
      />
    </div>
    <div class="setup-modal__content">
      <span class="body-title">{{ t('WORD.SELECT_DASHBOARD') }} </span>
      <div class="dashboard-list">
        <ev-text-field
          v-model="searchValue"
          :placeholder="t('WORD.SEARCH')"
          type="search"
          class="search-input base-expansion__search"
        />
        <ev-loading :model-value="isLoading" />
        <div class="dashboard-list__check-area">
          <div
            v-for="(list, idx) in searchFilterList"
            :key="idx"
            :class="['base-expansion__list']"
          >
            <ev-checkbox
              v-model="checkedList"
              v-model:value="list.dashboardId"
              :label="list.dashboardId"
              :title="list.dashboardName"
            >
              {{ list.dashboardName }}
            </ev-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="setup-modal__urlArea">
      <div
        v-if="urlInfoList.length > 0"
        :class="[urlInfoList.length > 0 ? `setup-modal__urlField` : null]"
      >
        <template
          v-for="(info, index) in urlInfoList"
          :key="index"
        >
          <div class="setup-modal__field">
            <ev-checkbox
              v-model="info.checked"
              class="setup-modal__field__checkbox"
            />
            <div>
              <ev-text-field
                v-model="info.urlName"
                :disabled="info.isGlobal && !isMaster"
                :placeholder="t('WORD.NAME')"
                :error-msg="info.nameErrorMsg"
                @input="() => validateUrlName(info.urlName, index)"
              />
            </div>
            <div>
              <ev-text-field
                v-model="info.url"
                :disabled="info.isGlobal && !isMaster"
                :placeholder="t('WORD.URL')"
                :error-msg="info.urlErrorMsg"
                @input="() => validateUrl(info.url, index)"
              />
            </div>
            <ev-button
              :disabled="info.isGlobal && !isMaster"
              type="icon"
              @click="onClickDeleteBtn(index)"
            >
              <ev-icon icon="icon-outline-delete" />
            </ev-button>
          </div>
        </template>
      </div>
      <ev-button
        shape="border"
        class="add-btn"
        @click="onClickAddBtn"
      >
        <ev-icon icon="ev-icon-plus" />
        {{ t('WORD.ADD_URL') }}
      </ev-button>
    </div>
    <div class="setup-modal__btn">
      <ev-button
        type="primary"
        :disabled="!isValid"
        @click="onClickSave"
      >
        {{ t('WORD.OK') }}
      </ev-button>
    </div>
  </div>
</template>

<style lang="scss">
.setup-modal {
  display: flex;
  z-index: 1;
  flex-direction: column;
  gap: 8px;
  width: 480px;
  border: var(--popup-dashboard-border-color);
  border-radius: 2px;
  background: var(--popup-dashboard-bg-color);
  box-shadow: var(--popup-dashboard-shadow);
  color: var(--color-gray-00-12);
  @include set-scrollbar();

  &__alert {
    display: flex;
    position: absolute;
    top: 76px;
    right: 25px;
    left: auto;
    z-index: 1;
    flex-direction: column;
    gap: 8px;
    width: 480px;
    border: var(--popup-dashboard-border-color);
    border-radius: 2px;
    background: var(--popup-dashboard-bg-color);
    box-shadow: var(--popup-dashboard-shadow);
  }

  .ev-text-field {
    width: 100% !important;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    padding: 12px 12px 12px 8px;
    border: var(--popup-dashboard-header-border-color);
    background: var(--popup-dashboard-header-bg-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    color: var(--detail-information-label-color);
  }
  &__btn-name {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    justify-content: center;
    height: 60px;
    padding: 8px 10px;
    color: var(--detail-information-label-color);
  }
  &__content {
    display: flex;
    flex-direction: column;
    .body-title {
      margin: 8px;
      font-size: 12px;
      color: var(--detail-information-label-color);
    }
    .dashboard-list {
      margin: 0 12px;
      border: 1px solid var(--popup-dashboard-border-color);

      &__check-area {
        height: 190px;
        padding: 8px;
        overflow-y: auto;
        .ev-checkbox {
          width: 100%;
          padding: 8px;
        }
        .base-expansion__list {
          display: flex;
          align-items: center;
          height: 28px;
          cursor: pointer;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .ev-text-field {
        padding: 8px;
      }
    }
  }
  &__urlArea {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
    .add-btn {
      width: 99px;
    }
  }
  &__urlField {
    max-height: 200px;
    padding: 8px;
    overflow-y: auto;
    border: 1px solid var(--popup-dashboard-border-color);
    border-radius: 2px;
  }
  &__field {
    display: flex;
    flex-direction: row;
    gap: 12px;

    div {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 6px;
    }
    &__checkbox {
      margin: auto;
    }
  }

  &__btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 48px;
    margin: 0 8px;
  }
}
</style>

import { CSSProperties, nextTick, ref, watch } from 'vue';

// ## Comment: targetElement 기준
export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';

export interface Slots {
  default?: () => any;
}

export interface Props {
  mouseEvent: MouseEvent | null;
  basePos?: TooltipPosition;
  customClass?: string;
  showTail?: boolean;
  teleportTo?: string;
  text?: string;
  nonePadding?: boolean;
  responsivePos?: boolean;
}

interface WindowSize {
  width: number;
  height: number;
  scrollX: number;
  scrollY: number;
}

interface GetAlignPosParams {
  tooltipRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  targetRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  windowSize: WindowSize;
  showTail: boolean;
}
const getVerticalPos = ({
  tooltipRect,
  targetRect,
  windowSize,
  showTail,
}: GetAlignPosParams): string => {
  let res = targetRect.y;

  if (showTail) {
    res = targetRect.y + targetRect.height / 2 - tooltipRect.height / 2;
  } else if (
    targetRect.y + tooltipRect.height > windowSize.height &&
    targetRect.y - tooltipRect.height > 0
  ) {
    res = targetRect.y - tooltipRect.height;
  }

  return `${res + window.scrollY}px`;
};
const getHorizontalPos = ({
  tooltipRect,
  targetRect,
  windowSize,
  showTail,
}: GetAlignPosParams): string => {
  let res = targetRect.x;

  if (showTail) {
    res = targetRect.x + targetRect.width / 2 - tooltipRect.width / 2;
  } else if (
    targetRect.x + tooltipRect.width > windowSize.width &&
    targetRect.x - tooltipRect.width > 0
  ) {
    res = targetRect.x - tooltipRect.width;
  }

  return `${res + window.scrollX}px`;
};

const getPosAndTooltipStyle = ({
  tooltipElement,
  target,
  windowSize,
  baseArrowPos,
  showTail,
  responsivePos,
}: {
  tooltipElement: HTMLElement;
  target: HTMLElement;
  windowSize: WindowSize;
  baseArrowPos: TooltipPosition;
  showTail: boolean;
  responsivePos: boolean;
}): { position: TooltipPosition; style: CSSProperties } => {
  const GAP = 15;
  const ARROW_SIZE = 4;
  const styleInfoByPos: Record<string, CSSProperties> = {};
  const possiblePos: TooltipPosition[] = [];
  const tooltipRect = tooltipElement.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();
  const { scrollX, scrollY, width: windowWidth, height: windowHeight } = windowSize;
  let position: TooltipPosition;
  let style: CSSProperties;

  // 각 방향별 공간 여유 계산
  const spaces = {
    right: windowWidth - (targetRect.x + targetRect.width + GAP + ARROW_SIZE),
    left: targetRect.x - (GAP + ARROW_SIZE),
    top: targetRect.y - (GAP + ARROW_SIZE),
    bottom: windowHeight - (targetRect.y + targetRect.height + GAP + ARROW_SIZE),
  };

  const topPos = getVerticalPos({
    tooltipRect,
    targetRect,
    windowSize,
    showTail,
  });

  // right 위치 계산
  if (spaces.right >= tooltipRect.width) {
    possiblePos.push('right');
    styleInfoByPos.right = {
      left: `${targetRect.x + targetRect.width + GAP + scrollX}px`,
      top: topPos,
    };
  }

  // left 위치 계산
  if (spaces.left >= tooltipRect.width) {
    possiblePos.push('left');
    styleInfoByPos.left = {
      left: `${targetRect.x - tooltipRect.width - GAP + scrollX}px`,
      top: topPos,
    };
  }

  const leftPos = getHorizontalPos({
    tooltipRect,
    targetRect,
    windowSize,
    showTail,
  });

  // top 위치 계산
  if (spaces.top >= tooltipRect.height) {
    possiblePos.push('top');
    styleInfoByPos.top = {
      left: leftPos,
      top: `${targetRect.y - tooltipRect.height - GAP + scrollY}px`,
    };
  }

  // bottom 위치 계산
  if (spaces.bottom >= tooltipRect.height) {
    possiblePos.push('bottom');
    styleInfoByPos.bottom = {
      left: leftPos,
      top: `${targetRect.y + targetRect.height + GAP + scrollY}px`,
    };
  }

  if (responsivePos) {
    // 먼저 basePos가 가능한지 확인
    if (possiblePos.includes(baseArrowPos) && styleInfoByPos[baseArrowPos]) {
      position = baseArrowPos;
      style = styleInfoByPos[baseArrowPos];
    } else {
      // basePos가 불가능할 경우에만 가장 공간이 많은 방향 찾기
      const maxSpace = Math.max(...Object.values(spaces));
      const bestPosition = Object.entries(spaces).find(
        ([_, space]) => space === maxSpace,
      )?.[0] as TooltipPosition;

      if (possiblePos.includes(bestPosition)) {
        position = bestPosition;
        style = styleInfoByPos[bestPosition];
      } else {
        position = possiblePos[0] ?? 'right';
        style = styleInfoByPos[position] ?? {};
      }
    }
  } else if (styleInfoByPos[baseArrowPos]) {
    position = baseArrowPos;
    style = styleInfoByPos[baseArrowPos];
  } else {
    position = possiblePos[0] ?? 'right';
    style = styleInfoByPos[position] ?? {};
  }

  // 화면 경계 체크 및 조정
  if (responsivePos) {
    const adjustedStyle = { ...style };
    const leftValue = parseInt(style.left as string, 10);
    const topValue = parseInt(style.top as string, 10);

    // 좌우 경계 체크
    if (leftValue + tooltipRect.width > windowWidth + scrollX) {
      adjustedStyle.left = `${windowWidth - tooltipRect.width + scrollX - GAP}px`;
    }
    if (leftValue < scrollX) {
      adjustedStyle.left = `${scrollX + GAP}px`;
    }

    // 상하 경계 체크
    if (topValue + tooltipRect.height > windowHeight + scrollY) {
      adjustedStyle.top = `${windowHeight - tooltipRect.height + scrollY - GAP}px`;
    }
    if (topValue < scrollY) {
      adjustedStyle.top = `${scrollY + GAP}px`;
    }

    style = adjustedStyle;
  }

  return {
    position,
    style,
  };
};

export const setup = (props: Required<Props>) => {
  const tooltipPosition = ref<TooltipPosition>(props.basePos);
  const tooltipRef = ref<HTMLElement | null>(null);
  const tooltipStyle = ref<CSSProperties>({});

  watch(
    () => props.mouseEvent,
    async (evt) => {
      // ## Comment: Text 갱신 시간
      await nextTick();
      // ## Comment: MouseEvent 에는 target 관련 type 이 정의되어 있지 않음.
      const targetElement = evt?.target ? (evt.target as HTMLElement) : null;
      const tooltipElement = tooltipRef.value;
      const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      };
      tooltipStyle.value = {
        zIndex: -100,
        left: 0,
        top: 0,
        visibility: 'hidden',
      };

      await nextTick();

      if (targetElement && tooltipElement) {
        const { position, style } = getPosAndTooltipStyle({
          tooltipElement,
          windowSize,
          target: targetElement,
          baseArrowPos: props.basePos,
          showTail: props.showTail,
          responsivePos: props.responsivePos ?? false,
        });

        tooltipPosition.value = position;
        tooltipStyle.value = {
          ...style,
        };
      }
    },
  );

  return {
    tooltipPosition,
    tooltipRef,
    tooltipStyle,
  };
};

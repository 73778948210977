<script setup lang="ts">
import { Props, setup } from '@/ai/components/targetList.setup';
import BaseGrid from '@/common/components/molecules/grid/BaseGrid.vue';

const props = defineProps<Props>();
const { toggleValue, onChange, t, convertedRows, columns, onDetail, getIconType } = setup(props);
</script>

<template>
  <!-- transition 태그를 사용하여 fade 애니메이션 추가 -->
  <transition name="fade">
    <div
      v-if="true"
      class="target-list"
    >
      <div class="target-list__header">
        <div class="service-target__header-toggle">
          <ev-button-group>
            <ev-button
              :type="toggleValue === 'list' ? 'primary' : 'default'"
              :class="{ selected: toggleValue === 'list' }"
              @click="() => onChange('list')"
            >
              <ev-icon icon="icon-list" />
              <p class="button-text">{{ t('WORD.LIST') }}</p>
            </ev-button>
            <ev-button
              :type="toggleValue === 'icon' ? 'primary' : 'default'"
              :class="{ selected: toggleValue === 'icon' }"
              @click="() => onChange('icon')"
            >
              <ev-icon icon="icon-icon" />
              <span class="button-text">{{ t('WORD.ICON') }}</span>
            </ev-button>
          </ev-button-group>
        </div>
      </div>
      <div v-if="toggleValue === 'list'">
        <transition-group
          name="fade"
          tag="div"
          class="target-list-grid"
        >
          <base-grid
            class="target-list-grid fade-in-item"
            :columns="columns"
            :rows="convertedRows"
          >
            <template #platform="{ item }">
              <div
                :key="item"
                class="text-wrapper"
              >
                <ev-icon :icon="getIconType(item.row[2][0])" />
                {{ item.row[2][0] }}
              </div>
            </template>

            <template #targetName="{ item }">
              <div
                :key="item"
                class="text-wrapper text-link"
                @click="() => onDetail(item.row[2])"
              >
                {{ item.row[2][1] }}
              </div>
            </template>
          </base-grid>
        </transition-group>
      </div>
      <div class="target-list__content">
        <div v-if="toggleValue === 'icon'">
          <transition-group
            name="fade"
            tag="div"
            class="target-list-icon"
          >
            <div
              v-for="(target, index) in props.targetData"
              :key="index"
              :style="{
                display: props.targetData.length ? 'block' : 'none',
                animationDelay: `${(1.5 / props.targetData.length) * index}s`,
              }"
              class="target-item fade-in"
              @click="() => onDetail(target)"
            >
              <div :class="['target-box', target.targetAlertLevel.toLocaleLowerCase()]">
                <ev-icon
                  class="target-list__content-icon"
                  :icon="getIconType(target.targetKind)"
                />
              </div>
              <span
                class="target-list__content-name"
                :title="target.targetName"
              >
                {{ target.targetName }}
              </span>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.target-list {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    gap: 20px;
    align-items: center;
    align-self: flex-start;
  }

  .service-target__header-toggle {
    display: flex;
    align-items: center;
  }

  .ev-button-group {
    display: flex;
    align-items: center;
    height: 20px;

    .ev-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 22px !important;
      padding: 0 !important;
      border: 1px solid transparent;
      font-size: 11px;
      font-weight: 400;

      &.selected {
        background-color: #3b82f6;
        color: #ffffff;
      }

      &.first {
        border-radius: 2px 0 0 2px;
      }

      &.last {
        border-radius: 0 2px 2px 0;
      }
    }

    .button-text {
      padding-right: 4px;
      line-height: 1;
      white-space: nowrap;
    }
  }

  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 16px 6px 0;
    cursor: pointer;
    background-color: var(--service-target-list-background-color);

    &:hover {
      border-color: var(--service-target-list-hover-border-color);
    }

    .target-item {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 84px;
      height: 90px;
      padding: 12px 5px;
      border: 1px solid var(--service-target-list-border-color);
      background-color: white;
      text-align: center;
      white-space: normal;
      word-wrap: break-word;

      &:hover {
        border-color: #3b82f6;
      }

      &.selected {
        border-color: #3b82f6;
        background-color: #dbeafe;
      }
    }

    .target-list__content-icon {
      width: 36px;
      height: 36px;
      border-radius: 2px;
      background-color: white;
      mask-repeat: no-repeat;
    }

    .target-list__content-name {
      display: inline-block;
      width: 100%;
      max-height: 2.4em;
      margin-top: 8px;
      overflow: hidden;
      font-size: 12px;
      color: #282c32;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }

    &-target {
      display: inline-flex;
      position: absolute;
      top: -5px;
      right: -5px;
      align-items: flex-start;
      justify-content: center;
      width: 27px;
      height: 27px;
      border: 1px solid var(--service-target-list-target-border-color);
      border-radius: 50%;
      background-color: var(--service-target-list-target-background-color);
      box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.56);
    }
  }

  .target-list {
    width: 36px;
    height: 36px;
    border: 1px;
  }

  .normal {
    width: 36px;
    height: 36px;
    border: 1px solid #047857;
    border-radius: 2px;
    background-color: #34d399;
    color: #c8c8c8;
  }

  .warning {
    width: 36px;
    height: 36px;
    border: 1px solid #fbbf24;
    border-radius: 2px;
    background-color: #fbbf24;
    color: #c8c8c8;
  }

  .critical {
    width: 36px;
    height: 36px;
    border: 1px solid #b91c1c;
    border-radius: 2px;
    background-color: #f87171;
    color: #c8c8c8;
  }

  .target-list-icon {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    justify-items: center;
  }
  .target-list-grid {
    min-width: 340px;
  }
  .cell-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
  }

  .text-wrapper {
    display: flex;
    gap: 2px; /* 아이콘과 텍스트 간 간격 조정 */
    align-items: center;
  }

  .text-link {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    color: #3b82f6;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-in {
  opacity: 0;
  animation: fade-in 0.1s forwards;
}
.fade-in-item {
  opacity: 0;
  animation: fade-in 0.5s forwards;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}
</style>

import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useDrag } from '@/ai/utils/utils';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '@/common/assets/ai/floating-bot.json';
import { AnimationItem } from 'lottie-web';
import { useChatStore } from '../store/chat';

export const setup = () => {
  const chatStore = useChatStore();

  const isChatWindowOpen = computed(() => chatStore.isChatWindowOpen);

  const margin = 22;
  const buttonHeight = 50;

  const container = ref<HTMLDivElement | null>(null);
  let floatingBotAnimation: AnimationItem | null = null;

  const initialPosition = {
    right: margin,
    bottom: margin,
  };

  const { position, startDrag } = useDrag(initialPosition);
  const updatePosition = () => {
    position.value.right = margin;
    position.value.bottom = margin;
  };

  const toggleChat = () => {
    if (!chatStore.isChatWindowOpen) {
      const chatWindowPosition = {
        right: position.value.right,
        bottom: position.value.bottom + buttonHeight + 10,
      };
      chatStore.openChat(chatWindowPosition);
    } else {
      chatStore.toggleChat();
    }
  };

  const mouseDownPosition = ref({ x: 0, y: 0 });
  const mouseUpPosition = ref({ x: 0, y: 0 });

  const onMouseDown = (event: MouseEvent) => {
    mouseDownPosition.value = { x: event.clientX, y: event.clientY };
    startDrag(event);
  };

  const onMouseUp = (event: MouseEvent) => {
    mouseUpPosition.value = { x: event.clientX, y: event.clientY };

    const isSamePosition =
      mouseDownPosition.value.x === mouseUpPosition.value.x &&
      mouseDownPosition.value.y === mouseUpPosition.value.y;

    if (isSamePosition) {
      toggleChat();
    }
  };

  onMounted(() => {
    window.addEventListener('resize', updatePosition);

    if (container.value) {
      floatingBotAnimation = lottie.loadAnimation({
        container: container.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updatePosition);

    if (floatingBotAnimation) {
      floatingBotAnimation.destroy();
    }
  });

  return {
    position,
    onMouseDown,
    onMouseUp,
    startDrag,
    toggleChat,
    container,
    isChatWindowOpen,
  };
};

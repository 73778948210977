import { ref, computed } from 'vue';
import { useInternational } from '@/common/locale';
import { useSimpleTextTooltip } from '@/common/components/molecules/simpleTextTooltip/simpleTextTooltip.uses';
import { IconType } from '@/types/icon.types';
import type { ComposerTranslation } from 'vue-i18n';
import type { Size } from '@/types/common.types';

const ICON_INFO: Record<string, IconType> = {
  multi: 'icon-multi',
  infra: 'icon-featured-infrastructure',
  host: 'icon-featured-host',
  python: 'icon-python',
  kafka: 'icon-kafka',
  java: 'icon-java',
  app: 'icon-featured-application',
  k8s: 'icon-kubernetes-primary',
  log: 'icon-featured-log',
  sms: 'icon-featured-sms',
  mysql: 'icon-mysql-color',
  oracle: 'icon-oracle',
  postgresql: 'icon-postgresql',
  sqlserver: 'icon-sqlserver-color',
  redis: 'icon-redis',
  mongo: 'icon-mongodb',
  cubrid: 'icon-cubrid',
  cloud: 'icon-featured-cloud',
  aws: 'icon-aws',
  aws_ec2: 'icon-aws-ec2',
  aws_lambda: 'icon-aws-lambda',
  aws_lightsail: 'icon-aws-lightsail',
  aws_s3: 'icon-aws-s3',
  aws_aurora: 'icon-aws-aurora',
  aws_documentdb: 'icon-aws-documentdb',
  aws_dynamodb: 'icon-aws-dynamodb',
  aws_elasticache: 'icon-aws-elasticache',
  aws_rds: 'icon-aws-rds',
  aws_redshift: 'icon-aws-redshift',
  aws_vpc: 'icon-aws-vpc',
  aws_costexplorer: 'icon-aws-costexplorer',
  user: 'icon-featured-user',
} as const;

const ICONS_WITH_BACKGROUND = ['host', 'multi', 'log', 'app'];

type IconCode = (typeof ICON_INFO)[keyof typeof ICON_INFO];

export interface Props {
  iconCode?: IconCode;
  iconColor?: 'default' | 'white';
  size?: Size;
}

const useTooltip = (t: ComposerTranslation) => {
  const tooltipText = t('MESSAGE.UI.DASHBOARD.FEATURED_ICON_TOOLTIP');
  const tooltipMouseEvent = ref<MouseEvent | null>(null);
  const { onMouseEventInTarget } = useSimpleTextTooltip(tooltipMouseEvent);

  return {
    tooltipMouseEvent,
    tooltipText,
    onMouseEventInTarget,
  };
};

export const setup = (props: Required<Props>) => {
  const { t } = useInternational();
  const featuredIcon = computed<IconType>(() => ICON_INFO[props.iconCode]);
  const { tooltipMouseEvent, tooltipText, onMouseEventInTarget } = useTooltip(t);

  const needsBackground = computed(() => {
    const baseIconCode = props.iconCode.replace('icon-', '');
    return ICONS_WITH_BACKGROUND.includes(baseIconCode);
  });

  return {
    featuredIcon,
    tooltipMouseEvent,
    tooltipText,
    onMouseEventInTarget,
    needsBackground,
  };
};

<template>
  <div class="loading__wrapper">
    <i class="bot-message--icon" />
    <div class="bot-message--message">
      <div
        ref="container"
        class="loading-bar"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '@/common/assets/ai/chat-loading.json';
import { AnimationItem } from 'lottie-web';

const container = ref<HTMLDivElement | null>(null);
let loadingAnimation: AnimationItem | null = null;

onMounted(() => {
  if (container.value) {
    loadingAnimation = lottie.loadAnimation({
      container: container.value,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }
});

onUnmounted(() => {
  if (loadingAnimation) {
    loadingAnimation.destroy();
  }
});
</script>

<style scoped lang="scss">
.loading__wrapper {
  display: flex;
  align-items: flex-start;
  .bot-message--icon {
    display: inline-block;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    background-image: url('@/common/assets/ai/chat-bot.svg');
    background-size: cover;
  }

  .bot-message--message {
    max-width: 300px;
    padding: 12px;
    border-radius: 12px;
    background-color: white;
    font-size: 13px;
  }
}
</style>

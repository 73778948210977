/**
 * 응답 키벨루 배열을 그리드 rows 형태로 변환하는 함수
 * data :: [{a: 1, b:2}]
 * columns :: [{field: 'a'}, {field: 'b'}]
 * return :: [[1,2]]
 * @param data
 * @param columns
 * @returns {*}
 */
import {
  isDateTime,
  replaceInstanceObjectType,
  sortObjectByKey,
  utcZeroTimeToStandardTime,
} from '@/common/utils/commonUtils';
import { convertValueWithUnit, formatValueWithUnit } from '@/common/utils/convertUnits.utils';
import {
  ColumnWithHideInfo,
  CustomColumn,
  CustomColumnsReadonly,
  GridColumn,
  GridColumnType,
} from '@/common/utils/types';
import { BlockedList } from '@/sqlServer/views/trendAnalysis/trendTab/useBlockedTab';
import dayjs from 'dayjs';
import { PA_TOGGLE_DATA_TYPE } from '@/common/utils/define';
import { computed, Ref } from 'vue';
import { getOperatorsByType } from '@/common/components/molecules/filterSearch/filterSearch.utils';
import { useEvGridFilterSearch } from '@/common/components/molecules/filterSearch/filterSearch.uses';
import { FilterItem } from '@/common/components/molecules/filterSearch/filterSearch.type';

export const calculateRowDepth = <T extends Record<string, any>>(row: T): number =>
  row.children?.length ? 1 + Math.max(...row.children.map(calculateRowDepth)) : 1;

export const convertPlanDataToPlanRow = (planData: string) =>
  planData
    ? planData
        .replace(/ /g, '\u00a0')
        .split('/n')
        .map((currentValue, idx) => [idx + 1, currentValue])
    : [];

export function makeRows<
  T extends Record<string, any>,
  Column extends Readonly<CustomColumn> | CustomColumn,
>(
  data: T[] = [],
  columns: readonly Column[],
  columnFormatter?: { [K in Column['field']]?: (field: T[K & keyof T], row?: T) => any },
  originTimeFields?: string[],
): any[] {
  return data.map((obj, idx) =>
    columns.map((col) => {
      if (columnFormatter?.[col.field]) {
        return columnFormatter[col.field](obj[col.field], obj);
      }

      if (isDateTime(obj[col.field])) {
        const useUtc0Time = originTimeFields?.includes(col.field);
        const utc0Time = obj[col.field]
          ? dayjs(obj[col.field]).format('YYYY-MM-DD HH:mm:ss')
          : null;
        return useUtc0Time ? utc0Time : utcZeroTimeToStandardTime(obj[col.field]);
      }
      if (
        typeof obj[col.field] === 'string' &&
        (obj[col.field] === 'i' || obj[col.field] === 'r')
      ) {
        return replaceInstanceObjectType(obj[col.field]);
      }
      if (['rank', 'no'].includes(col.field)) {
        return idx + 1;
      }
      return obj[col.field] ?? null;
    }),
  );
}

/**
 * 그리드 데이터와 그리드 컬럼을 가져와 K-V 매핑된 객체를 리턴해준다.
 * @param data 그리드 데이터
 * @param columns 그리드 컬럼
 */
export const makeRowToObj = <T extends any[], Column extends Readonly<CustomColumn> | CustomColumn>(
  data: T,
  columns: readonly Column[],
  formatter: { [K in Column['field']]?: (field: T[K & keyof T]) => any } = {},
) => {
  if (!columns.length) {
    return {};
  }

  return columns.reduce((acc, cur, idx) => {
    if (!acc[cur.field]) {
      acc[cur.field] = null;
    }
    if (formatter[cur.field]) {
      acc[cur.field] = formatter[cur.field](data[idx]);
    } else {
      acc[cur.field] = data[idx];
    }
    return acc;
  }, {});
};

export const makeOracleWaitClassRows = (data, columns) => {
  const tempData: any[] = [];
  data.forEach((item) => {
    if (item?.values.length) {
      item.values.forEach((val) => {
        tempData.push({
          waitClassName: item.waitClassName,
          ...val,
        });
      });
    }
  });
  return makeRows(tempData, columns);
};

export const makeOracleWaitChainsRow = ({
  collectTime,
  osid,
  pid,
  sid,
  serialNumber,
  blockIsValid,
  blockerOsid,
  blockerPId,
  blockerSId,
  blockerSerialNumber,
  event,
  lockType,
  lockMode,
  numberWaiters,
  rowWaitObjNumber,
  sqlId,
  sqlText,
  prevSqlId,
  inWait,
  waiters,
  chainIsCycle,
  instance,
  blockerInstance,
  secondInWait,
}) => ({
  collectTime: utcZeroTimeToStandardTime(collectTime),
  chainIsCycle,
  instance,
  sid,
  serialNumber,
  pid,
  osid,
  blockerInstance,
  blockerPId,
  blockerOsid,
  blockerSId,
  blockerSerialNumber,
  blockIsValid,
  secondInWait,
  event,
  lockType,
  lockMode,
  numberWaiters,
  inWait,
  rowWaitObjNumber,
  sqlId,
  sqlText,
  prevSqlId,
  expand: !!waiters?.length,
  children: waiters?.length ? waiters.map((waiter) => makeOracleWaitChainsRow(waiter)) : undefined,
});

export const makeSqlServerBlockedRow = ({
  blockingSessionId,
  commandType,
  cpuTimePersec,
  databaseName,
  ecid,
  elapsedTime,
  kpid,
  logicalReadsPersec,
  loginName,
  objectName,
  openTran,
  physicalReadsPersec,
  planHash,
  programName,
  sessionId,
  sqlHandle,
  sqlHash,
  sqlId,
  sqlLastWaitType,
  sqlTextHashId,
  sqlWaitTime,
  statementEndOffset,
  statementStartOffset,
  status,
  waiters,
}: BlockedList) => ({
  blockingSessionId,
  commandType,
  cpuTimePersec,
  databaseName,
  ecid,
  elapsedTime,
  kpid,
  logicalReadsPersec,
  loginName,
  objectName,
  openTran,
  physicalReadsPersec,
  planHash,
  programName,
  sessionId,
  sqlHandle,
  sqlHash,
  sqlId,
  sqlLastWaitType,
  sqlTextHashId,
  sqlWaitTime,
  statementEndOffset,
  statementStartOffset,
  status,
  expand: !!waiters?.length,
  children: waiters?.length ? waiters.map((waiter) => makeSqlServerBlockedRow(waiter)) : undefined,
});

export function findGridColumnIndex<T extends string>({
  fields = [],
  columns = [],
}: {
  fields: T[];
  columns: CustomColumn<T>[] | Readonly<CustomColumn<T>[]>;
}): Record<T, number> {
  const idxMap: Record<T, number> = {} as Record<T, number>;

  const targetFields = new Set(fields);

  for (let idx = 0; idx < columns.length; idx++) {
    const { field } = columns[idx];
    if (targetFields.has(field)) {
      idxMap[field] = idx;
    }
  }

  return idxMap;
}

export function findCustomColumnIndexMap<T extends string>({
  columns = [],
}: {
  columns: CustomColumn<T>[] | Readonly<CustomColumn<T>[]>;
}): { [K in T]: number } {
  const idxMap = {} as { [K in T]: number };

  for (let idx = 0; idx < columns.length; idx++) {
    const { field } = columns[idx];
    idxMap[field] ??= idx;
  }

  return idxMap;
}

export const getRowByFieldInTreeGrid = <T extends { children?: T[] }>(
  treeNodes: T[],
  field: string,
  value: any,
): T | null => {
  for (let ix = 0; ix < treeNodes.length; ix++) {
    const node = treeNodes[ix];
    if (node[field] === value) {
      return node;
    }

    if (node?.children?.length) {
      const result = getRowByFieldInTreeGrid(node.children, field, value);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const getFormattedValueWithBestUnit = (
  value: number | null | undefined,
  unit = '',
): string | null => {
  if (value == null) return '';
  const convertValueInfo = convertValueWithUnit({ value, unit });
  return formatValueWithUnit({ originValue: value, ...convertValueInfo });
};

export const findColumnProperties = <k extends keyof CustomColumn>(
  findRendererType: CustomColumn['rendererType'],
  findFieldName: k,
  gridColumns: CustomColumn[],
) =>
  gridColumns
    .filter((column) => column?.rendererType === findRendererType)
    .map((column) => column[findFieldName]);

export const getGridColumnsByToggle = (
  initialGridColumns: CustomColumn[] | CustomColumnsReadonly,
) => {
  return (type: string, gridColumns: CustomColumn[] | CustomColumnsReadonly): GridColumn[] => {
    let columns = gridColumns;
    if (!columns?.length) {
      columns = initialGridColumns;
    }
    const suffix = type === PA_TOGGLE_DATA_TYPE.SUM ? '' : 'Avg';
    return columns.map((column, idx) => {
      if (column.attrs?.toggle) {
        return {
          ...column,
          field: `${initialGridColumns[idx].field}${suffix}`,
        };
      }
      return column;
    });
  };
};

export const changeColumnStatus = (gridColumns: Ref<CustomColumn[]>) => {
  return ({ columns: columnsWithHideInfo }: { columns: ColumnWithHideInfo[] }) => {
    gridColumns.value = sortObjectByKey(columnsWithHideInfo, 'index', 'ascending');
  };
};

export const emitChangeColumnStatus = (emit: {
  (e: 'change-column-status', value: { columns: ColumnWithHideInfo[] }): void;
}) => {
  return (gridInfos: { columns: ColumnWithHideInfo[] }) => {
    emit('change-column-status', gridInfos);
  };
};

export const useFilterSearch = (columns: GridColumn[]) => {
  const filterSearchItems: FilterItem[] = columns
    .filter(({ searchable }) => searchable)
    .map(({ caption, field, type }) => {
      const values: {
        multi: boolean;
        items: { id: string; name: string }[];
      } = {
        multi: type === 'boolean',
        items:
          type === 'boolean'
            ? [
                { id: 'true', name: 'True' },
                { id: 'false', name: 'False' },
              ]
            : [],
      };

      return {
        key: {
          id: field,
          name: caption,
        },
        operators: getOperatorsByType(type as GridColumnType),
        values,
      };
    });

  const { filterSearchResultMV, filterGridByFilterSearch, changeStateObjectRowsByFilterSearch } =
    useEvGridFilterSearch({
      columns: computed(() => columns),
      filterItems: filterSearchItems,
    });

  return {
    filterSearchItems,
    filterSearchResultMV,
    filterGridByFilterSearch,
    changeStateObjectRowsByFilterSearch,
  };
};

export const processTreeNodes = <T extends Record<string, any>>({
  row,
  maxDepth,
  option,
}: {
  row: T;
  maxDepth: number;
  option: Record<string, any> & {
    handler: (param: { row: T; depth: number; children: T[]; option: Record<string, any> }) => void;
  };
}) => {
  const queue = [{ row, depth: 1 }];

  while (queue.length > 0) {
    const { row: currentRow, depth } = queue.shift()!;

    if (depth <= maxDepth) {
      if (option.handler) {
        option.handler({ row: currentRow, depth, children: currentRow?.children, option });
      }

      if (currentRow?.children && Array.isArray(currentRow?.children)) {
        currentRow?.children.forEach((child) => {
          queue.push({ row: child, depth: depth + 1 });
        });
      }
    }
  }
};

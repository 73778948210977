import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { ClickData } from '@/common/utils/types';

const STYLE_INFO = {
  MORE_COUNT: 32,
  GAP: 4,
  CHIP_MAX_WIDTH: '100px',
};

// 그리드에서 shownChipCount n개로 사용할경우 content 가 잘릴 수 있음
// 그리드 아닌 부분에서도 사용 할 수 있도록 type 을 nubmer로 수정
export interface Props {
  rowIndex: number;
  chips: string[] | Record<string, string[]>;
  shownChipCount?: number;
  isMultipleValueChip?: boolean;
  customClassEachChips?: string[];
  fieldName?: string;
  rowData?: any;
  columnIndex?: number;
  isClickable?: boolean;
}

export interface Emit {
  (
    e: 'click-count-clickable',
    value: ClickData & { fieldName: string; rowData: any; columnIndex: number },
  );
  (e: 'click-count', value: ClickData);
  (e: 'click-chip', value: ClickData);
  (e: 'resize');
}

const setup = (props: Props, emit: Emit) => {
  const baseGridChipsEl = ref();
  const filteredChips = ref<string[]>([]);
  const moreCount = ref<number>(0);
  const chipsClass = computed(() => (props.chips?.length === 1 ? 'stretch' : ''));

  const setFilteredChips = async () => {
    const chips: string[] = props.isMultipleValueChip
      ? Object.keys(props.chips)
      : (props.chips as string[]);
    filteredChips.value = [...chips];

    await nextTick();
    if (baseGridChipsEl.value && chips?.length > 1) {
      const targetElWidth = baseGridChipsEl.value?.getBoundingClientRect().width;
      const children = baseGridChipsEl.value?.querySelectorAll('.chip');

      if (children.length) {
        let chipCount = 0;
        let childTotalWidth = 0;
        children?.forEach((childEl) => {
          if (childEl.className.includes('count')) {
            return;
          }

          const { width } = childEl.getBoundingClientRect();
          childTotalWidth += width + STYLE_INFO.GAP ?? 0;
          if (childTotalWidth + STYLE_INFO.MORE_COUNT < targetElWidth) {
            chipCount += 1;
          }
        });

        if (props.shownChipCount) {
          children[0].style.maxWidth = `${
            targetElWidth - STYLE_INFO.MORE_COUNT - STYLE_INFO.GAP
          }px`;
        } else if (chips.length && !chipCount) {
          children[0].style.maxWidth = `${
            targetElWidth - STYLE_INFO.MORE_COUNT - STYLE_INFO.GAP
          }px`;
          chipCount++;
        } else {
          children[0].style.maxWidth = STYLE_INFO.CHIP_MAX_WIDTH;
        }

        filteredChips.value = filteredChips.value.slice(0, props.shownChipCount ?? chipCount);
        moreCount.value = chips.length - (props.shownChipCount ?? chipCount);
      }
    } else {
      moreCount.value = 0;
    }
  };

  const onClickChip = (evt, chip) => {
    if (!props.isMultipleValueChip) return;
    const totalChips: string[] = props.isMultipleValueChip
      ? Object.keys(props.chips)
      : (props.chips as string[]);
    emit('click-chip', { rowIndex: props.rowIndex, chips: props.chips[chip], totalChips, evt });
  };

  const onClickCount = (evt) => {
    const totalChips: string[] = props.isMultipleValueChip
      ? Object.keys(props.chips)
      : (props.chips as string[]);
    const chips = totalChips.slice(moreCount.value * -1);

    emit('click-count', {
      rowIndex: props.rowIndex,
      chips,
      totalChips,
      evt,
    });
  };

  const onClickCountClickable = (evt) => {
    if (!props.isClickable) return;

    const totalChips: string[] = props.isMultipleValueChip
      ? Object.keys(props.chips)
      : (props.chips as string[]);
    const chips = totalChips.slice(moreCount.value * -1);

    emit('click-count-clickable', {
      rowIndex: props.rowIndex,
      chips,
      totalChips,
      evt,
      fieldName: props.fieldName,
      rowData: props.rowData,
      columnIndex: props.columnIndex,
    });
  };

  const ro = new ResizeObserver(async () => {
    await setFilteredChips();
  });

  watch(
    () => props.chips,
    async () => {
      await setFilteredChips();
    },
    { immediate: true },
  );

  onMounted(() => {
    ro.observe(baseGridChipsEl.value!);
  });

  return {
    baseGridChipsEl,
    filteredChips,
    moreCount,
    chipsClass,
    onClickChip,
    onClickCount,
    onClickCountClickable,
  };
};

export { STYLE_INFO, setup };

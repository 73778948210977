import { round } from 'lodash-es';
import {
  isApiCountUnit,
  convertApiDataUnitToBinaryByte,
  convertTimeApiUnit,
  isApiTimeUnit,
  isApiDataUnit,
  isDataTransmissionUnit,
  isApiCoreUnit,
  ApiDataUnit,
} from '../ports/convertUnits';
import { BinaryBytesUnit, CoreUnit, CountUnit, TimeUnit } from './convertUnits.types';

export type UnitValueInfo<Unit = string> = {
  value: number;
  unit: Unit;
};

type ConvertedUnitValueInfo<Unit = string> = UnitValueInfo<Unit>;

const convertValueWithUnitList = <Unit extends string>(
  value: number,
  unit: Unit,
  unitRange: number,
  units: Unit[],
): ConvertedUnitValueInfo<Unit> => {
  const sign = value < 0 ? -1 : +1;
  let unitInd = units.findIndex((unitItem) => unitItem === unit);
  let copiedValue = value / sign;

  if (unitInd === -1) {
    return {
      value,
      unit,
    };
  }

  while (copiedValue >= unitRange && unitInd < units.length - 1) {
    copiedValue /= unitRange;
    unitInd++;
  }
  return {
    value: copiedValue * sign,
    unit: units[unitInd],
  };
};

export const isCountUnit = (unit: any): unit is CountUnit => {
  return CountUnit.includes(unit);
};

export const convertCountWithUnit = (
  count: number,
  toUnit?: CountUnit,
): ConvertedUnitValueInfo<CountUnit> => {
  if (toUnit != null) {
    return {
      value: count / 1000 ** CountUnit.indexOf(toUnit),
      unit: toUnit,
    };
  }
  return convertValueWithUnitList(count, '', 1000, [...CountUnit]);
};

const convertBinaryBytesWithUnit = (
  byte: number,
  unit: BinaryBytesUnit,
  toUnit?: BinaryBytesUnit,
): ConvertedUnitValueInfo<BinaryBytesUnit> => {
  if (toUnit) {
    const fromUnitIndex = BinaryBytesUnit.indexOf(unit);
    const toUnitIndex = BinaryBytesUnit.indexOf(toUnit);

    if (fromUnitIndex === toUnitIndex) {
      return { value: byte, unit: toUnit };
    }
    return {
      value:
        fromUnitIndex > toUnitIndex
          ? byte * 1024 ** (fromUnitIndex - toUnitIndex)
          : byte / 1024 ** (toUnitIndex - fromUnitIndex),
      unit: toUnit,
    };
  }
  return convertValueWithUnitList(byte, unit, 1024, [...BinaryBytesUnit]);
};

const isTimeUnit = (unit: any): unit is TimeUnit => {
  return TimeUnit.includes(unit);
};

const convertValueUnitCsToMs = (value: number): ConvertedUnitValueInfo<'ms'> => {
  // 1cs === 10ms
  return { value: value * 10, unit: 'ms' };
};

/**
 ** ms, cs 단위는 모두 s로 변경하고, 그외의 단위는 그대로 보여준다.
 */
const convertTimeWithUnit = (
  time: number,
  unit: TimeUnit | 'cs',
  toUnit?: TimeUnit,
): ConvertedUnitValueInfo<TimeUnit> => {
  let timeUnit = unit;
  let timeValue = time;
  if (timeUnit === 'cs') {
    const { value: msValue, unit: convertedMsUnit } = convertValueUnitCsToMs(time);
    timeValue = msValue;
    timeUnit = convertedMsUnit;
  }

  let timeUnitIndex = TimeUnit.findIndex((timeUnitItem) => timeUnitItem === timeUnit);

  if (timeUnitIndex === -1) {
    return { value: time, unit: timeUnit };
  }

  const MsPerSecond = 1000;
  const SecondsPerMinute = 60;
  const secondIndex = TimeUnit.findIndex((timeUnitItem) => timeUnitItem === 's');
  if (secondIndex === -1) {
    throw new Error('s unit이 TimeUnit에서 제외되었습니다.');
  }

  const toTimeUnit = toUnit || (timeUnit === 'ms' ? 's' : timeUnit);

  if (toTimeUnit) {
    const toTimeUnitIndex = TimeUnit.findIndex((timeUnitItem) => timeUnitItem === toTimeUnit);
    if (timeUnitIndex > toTimeUnitIndex) {
      while (TimeUnit[timeUnitIndex] !== toTimeUnit) {
        timeValue *= timeUnitIndex <= secondIndex ? MsPerSecond : SecondsPerMinute;
        --timeUnitIndex;
      }
    } else {
      while (TimeUnit[timeUnitIndex] !== toTimeUnit) {
        timeValue /= timeUnitIndex < secondIndex ? MsPerSecond : SecondsPerMinute;
        ++timeUnitIndex;
      }
    }
    return {
      value: timeValue,
      unit: TimeUnit[timeUnitIndex],
    };
  }

  if (timeUnitIndex < 2) {
    while (TimeUnit[timeUnitIndex] !== 's') {
      timeValue /= MsPerSecond;
      ++timeUnitIndex;
    }
    return {
      value: timeValue,
      unit: TimeUnit[timeUnitIndex],
    };
  }
  return { value: time, unit: timeUnit };
};

const getDataConvertedUnit = (originUnit: string, convertedUnit: BinaryBytesUnit) => {
  if (originUnit.includes('/sec')) {
    return `${convertedUnit}/sec`;
  }
  if (isDataTransmissionUnit(originUnit)) {
    switch (convertedUnit) {
      case 'byte':
        return `${originUnit}`;
      case 'KiB':
        return `k${originUnit}`;
      case 'MiB':
        return `m${originUnit}`;
      case 'GiB':
        return `g${originUnit}`;
      default:
        throw new Error(`invalid data unit ${convertedUnit}`);
    }
  }
  return convertedUnit;
};

const isCoreUnit = (unit: any): unit is CoreUnit => {
  return CoreUnit.includes(unit);
};

const convertCoreWithUnit = (
  value: number,
  unit: CoreUnit,
  toUnit?: CoreUnit,
): ConvertedUnitValueInfo<CoreUnit> => {
  if (toUnit != null) {
    const diff = CoreUnit.indexOf(toUnit) - CoreUnit.indexOf(unit);
    return {
      value: value / 1000 ** diff,
      unit: toUnit,
    };
  }
  return convertValueWithUnitList<CoreUnit>(value, unit, 1000, [...CoreUnit]);
};

export const convertValueWithUnit = (
  { value, unit }: UnitValueInfo,
  options?: {
    decimal?: number;
    toUnit?: ApiDataUnit | CountUnit | CoreUnit | TimeUnit | 'percent';
    originUnit?: boolean;
    space?: boolean;
  },
): ConvertedUnitValueInfo => {
  const { decimal = 3, toUnit, originUnit = false, space = true } = options ?? {};
  const lowerUnit = unit?.toLowerCase() ?? unit;
  if (isApiCountUnit(unit)) {
    const { value: countValue, unit: convertedCountUnit } = convertCountWithUnit(
      value,
      isCountUnit(toUnit) ? toUnit : undefined,
    );
    return {
      value: round(countValue, decimal),
      unit: convertedCountUnit
        ? `${convertedCountUnit}${originUnit ? '' : `${space ? ' ' : ''}${unit}`}`
        : unit,
    };
  }
  if (isApiDataUnit(unit)) {
    const { value: countValue, unit: convertedDataUnit } = convertBinaryBytesWithUnit(
      value,
      convertApiDataUnitToBinaryByte(unit),
      isApiDataUnit(toUnit) ? convertApiDataUnitToBinaryByte(toUnit) : undefined,
    );

    return {
      value: round(countValue, decimal),
      unit: getDataConvertedUnit(unit, convertedDataUnit),
    };
  }
  if (isApiTimeUnit(unit)) {
    const { value: convertedValue, unit: convertedUnit } = convertTimeWithUnit(
      value,
      convertTimeApiUnit(unit),
      isTimeUnit(toUnit) ? toUnit : undefined,
    );
    return {
      value: round(convertedValue, decimal),
      unit: convertedUnit,
    };
  }

  if (isApiCoreUnit(lowerUnit)) {
    const { value: convertedValue, unit: convertedUnit } = convertCoreWithUnit(
      value,
      lowerUnit,
      isCoreUnit(toUnit) ? toUnit : undefined,
    );
    return {
      value: round(convertedValue, decimal),
      unit: convertedUnit,
    };
  }
  return { value: round(value, decimal), unit };
};

export const formatValueWithUnit = ({
  value,
  unit,
  originValue,
}: ConvertedUnitValueInfo & { originValue: number }): string => {
  const computedUnitStr = unit ? ` ${unit}` : '';
  if (originValue === 0) return `0${computedUnitStr}`;
  if (value === 0) return `0.000${computedUnitStr}`;
  return `${value}${computedUnitStr}`;
};
